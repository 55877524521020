import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import { axesInvalidateKeySelector, axesLoadingSelector, axesSelector } from "widgets/AxesAndMarks/model/selectors";
import { getAxes } from "widgets/AxesAndMarks/model/thunks";
import AxesAndMarksInfo from "widgets/AxesAndMarks/ui/AxesAndMarksInfo/AxesAndMarksInfo";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./DisplayAxesAndMarks.module.scss";

interface IProps {
  id: number;
  planOrFact: "plan" | "fact";
  workOrGroup: "work" | "group";
  customHeader?: React.ReactNode;
}

const DisplayAxesAndMarks: React.FC<IProps> = ({ id, planOrFact, workOrGroup, customHeader }) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();
  const paramKey = `${planOrFact}_${workOrGroup}` as "fact_group";
  const key = generateStorageKey({ [paramKey]: id });
  const pendingKey = generateStorageKey({ [paramKey]: id, type: "pending" });
  const axes = useSelector(axesSelector)[key];
  const isloading = useSelector(axesLoadingSelector)[key];
  const isPending = useSelector(axesLoadingSelector)[pendingKey];
  const invalidateKey = useSelector(axesInvalidateKeySelector);

  useEffect(() => {
    dispatch(getAxes(buildingId, planOrFact, workOrGroup, id));
  }, [buildingId, planOrFact, workOrGroup, id, invalidateKey]);

  const showSpinner = isloading || isPending;

  if (showSpinner) {
    return (
      <div className={styles.spinner}>
        <Spinner isStatic isSmall />
      </div>
    );
  }

  return <AxesAndMarksInfo info={axes} customHeader={customHeader} />;
};

export default DisplayAxesAndMarks;
