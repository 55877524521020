import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addStockProducts,
  loadStockProducts,
  stockProductsLoadingSelector,
  stockProductsSelector,
} from "redux/modules/common/building/stocks";

import TableRow from "./components/TableRow";
import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import { Spinner } from "../../../../../shared/ui/atoms/Spinner/Spinner";
import ShowMoreButton from "../../../../../shared/ui/controls/ShowMoreButton/index";
import EmptyPlaceholder from "../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import PackingList from "../../../PackingList/PackingList";
import TableHeader from "../common/TableHeader/TableHeader";

import { VIEW_MANUFACTURING_PACKING_LIST_SECTION } from "constants/permissions/manufacturingPermissions";

import { useQueryParams } from "../../../../../utils/hooks/useQueryParams";
import usePermission from "hooks/usePermission";

import { hasOnlyPaginationFilters } from "../../../../../utils/hasOnlyPaginationFilters";

import warehouses from "images/icons/navigation/warehouses.svg";

import styles from "./Receipt.module.scss";

const Receipt = ({ stockId, objectId }) => {
  const dispatch = useDispatch();
  const products = useSelector(stockProductsSelector);
  const isLoading = useSelector(stockProductsLoadingSelector);
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const updId = useQueryParams("upd");

  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  useEffect(() => {
    stockId && dispatch(loadStockProducts(stockId, filters));
  }, [filters, stockId]);

  const changeFilters = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleAdd = () => {
    dispatch(addStockProducts(stockId, { ...filters, offset: products?.results?.length, limit: filters.limit }));
  };

  const haveViewUpdPermission = usePermission(VIEW_MANUFACTURING_PACKING_LIST_SECTION);

  const isEmpty = !isLoading && !products?.results.length;

  return (
    <>
      <>
        {((isEmpty && isWithFilters) || !isEmpty) && (
          <TableHeader
            onFiltersChange={changeFilters}
            lastColName="Остаток"
            preLastColName="В производстве"
            prePreLastColName="Приход"
          />
        )}
        {isLoading && <Spinner />}
        {!isLoading &&
          !!products?.results.length &&
          products?.results?.map((item, index) => (
            <TableRow data={item} index={index} stockId={stockId} objectId={objectId} key={`${item.id}_${index}`} />
          ))}
        {isEmpty && <EmptyPlaceholder img={warehouses} />}
        <ShowMoreButton
          allCount={products?.count}
          showedCount={products?.results?.length}
          handleAdd={handleAdd}
          isExists={!isLoading && products?.count > products?.results.length}
        />
      </>
      {updId && haveViewUpdPermission && (
        <div className={styles.updWrapper}>
          <TemplateSimple>
            <PackingList propsPackingListId={updId} fallbackObjectId={objectId} />
          </TemplateSimple>
        </div>
      )}
    </>
  );
};

export default Receipt;
