import moment from "moment";
import React from "react";

import IntervalGroupModal from "../../../../../UI/_TODO/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import MaterialBlockInWork from "../../../../../UI/_TODO/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import WorkListItem from "../../../../../UI/_TODO/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../../../UI/_TODO/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import ModalContentSplitter from "../../../../../UI/atoms/ModalContentSplitter/ModalContentSplitter";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import NavBar from "components/UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";
import ProductIntervalCardContent from "components/pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";

import { SimpleResourceBlock } from "../../../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import TabBarNotLinks from "../../../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import IntervalRelationsContent from "./IntervalRelationsContent/IntervalRelationsContent";
import ManufacturingModalBottom from "./ManufacturingModalBottom/ManufacturingModalBottom";
import ManufacturingModalContentFact from "./ManufacturingModalContentFact";
import ManufacturingModalContentPlan from "./ManufacturingModalContentPlan";
import PlanEditing from "./PlanEditing/PlanEditing";
import useCanApprovePlan from "./PlanEditing/useCanApprovePlan";
import WorkApprovingSharedModalContent from "./WorkSharingModalContent/WorkApprovingSharedModalContent";
import WorkSharingModalContent from "./WorkSharingModalContent/WorkSharingModalContent";
import { manufacturingMaterialsStatusSwitcher } from "./manufacturingModalStatusSwitcher";
import { ManufacturingModalType, useManufacturingModal } from "./useManufacturingModal";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import DisplayAxesAndMarks from "widgets/AxesAndMarks/widgets/DisplayAxesAndMarks/DisplayAxesAndMarks";
import PlanEditingHistory from "widgets/EditingHistory/PlanEditingHistory";

import { MAIN_PLAN_TAB, PLAN_TABS, RELATIONS_PLAN_TAB } from "./constants";

import { isNanChecker } from "../../../../../../utils/formatters/isNanChecker";
import { IMimInChartModal } from "./utils";

import scheduleIcon from "../../../../../../images/icons/navigation/scheduleIcon.svg";

import styles from "./ManufacturingModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  date_start: string;
  date_end: string;
  objectId: string;
  type: string;
  intervaldata: Record<string, any>;
  expenditureId: number;
  sectionId: number;
  modalType: ManufacturingModalType;
  updateWorkStatus?: (status: string, id: number) => void;
  updatePlanStatus?: (status: string, id: number) => void;
  onEditCallback?: () => void;
  isEditRelationsByDefault?: boolean;
  onAddToJournalCallback?: () => void;
  isGroupInterval?: boolean;
}

// TODO: Отрефакторить
// Сейчас для планов расценок и групп на графике
// используется компонент ManufacturingPlanModal
// Нужно вынести логику работы с фактами в отдельную модалку
const ManufacturingModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  date_start,
  date_end,
  objectId,
  type,
  intervaldata = {},
  expenditureId,
  sectionId,
  modalType,
  updateWorkStatus,
  updatePlanStatus,
  isEditRelationsByDefault,
  onEditCallback,
  onAddToJournalCallback,
  isGroupInterval,
}) => {
  const {
    isPlan,
    isSharing,
    isReviewing,
    setIsReviewing,
    closeHandler,
    interval,
    statusText,
    statusIcon,
    setIsSharing,
    intervalDataFetcher,
    files,
    fileUploadHandler,
    fileRemoveHandler,
    isEditingPlan,
    setIsEditingPlan,
    closePlanEditingHandler,
    isLoading,
    hasRelations,
    planTab,
    isAddingNew,
    setIsAddingNew,
    list,
    handleListBackButton,
    setPlanTab,
    hasEditRelationsPermission,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    canEditPlan,
    modalMaterials,
    planIsInFuture,
    approveHandler,
    intervalClickHandler,
    statusColor,
    isGroup,
    isMaterialsLoading,
  } = useManufacturingModal({
    isOpen,
    type,
    objectId,
    expenditureId,
    sectionId,
    date_start,
    date_end,
    modalType,
    onClose,
    updateWorkStatus,
    updatePlanStatus,
    intervaldata,
    isEditRelationsByDefault,
    isGroupInterval,
  });

  const canApprovePlan = useCanApprovePlan({
    objectId,
    approvals: interval?.approvals,
    isSection: false,
    isActive: isOpen,
  });

  if (isSharing || isReviewing) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification}
        statusColor={statusColor as any}
        statusText={statusText}
        statusIcon={statusIcon}
      >
        {isSharing && (
          <WorkSharingModalContent
            onClose={() => setIsSharing(false)}
            interval={interval}
            onIntervalReload={intervalDataFetcher}
            objectId={objectId}
            files={files}
            onAddFile={fileUploadHandler}
            onDeleteFile={fileRemoveHandler}
            isPlan={isPlan}
          />
        )}
        {isReviewing && (
          <WorkApprovingSharedModalContent
            onCloseWholeModal={closeHandler}
            onClose={() => setIsReviewing(false)}
            interval={interval}
            onIntervalReload={intervalDataFetcher}
            objectId={objectId}
            files={files}
            onAddFile={fileUploadHandler}
            onDeleteFile={fileRemoveHandler}
            isPlan={isPlan}
          />
        )}
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
      >
        <ButtonBack onClick={() => setIsEditingPlan(false)} className={styles.back} />
        <PlanEditing
          planCreator={interval?.author}
          planCreatingDate={interval?.created_at}
          productId={interval?.expenditure_id}
          buildingId={+objectId}
          intervalId={interval?.id}
          planCount={interval?.count}
          planDateStart={interval?.start_at}
          planDateEnd={interval?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={interval?.planned_materials_list}
          defaultMims={interval?.count_planned_services}
          defaultWorkers={interval?.workers}
          defaultWorkersCounts={interval?.count_workers_data}
          onEditCallback={onEditCallback}
          sectionId={interval.parent_section_id || interval.parent_id || interval.section_id}
          paymentDate={interval?.payment_date}
          expemdituresIds={
            isGroup ? interval?.group?.expenditures?.map((el: any) => el.expenditure_id) : [interval?.expenditure_id]
          }
          closeWholeModal={onClose}
          isExpendituresGroup={isGroupInterval}
          measure={interval?.measure}
        />
      </ModalContainer>
    );
  }

  if (isGroup && !!interval?.id && !isLoading) {
    return (
      <IntervalGroupModal
        isOpen
        onClose={closeHandler}
        onBackButtonClick={list.length > 1 ? handleListBackButton : undefined}
        expenditureId={interval.id}
        expenditure={interval}
        sectionName={interval.group?.section_name}
        activeModule={"plans"}
        date_start={interval.start_at}
        date_end={interval.end_at}
        objectId={objectId}
        sectionId={interval.parent_section_id || interval.parent_id || interval.section_id} /* @ts-ignore */
        remarks={interval?.remarks ?? []}
        ticketRemarks={interval?.ticketRemarks ?? []}
        onEditCallback={onEditCallback}
        showRelationsTab={true}
        hasRelations={hasRelations}
      />
    );
  }

  const resourcesCount = isNanChecker(+modalMaterials?.length) + isNanChecker(+interval?.simpleResources?.length);

  if (interval?.id && !isLoading && !isGroup) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} /* @ts-ignore */
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
        isFixedHeader={hasRelations && planTab === RELATIONS_PLAN_TAB}
      >
        {isAddingNew && (
          <NavBar isExist text="Вернуться к работе" onClick={() => setIsAddingNew(false)} className={styles.back} />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent //@ts-ignore
            objectId={intervaldata.building_id ?? objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={interval?.section_id}
            directlyWork={interval?.expenditure_id}
            isOpen={isOpen}
            onAddToJournalCallback={onAddToJournalCallback}
          />
        )}
        {!isAddingNew && (
          <>
            <NavBar isExist={list?.length > 1} onClick={handleListBackButton} className={styles.back} />
            {hasRelations && (
              <TabBarNotLinks
                tabs={PLAN_TABS}
                activeId={planTab}
                onClick={setPlanTab}
                className={styles.relationsTabs}
              />
            )}
            {planTab === RELATIONS_PLAN_TAB && (
              <IntervalRelationsContent
                hasEditPermission={hasEditRelationsPermission}
                intervalId={interval.id}
                projectId={+objectId}
                relationsFromCurrentInterval={relationsFromCurrentInterval}
                relationsToCurrentInterval={relationsToCurrentInterval}
                intervalStartAt={interval.start_at}
                intervalEndAt={interval.end_at}
                isEditRelationsByDefault={isEditRelationsByDefault}
                onAddRelationCallback={onEditCallback}
              />
            )}
            {planTab === MAIN_PLAN_TAB && (
              <>
                {isPlan && (
                  <ManufacturingModalContentPlan
                    planCreator={interval?.author}
                    planCreatingDate={interval?.created_at}
                    measure={interval?.measure}
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    projectAmount={interval?.work_amount}
                    projectFullAmount={interval?.expenditure_amount}
                    projectFullCount={interval?.expenditure_count}
                    planCount={interval?.count}
                    planIsInWork={interval?.work_status === "actived"}
                    approvals={interval?.approvals}
                    closureDate={interval?.payment_date}
                  />
                )}
                {!isPlan && (
                  <ManufacturingModalContentFact
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    measure={interval?.measure}
                    passingAuthor={interval?.author}
                    passingDate={interval?.created_at}
                    passingCount={interval?.count}
                    investedAmount={interval?.work_amount}
                    acceptedAmount={interval?.accepted?.length > 0 && interval.accepted[0].amount}
                    acceptedAuthor={interval?.accepted?.length > 0 && interval.accepted[0].approve_user}
                    acceptedCount={interval?.accepted?.length > 0 && interval.accepted[0].count}
                    acceptedDate={interval?.accepted?.length > 0 && interval.accepted[0].created_at}
                    planCreator={interval?.from_interval?.author}
                    planCreatingDate={interval?.from_interval?.created_at}
                    planCount={interval?.from_interval?.count}
                    projectAmount={interval?.from_interval?.work_amount}
                    planApprover={interval?.from_interval?.approved_user}
                    planApprovingDate={interval?.from_interval?.approved_at}
                    remarks={interval?.remarks}
                    ticketRemarks={interval?.ticketRemarks}
                  />
                )}
                <div className={styles.pair}>
                  <div className={styles.info}>
                    <b>Раздел:</b>
                    <span>{interval?.section_name}</span>
                  </div>
                </div>
                <WorkersList workers={interval?.workers} />
                {isMaterialsLoading && <Spinner isStatic />}
                {!!resourcesCount && !isMaterialsLoading && (
                  <Expandable title={`Материалы: ${resourcesCount}`}>
                    {/* @ts-ignore */}
                    {interval?.simpleResources?.map((el) => (
                      <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
                    ))}
                    {modalMaterials.map((el: any, index: number) => (
                      <MaterialBlockInWork
                        name={el.stock_using_material?.product_building?.name}
                        measure={el.stock_using_material?.product_building?.measure}
                        count={el.count}
                        amount={el.amount}
                        key={el.id} //@ts-ignore
                        statusColor={manufacturingMaterialsStatusSwitcher(interval, index).color}
                        statusText={manufacturingMaterialsStatusSwitcher(interval, index).text}
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.planned_materials_list?.length && (
                  <Expandable title={`Материалы: ${interval?.planned_materials_list.length}`}>
                    {interval?.planned_materials_list.map((el: any) => (
                      <MaterialBlockInWork
                        name={el.material?.name}
                        measure={el.material?.measure}
                        count={el.count}
                        amount={
                          (parseFloat(el.count) * parseFloat(el.material?.estimate_expenditure?.estimate_amount)) /
                            parseFloat(el.material?.estimate_expenditure?.estimate_count) ||
                          el.material?.estimate_expenditure?.estimate_amount
                        }
                        key={el.id}
                        statusColor="blue"
                        statusText="План"
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.count_planned_services?.length && (
                  <Expandable title={`Машины и Механизмы: ${interval?.count_planned_services.length}`}>
                    {interval?.count_planned_services.map((el: any) => (
                      <MaterialBlockInWork
                        key={el.id}
                        name={el.service?.name}
                        measure={el.service?.measure}
                        count={el.count}
                        amount={el.amount}
                        statusColor="blue"
                        statusText="План"
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.ticket_services?.length && (
                  <Expandable title={`Машины и Механизмы: ${interval?.ticket_services.length}`}>
                    {interval?.ticket_services?.map((el: IMimInChartModal) => (
                      <MaterialBlockInWork
                        key={el.id}
                        name={el.service?.name}
                        measure={el.service?.measure}
                        count={el.count} /* @ts-ignore */
                        amount={el.service.estimate_expenditure.estimate_amount}
                      />
                    ))}
                  </Expandable>
                )}
                {!!files?.length && (
                  <ExpandableFileInput
                    files={files || []}
                    canExtractFiles
                    canDeleteFiles={false}
                    canUploadFiles={false}
                  />
                )}
                {isPlan && (
                  <PlanEditingHistory
                    isGroup={isGroup}
                    isOpen={isOpen}
                    objectId={objectId}
                    ticketId={intervaldata?.id}
                  />
                )}
                <DisplayAxesAndMarks planOrFact={isPlan ? "plan" : "fact"} workOrGroup="work" id={intervaldata?.id!} />
                <ManufacturingModalBottom
                  interval={interval}
                  isPlan={isPlan}
                  canEditPlan={canEditPlan}
                  canApprovePlan={!!canApprovePlan}
                  planIsInFuture={planIsInFuture}
                  onAddToJournal={() => setIsAddingNew(true)}
                  onApprove={approveHandler}
                  onEdit={() => setIsEditingPlan(true)}
                  onSetSharing={() => setIsSharing(true)}
                  onSetReviewingShared={() => setIsReviewing(true)}
                />
              </>
            )}
          </>
        )}
      </ModalContainer>
    );
  }

  const isWorkListShown = !interval?.id && list?.length > 1;

  return (
    <SliderModal closeHandler={closeHandler} isOpen={isOpen} className={styles.modalContainer}>
      {isLoading && <Spinner />}
      {!isLoading && isWorkListShown && (
        <div className={styles.list}>
          {list.map(
            (
              el: any,
              index: number //@ts-ignore
            ) => {
              const isGroup = !!el.group;
              return (
                <React.Fragment key={el.id}>
                  {(index === 0 || el.start_at !== list[index - 1]?.start_at) && (
                    <ModalContentSplitter label={moment(el.start_at).format("DD.MM.YY")} />
                  )}
                  <WorkListItem
                    //@ts-ignore
                    type={type}
                    onClick={() => intervalClickHandler(el.id, el.expenditure_id || el.group?.id, isGroup)}
                    name={el.expenditure_name || el.group?.name}
                    passAmount={el.completed_amount || el.work_amount_with_materials || el.group?.amount}
                    passCount={el.count || el.group?.count}
                    passDate={el.created_at}
                    passName={isGroup ? el.author?.name : el.author}
                    acceptAmount={el.accepted?.length ? el.accepted[0].amount : 0}
                    acceptCount={el.accepted?.length ? el.accepted[0].count : 0}
                    acceptDate={el.accepted?.length ? el.accepted[0].created_at : ""}
                    acceptName={el.accepted?.length ? el.accepted[0].approve_user : ""}
                    measure={el.measure || el.group?.measure}
                    hideAccepted={!el.accepted?.length}
                    modalType={modalType}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
      {!isLoading && !isWorkListShown && (
        <EmptyPlaceholder
          img={scheduleIcon}
          text={"В данном интервале пока что нет записей"}
          className={styles.emptyPlaceholder}
        />
      )}
    </SliderModal>
  );
};

export default React.memo(ManufacturingModal);
