import React from "react";

import { IJournalAcceptanceSharedContractors } from "redux/modules/common/building/journal/acceptance/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalSections from "../JournalSections/JournalSections";
import JournalContractorRow from "./JournalContractorRow";
import JournalContractorSections from "./JournalContractorSections";

import { DEFAULT_ENTITY_ID } from "./../../constants";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";
import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import styles from "./JournalContractors.module.scss";

interface IProps {
  month: number;
  year: number;
  onSectionClick: (sectionId: number) => void;
  contractors: IJournalAcceptanceSharedContractors[];
  currentContractorId: number;
  onContractorClick: (contractorId: number, name: string) => void;
  currentSectionId: number;
  onBackFromSection: () => void;
  activeContractorName: string;
}

const JournalContractors: React.FC<IProps> = ({
  month,
  year,
  onSectionClick,
  contractors,
  currentContractorId,
  onContractorClick,
  currentSectionId,
  onBackFromSection,
  activeContractorName,
}) => {
  const buildingId = useObjectId();
  const buildingData = useBuildingDetailDataById(buildingId);

  return (
    <div className={styles.contractors}>
      {!currentContractorId && (
        <JournalContractorRow
          name={buildingData?.entity_name!}
          count={0}
          onSectionClick={() => onContractorClick(DEFAULT_ENTITY_ID, buildingData?.entity_name!)}
        />
      )}
      {!currentContractorId &&
        contractors.map((el) => (
          <JournalContractorRow
            name={el.entity_name}
            count={el.facts_to_confirm}
            key={el.entity_id}
            onSectionClick={() => onContractorClick(el.entity_id, el.entity_name)}
          />
        ))}
      {!!currentContractorId && currentContractorId !== DEFAULT_ENTITY_ID && (
        <JournalContractorSections
          onBackFromSection={onBackFromSection}
          contractors={contractors}
          currentContractorId={currentContractorId}
          year={year}
          month={month}
          onSectionClick={onSectionClick}
          activeContractorName={activeContractorName}
        />
      )}
    </div>
  );
};

export default JournalContractors;
