import cn from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadContractorDetail } from "redux/modules/_TODO/constructing/counterpartiesV2/contractors/thunks";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import { useTypedSelector } from "app/store/typedUseSelector";
import formatStringByPattern from "format-string-by-pattern";

import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";

import getShortFullName from "../../../../../../utils/formatters/getShortFullName";

import styles from "./ContractorInfo.module.scss";

interface IProps {
  contractorId: number;
}

const ContractorInfo: React.FC<IProps> = ({ contractorId }) => {
  const dispatch = useDispatch();
  const contractor = useTypedSelector((state) => state.counterpartiesV2.contractors.detail)[contractorId];
  const contractorIsLoading = useTypedSelector((state) => state.counterpartiesV2.contractors.isLoading)[contractorId];

  useEffect(() => {
    dispatch(loadContractorDetail(contractorId));
  }, [contractorId]);

  if (contractorIsLoading) return <Spinner />;

  return (
    <div>
      <section>
        <header className={cn(styles.header, styles.title)}>Основные</header>
        <div className={styles.main}>
          <div className={styles.block}>
            <h2 className={styles.title}>{LOCALIZATION_CONFIG.taxesNumberLabel}</h2>
            <div>{contractor.itn}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>КПП</h2>
            <div>{contractor.iec}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>ОГРН</h2>
            <div>{contractor.psrn}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Телефон</h2>
            <a href={`tel:${contractor.contact_manager.phone_number}`} className={styles.blueValue}>
              {formatStringByPattern("+7(999)999-99-99")(contractor.contact_manager.phone_number)}
            </a>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Представитель</h2>
            <div>{getShortFullName(contractor.contact_manager)}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Email</h2>
            <a className={styles.blueValue} href={`mailto:${contractor.contact_manager.email}`}>
              {contractor.contact_manager.email}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default React.memo(ContractorInfo);
