import React from "react";

import ChartPopup from "components/pages/Building/components/ChartPopup/ChartPopup";
import { IPropjectInList } from "pages/Projects/model/types";

import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import SettingGear from "images/SettingGear";

import styles from "./ProjectsTable.module.scss";

interface IProps {
  project: IPropjectInList;
  onClick: (id: number) => void;
  onSettingsClick: (id: number) => void;
}

const isTransferred = false;
const viewEditBuildingPermission = true;

const ProjectsTableRow: React.FC<IProps> = ({ project, onClick, onSettingsClick }) => {
  return (
    <TableReusableRow
      containerClassName={styles.rowContainer}
      className={styles.row}
      isHoverable
      onClick={() => onClick(project.id)}
    >
      <TableReusableCell isNoBreak>{project.name}</TableReusableCell>
      <TableReusableCell isNoBreak>{project.entity_name}</TableReusableCell>
      <TableReusableCell isNoBreak>{project.customer}</TableReusableCell>
      <TableReusableCell className={styles.actionsContainer}>
        {!isTransferred && (
          <ProgressBar
            completed={Math.round(+project?.calculation?.complete_percent * 100 || 0)}
            className={styles.progress}
          />
        )}
        {!isTransferred && (
          <ChartPopup
            all={Number(project?.calculation?.budget || 0)}
            completed={Number(project?.calculation?.doned || 0)}
            accepted={Number(project?.calculation?.received || 0)}
            containerClassName={styles.textSize}
            type="currency"
            popupClassName={undefined}
            onHover={undefined}
            isLast={undefined}
          />
        )}
        {/* {isTransferred && (
          <div onClick={stopEventPropagation} className={styles.sharingWrapper}>
            <SelectStatus
              options={TRANSFERRED_STATUS_OPTIONS}
              onChange={statusChangeHandler}
              value={"accepted"}
              isLoading={isSharingPending}
            />
          </div>
        )} */}
        <div className={styles.buttonGroup} onClick={stopEventPropagation}>
          {!isTransferred && viewEditBuildingPermission && (
            <div className={styles.settingsBtn} onClick={() => onSettingsClick(project.id)}>
              <SettingGear color="#fff" />
            </div>
          )}
        </div>
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default ProjectsTableRow;
