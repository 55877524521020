import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  journalAcceptanceSectionsSelector,
  journalAcceptanceSharedContractorsSelector,
} from "redux/modules/common/building/journal/acceptance/selectors";
import { getAcceptanceSections } from "redux/modules/common/building/journal/acceptance/thunks";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { DEFAULT_ENTITY_ID } from "./../constants";

import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

interface IProps {
  contractorId?: number;
  year: number;
  month: number;
}

export const useJournalSharingContractorName = ({ contractorId, year, month }: IProps) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const key = generateJornalTicketSectionKey(objectId, year, month);
  const sharedContractors = useSelector(journalAcceptanceSharedContractorsSelector)[key];

  const [activeContractorName, setActiveContractorName] = useState("");

  const detailedBuilding = useBuildingDetailDataById(objectId);

  useEffect(() => {
    if (!contractorId) return;
    if (contractorId === DEFAULT_ENTITY_ID) {
      setActiveContractorName(detailedBuilding?.entity_name!);
      return;
    }
    if (!!sharedContractors) {
      const currentContractor = sharedContractors.find((el) => +el.entity_id === +contractorId);
      setActiveContractorName(currentContractor?.entity_name!);
    } else {
      dispatch(getAcceptanceSections(objectId, year, month));
    }
  }, [objectId, contractorId, sharedContractors, year, month, detailedBuilding?.id]);

  return activeContractorName;
};
