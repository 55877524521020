import { sliceTextByConstraint } from "./sliceTextByConstraint";

import { IIdAndName } from "types/idAndName";
import { personFullNameWithId } from "types/personsTypes";

const getShortName = (name?: string | string[]) => {
  if (!name || typeof name !== "string") return "";

  const firstLetter = name.slice(0, 1);
  return `${firstLetter.toUpperCase()}.`;
};

const isNameWithLastFirstMiddle = (name: personFullNameWithId | IIdAndName): name is personFullNameWithId => {
  return (name as personFullNameWithId).last_name !== undefined;
};

export default function getShortFullName(
  lastName?: string | personFullNameWithId | IIdAndName,
  firstName?: string | string[] | number,
  middleName?: string | string[],
  lastNameConstraint = 0
): string {
  if (typeof lastName === "object" && lastName !== null) {
    if (isNameWithLastFirstMiddle(lastName)) {
      return getShortFullName(lastName?.last_name, lastName?.first_name, lastName?.middle_name);
    }
    return lastName.name;
  }

  if (typeof lastName === "string" && !firstName && !middleName) {
    const [last, first, middle] = lastName.split(" ");
    if (!!last && !!first) {
      return getShortFullName(last, first, middle);
    }
  }

  const shortFirstName = getShortName(firstName as string);
  const shortMiddleName = getShortName(middleName);

  if (!lastName || !firstName) return "Имя не указано";

  return `${
    lastNameConstraint > 0 ? sliceTextByConstraint(lastName as any, lastNameConstraint) : lastName
  } ${shortFirstName} ${shortMiddleName}`;
}
