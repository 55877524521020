import cn from "classnames";
import classNames from "classnames";
import React from "react";

import HideBlock from "components/UI/atoms/_TODO/HideBlock";

import { IPermission, IPermissionsTree } from "features/permissions/model/types";

import styles from "./HelpModalNavigation.module.scss";

interface IProps {
  group: IPermissionsTree;
  activePerm: string;
  onClick: (perm: string) => void;
  activePermPath?: string[];
  search: string;
  onOpen: (perm: string) => void;
}

const HelpModalNavigation: React.FC<IProps> = ({ group, activePerm, onClick, activePermPath, search, onOpen }) => {
  const isNeedToExtractPermissions = !group.name && !group.groups.length && group.permissions.length;

  const isGroupHidden = group.permissions.every((el) => !el.showing_in_instructions);

  if (isGroupHidden) {
    return null;
  }

  if (isNeedToExtractPermissions) {
    return (
      <>
        {group.permissions.map((el) => {
          if (el.user_disabled || !el.showing_in_instructions) {
            return null;
          }
          return (
            <div
              key={el.alias}
              onClick={() => onClick(el.alias)}
              className={cn(styles.btn, { [styles.active]: el.alias === activePerm })}
            >
              {el.title}
            </div>
          );
        })}
      </>
    );
  }

  if (group.permissionsDisabled) {
    return null;
  }

  /*  if (!group.groups.length && group.permissions.length === 1 && false) {
    const permission = group.permissions[0];

    if (!permission.showing_in_instructions) {
      return null;
    }

    return (
      <div className={styles.aloneBtnWrapper}>
        <div
          key={permission.alias}
          onClick={() => onClick(permission.alias)}
          className={cn(styles.btn, { [styles.active]: permission.alias === activePerm })}
        >
          {permission.title}
        </div>
      </div>
    );
  } */

  return (
    <div className={styles.container}>
      <HideBlock
        timeout={0}
        titleClassName={classNames(styles.titleClassName, {
          [styles.activeParent]: activePerm === group.permissions.at(-1)?.alias,
        })}
        title={group.name}
        isHiddenDefault={!activePermPath?.includes(group.name) && !search}
        onOpenAction={() => {
          const rootPerm = group.permissions?.[0];
          if (!rootPerm) return;
          onOpen?.(rootPerm.alias);
        }}
      >
        {group.groups.map((el) => (
          <HelpModalNavigation
            key={el.name}
            group={el}
            activePerm={activePerm}
            onClick={onClick}
            activePermPath={activePermPath}
            search={search}
            onOpen={onOpen}
          />
        ))}
        {group.permissions.map((el) => {
          if (el.user_disabled || !el.showing_in_instructions) {
            return null;
          }
          if (el.title === group.name) {
            return null;
          }
          return (
            <div
              key={el.alias}
              onClick={() => onClick(el.alias)}
              className={cn(styles.btn, { [styles.active]: el.alias === activePerm })}
            >
              {el.title}
            </div>
          );
        })}
      </HideBlock>
    </div>
  );
};

export default React.memo(HelpModalNavigation);
