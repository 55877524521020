import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface InvitesState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  detail: Record<string, any>;
  list: Record<string, any>;
}

const initialState: InvitesState = {
  isLoading: {},
  invalidateKey: 0,
  detail: {},
  list: {},
};

export const invitesSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setDetail: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.detail[key] = data;
    },
    setList: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.list[key] = data;
    },
    setMoreList: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.list[key].results = [...state.list[key].results, ...data.results];
    },
  },
});

export const invitesActions = invitesSlice.actions;

export default invitesSlice.reducer;
