import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import JournalDay from "components/UI/_TODO/WorkOrMaterialsModals/JournalTicketModal/JournalDay";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import InfoItem from "components/UI/_TODO/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import PairOfInfoItems from "components/UI/_TODO/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfInfoItems";
import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import {
  useAttachFileToDrwaingSetMutation,
  useDeleteDrawingSetMutation,
  useDeleteFileFromDrawingSetMutation,
  useEditDrawingSetMutation,
  useGetDrawingSetByIdQuery,
} from "../../model/blueprintsQuery";
import BlueprintSetForm from "./BlueprintSetForm";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";

import { ICreateDrawingSet } from "../../model/types";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./BlueprintSetModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  drawingSetId: number;
  isDefaultEditing?: boolean;
  permissions: IBuildingPropsPermissions;
}

const BlueprintSetEditModal: React.FC<IProps> = ({ isOpen, onClose, drawingSetId, isDefaultEditing, permissions }) => {
  const [isEditing, setIsEditing] = useState(isDefaultEditing);
  useEffect(() => {
    setIsEditing(isDefaultEditing);
  }, [isDefaultEditing]);

  const buildingId = useObjectId();
  const { data: detailData, isLoading } = useGetDrawingSetByIdQuery({ buildingId, drawingSetId }, { skip: !isOpen });

  const [deleteSet, { isLoading: isDeleteSetLoading }] = useDeleteDrawingSetMutation();
  const [isDeletingModal, setIsDeletingModal] = useState(false);
  const onDeleteSet = () => {
    setIsDeletingModal(false);
    deleteSet({ buildingId, drawingSetId });
  };

  const [attachFile, { isSuccess: isFileLoadingSuccess, isLoading: isFileLoading }] =
    useAttachFileToDrwaingSetMutation();
  const [deleteFile] = useDeleteFileFromDrawingSetMutation();

  const onUploadFile = (file: any) => {
    getArrayFilesInBase64([file]).then(([fileB64]) => {
      attachFile({
        buildingId,
        drawingSetId,
        file: { originalname: fileB64.name, file: fileB64.file as string } as any,
      });
    });
  };

  const onDeleteFile = (fileId: number) => {
    deleteFile({ buildingId, drawingSetId, fileId });
  };

  useEffect(() => {
    if (isFileLoadingSuccess) {
      message.success("Файл прикреплен");
    }
  }, [isFileLoadingSuccess]);

  const [editSet, { isLoading: isEditingSetLoading, isSuccess: isEditingSetSuccess }] = useEditDrawingSetMutation();

  const onEdit = (vals: ICreateDrawingSet) => {
    editSet({ drawingSetId, ...vals, buildingId });
  };

  useEffect(() => {
    if (isEditingSetSuccess) {
      onClose();
      setIsEditing(false);
    }
  }, [isEditingSetSuccess]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      name={isEditing ? "Редактирование комплекта чертежей" : detailData?.title}
      customHeadline={
        !isEditing && !!detailData?.updated_at ? (
          <div className={styles.updatedAt}>
            Изменено:&nbsp;{moment(detailData?.updated_at).format("DD.MM.YYYY HH:mm")}
          </div>
        ) : undefined
      }
    >
      {isLoading && <Spinner />}
      {!isLoading && !isEditing && (
        <div className={styles.grid}>
          <PairOfInfoItems
            label1="Шифр:"
            label2="Кол-во чертежей:"
            value1={detailData?.code!}
            value2={detailData?.drawings_count}
          />
          <PairOfInfoItems
            label1="Загрузил:"
            label2="&nbsp;"
            value1={
              <JournalDay
                day={moment(detailData?.created_at).format("DD/MM/YYYY")}
                time={
                  detailData?.created_at?.includes("T") ? moment(detailData?.created_at).format("HH:mm") : undefined
                }
              />
            }
            value2={getShortFullName(detailData?.author)}
          />
          <InfoItem label="Организация, разработавшая чертеж:" value={detailData?.organization} />
        </div>
      )}
      {!isLoading && !isEditing && (
        <ExpandableFileInput
          files={detailData?.files ?? []}
          onUploadFileDirectly={onUploadFile}
          onRemoveFileDirectly={onDeleteFile}
          isLoading={isFileLoading}
          canDeleteFiles={false}
        />
      )}
      <BottomControls isDoubleBtns isExists={!isEditing && !isLoading}>
        {permissions.canDeleteDrawingSets && (
          <ButtonBase secondary onClick={() => setIsDeletingModal(true)} isLoading={isDeleteSetLoading}>
            Удалить комплект
          </ButtonBase>
        )}
        {permissions.canEditDrawingSets && (
          <ButtonBase primary onClick={() => setIsEditing(true)}>
            Редактировать
          </ButtonBase>
        )}
      </BottomControls>
      {!isLoading && isEditing && (
        <BlueprintSetForm
          initialValues={detailData}
          onClose={onClose}
          onSubmit={onEdit}
          filesInput={
            <ExpandableFileInput
              files={detailData?.files ?? []}
              onUploadFileDirectly={onUploadFile}
              onRemoveFileDirectly={onDeleteFile}
              isLoading={isFileLoading}
            />
          }
          bottomControls={
            <BottomControls isDoubleBtns isExists>
              <ButtonBase secondary onClick={() => setIsEditing(false)}>
                Отменить
              </ButtonBase>
              <ButtonBase primary type="submit" isLoading={isEditingSetLoading}>
                Сохранить
              </ButtonBase>
            </BottomControls>
          }
        />
      )}
      <ConfirmModal
        isOpen={isDeletingModal}
        onClose={() => setIsDeletingModal(false)}
        variant="secondary"
        action={onDeleteSet}
        acceptButtonText="Удалить"
        title="Подтвердите действие"
      >
        Вы действительно хотите удалить комплект чертежей?
      </ConfirmModal>
    </ModalContainer>
  );
};

export default BlueprintSetEditModal;
