import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import {
  betonIncomeDetailSelector,
  betonIncomeLoadingSelector,
  betonInvalidateKeySelector,
} from "redux/modules/common/building/beton/selectors";
import { getBetonIncomeDetail, sendBetonToProduction } from "redux/modules/common/building/beton/thunks";
import { IBetonIncomeItem, IBetonToProduction } from "redux/modules/common/building/beton/types";
import { loadWorkers, workersSelector } from "redux/modules/common/building/workers";

import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";
import FormItem from "_LEGACY/UI/__TODO/LabeledItem/FormItem/FormItem";
import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import AddRelationsModal from "components/UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationsModal";
import InputNumber from "components/UI/atoms/InputNumber";
import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IMatchingInfo } from "components/pages/Journal/components/JournalDelivery/components/JournalTickets/JournalTickets";
import { defineWorkerId } from "components/pages/Stock/components/WriteOff/utils";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";
import Modal from "shared/ui/modal/Modal";

import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { composeFieldValidators, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./BetonIncomeWriteOff.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  item: IBetonIncomeItem;
  stockId: number;
  betonId: number;
  haveViewSendToProduction: boolean;
}

const BetonIncomeWriteOff: React.FC<IProps> = (props) => {
  const { onClose, isOpen, item, stockId, betonId, haveViewSendToProduction } = props;

  const dispatch = useDispatch();
  const [idAddRelation, setIdAddRelation] = React.useState<IMatchingInfo | null>(null);
  const objectId = useObjectId();
  const [expenditureId, setExpenditureId] = useState<number | null>(null);
  const [expenditureName, setExpenditureName] = useState<string | null>(null);
  const objectDetail = useBuildingDetailDataById(objectId);
  const workers = useSelector(workersSelector);
  const invalidateKey = useSelector(betonInvalidateKeySelector);
  const isLoading = useSelector(betonIncomeLoadingSelector)[generateStorageKey({ stockId, betonId: item.id })];

  const detailItem = useSelector(betonIncomeDetailSelector)[item.id];

  useEffect(() => {
    if (!isOpen || !stockId) return;
    dispatch(getBetonIncomeDetail(stockId, item.id));
  }, [stockId, isOpen, item.id]);

  useEffect(() => {
    if (!detailItem?.default_exp_work_id) return;
    setExpenditureId(detailItem?.default_exp_work_id);
    setExpenditureName(detailItem?.default_exp_work_name);
  }, [detailItem]);

  useEffect(() => {
    onClose();
  }, [invalidateKey]);

  const bindHandler = (expenditureId: number, expenditure: { expenditure: { name: string } }) => {
    setExpenditureId(expenditureId);
    setExpenditureName(expenditure.expenditure.name);
  };

  const workersAndEmployees = useMemo(() => {
    if (!workers || !objectDetail) return [];
    return [
      ...objectDetail.employees.map((el) => ({
        ...el,
        id: `user_${el.id}`,
        name: `${el.last_name} ${el.first_name} ${el.middle_name}`,
      })),
      ...workers.results.map((el: any) => ({
        ...el,
        id: `worker_${el.id}`,
        name: `${el.last_name} ${el.first_name} ${el.middle_name}`,
      })),
    ];
  }, [objectDetail, workers]);

  useEffect(() => {
    if (!objectId || !isOpen) return;
    dispatch(loadWorkers(objectId, { limit: 1000 }));
  }, [objectId, isOpen]);

  const submitHandler = (vals: Pick<IBetonToProduction, "count"> & { employee: string }) => {
    const definedUserOrWorker = defineWorkerId(vals.employee, "user", "worker");
    const data: IBetonToProduction = {
      count: vals.count,
      ...definedUserOrWorker,
      work: expenditureId,
    };
    dispatch(sendBetonToProduction(stockId, betonId, data));
  };

  const openRelationsHandler = () => {
    setIdAddRelation({
      id: expenditureId ?? -1,
      editable: true,
      expenditure_id: expenditureId ?? -1,
    });
  };

  return (
    <>
      <Modal
        title="Отправить в производство"
        onClose={onClose}
        isOpen={isOpen}
        className={styles.modal}
        closeOnClickOutside={!idAddRelation}
      >
        <Form
          onSubmit={submitHandler}
          render={({ hasValidationErrors, values, handleSubmit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <>
                <div className={styles.grid}>
                  <FormItem title="Наименование">
                    <div className={styles.name} title={item.name}>
                      {item.name}
                    </div>
                  </FormItem>
                  <FormItem title={`Доступно, ${item.measure}`}>{item.left_count}</FormItem>
                  <Field
                    name="count"
                    render={({ input, meta }) => (
                      <FormItem title={`Объем, ${item.measure}`}>
                        <InputNumber
                          placeholder="0.00"
                          className={styles.volume}
                          value={input.value}
                          setValue={input.onChange}
                        />
                      </FormItem>
                    )}
                  />
                  <FormItem title="Ответственный">
                    <Field
                      validate={composeFieldValidators(required())}
                      name="employee"
                      component={Select as any}
                      containerClassName={styles.select}
                      options={workersAndEmployees}
                    />
                  </FormItem>
                  {/*<FormItem title="Оси и отметки">*/}
                  {/*  <Field*/}
                  {/*    name="wew"*/}
                  {/*    component={Select as any}*/}
                  {/*    options={[]}*/}
                  {/*    disabled*/}
                  {/*    containerClassName={styles.select}*/}
                  {/*  />*/}
                  {/*</FormItem>*/}
                  {/*<FormItem title="&nbsp;">*/}
                  {/*  <Field*/}
                  {/*    name="wew"*/}
                  {/*    component={Select as any}*/}
                  {/*    options={[]}*/}
                  {/*    disabled*/}
                  {/*    containerClassName={styles.select}*/}
                  {/*  />*/}
                  {/*</FormItem>*/}
                  <FormItem
                    title={
                      <div className={styles.estimateTitle}>
                        Расценка
                        {!!expenditureName && (
                          <MatchingIcon
                            className={styles.matchingInHeader}
                            status={MatchingStatusesEnum.auto}
                            onClick={openRelationsHandler}
                            disabled={!!idAddRelation?.id}
                          />
                        )}
                      </div>
                    }
                    className={styles.estimate}
                  >
                    {isLoading && (
                      <div className={styles.spinner}>
                        <Spinner isSmall isStatic />
                      </div>
                    )}
                    {!isLoading && !!expenditureName && <div className={styles.expenditureName}>{expenditureName}</div>}
                    {!isLoading && !expenditureName && (
                      <ButtonBase
                        secondary
                        className={styles.choseBtn}
                        onClick={!idAddRelation?.id ? openRelationsHandler : undefined}
                      >
                        Выбрать
                        <MatchingIcon status={MatchingStatusesEnum.auto} />
                      </ButtonBase>
                    )}
                  </FormItem>
                </div>
                <div className={styles.controls}>
                  <ButtonBase secondary type="reset" onClick={onClose}>
                    Отменить
                  </ButtonBase>
                  {haveViewSendToProduction && (
                    <ButtonBase
                      primary
                      type="submit"
                      disabled={
                        hasValidationErrors ||
                        !parseFloat(values.count) ||
                        isNaN(parseFloat(values.count)) ||
                        !expenditureId
                      }
                    >
                      В производство
                    </ButtonBase>
                  )}
                </div>
              </>
            </form>
          )}
        />
      </Modal>
      {/* @ts-ignore */}
      <AddRelationToProduct
        key={idAddRelation?.id}
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(null)}
        objectId={objectId}
        onSubmit={bindHandler as any}
        updateProducts={() => void 0}
        typeForFilter="work"
      />
    </>
  );
};

export default BetonIncomeWriteOff;
