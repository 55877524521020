import classNames from "classnames";
import React from "react";

import { sliceTextByConstraint } from "../../../../../../utils/formatters/sliceTextByConstraint";

import styles from "../../Expenditure.module.scss";

const RelatedWork = ({ relatedWork }) => (
  <div className={classNames(styles.relatedWork, "relatedWork")} title={relatedWork.name}>
    №{relatedWork.number} {relatedWork.name}
  </div>
);

export default React.memo(RelatedWork);
