import cn from "classnames";
import React, { useContext, useMemo } from "react";

import { ChartCheckpointsMarks } from "redux/modules/common/chart/types";

import { useChartUnitMultiplier } from "components/pages/Chart/hooks/useChartUnitMultiplier";

import { generateMonthsForLine } from "../ChartDateLine.utils/generateMonthsForLine";
import { weekDatesRange } from "../ChartDateLine.utils/weekDatesRange";

import styles from "./ChartDateLineMonths.module.scss";
import { useDispatch } from "react-redux";
import { ChartContext } from "components/pages/Chart/Chart";
import { openViewCheckpointModalList } from "widgets/ViewCheckpoint/model/thunks";

export interface IMonthsLineProps {
  year: number;
  unitOffset: number;
  checkpointsMarks: ChartCheckpointsMarks["yearWeeks"];
}

export const ChartDateLineMonths = React.forwardRef(({ year, unitOffset, checkpointsMarks }: IMonthsLineProps, ref) => {
  const dispatch = useDispatch();
  const months = useMemo(() => generateMonthsForLine(+year), [year]);
  const unitMultiplier = useChartUnitMultiplier();
  
  const { projectId } = useContext(ChartContext);

  const openCheckpointModal = (week: number) => () => {
    const { startDate, endDate } = weekDatesRange({ year, week });
    dispatch(openViewCheckpointModalList({ startDate, endDate, objectId: projectId }));
  };

  return (
    <div
      className={styles.monthsLine}
      ref={(r) => {
        /* @ts-ignore */
        if (!ref.current) return; /* @ts-ignore */
        ref.current[year] = r;
      }}
      style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
    >
      {months?.map((month, index) => {
        return (
          <React.Fragment key={`${year}_${month.month}`}>
            <div
              className={cn(styles.monthCaptionBlock, "startMonth")}
              style={{
                left: `${unitMultiplier * (month.weeks[0] - 1)}rem`,
                width: `${unitMultiplier * month.weeks.length}rem`,
              }}
              data-year={year}
              data-month={index}
            >
              <span className={styles.monthName}>{month.name}</span>
              <span className={styles.monthInterval}>{month.label}</span>
            </div>
            {month.weeks?.map((w) => (
              <div
                key={w}
                className={cn(styles.weekNumber, {
                  [styles.currentWeek]: month.todayWeek === w,
                  [styles.hasCheckpoint]: checkpointsMarks[`${year}-${w}`],
                })}
                style={{
                  left: `${(w - 0.5) * unitMultiplier}rem`,
                }}
                onClick={checkpointsMarks[`${year}-${w}`] ? openCheckpointModal(w) : undefined}
              >
                <span>{w}</span>
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
});
