import moment from "moment";
import React from "react";

import LabelTemplate from "_LEGACY/UI/__TODO/LabeledItem/LabelTemplate/LabelTemplate";
import TaskCommentsContainer from "components/pages/Tasks/components/TaskComments/TaskCommentsContainer";

import PriorityRange from "../../../PriorityRange/PriorityRange";
import TaskApproversView from "../../../TaskApprovers/TaskApproversView";
import { renderTextWithLinks } from "../../../TaskComments/TaskOneComment";
import TaskFiles from "../../../TaskFiles/TaskFiles";
import TaskHistory from "../../../TaskHistory/TaskHistory";

import {
  EXTRA_MODAL_TAB,
  MAIN_MODAL_TAB,
  TASK_MODAL_TYPES,
  TASK_REMEMBER,
  TASK_REPEAT,
  TASK_STATUSES,
} from "../../../../constants";

import { useTasksModal } from "../../../../hooks/useTasksModal";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

import getShortFullName from "../../../../../../../utils/formatters/getShortFullName";

import styles from "./ModalViewTask.module.scss";

export interface IModalViewTaskProps {
  activeTab: string;
}

const ModalViewTask: React.FC<IModalViewTaskProps> = ({ activeTab }) => {
  const { taskId, placementType, listType, list_id, onClose } = useTasksModal(); /* @ts-ignore */
  const { updateTaskStatusDirectly, isTaskStatusEditable, certainTask } = useUpdateTask({
    taskId,
    placementType,
    listType,
    list_id,
  });

  if (!certainTask) return null;

  const {
    id,
    description,
    deadline,
    priority,
    building_obj,
    section_obj,
    executor_user,
    viewers,
    creator_user,
    repeat_task,
    remember_task,
    task_files,
    task_comments,
    viewers_data,
    status,
  } = certainTask;

  return (
    <div className={styles.viewContainer}>
      {activeTab === MAIN_MODAL_TAB && (
        <>
          {description && (
            <LabelTemplate label={"Описание задачи"}>{renderTextWithLinks(description, onClose)}</LabelTemplate>
          )}
          <div className={styles.pair}>
            {deadline && (
              <LabelTemplate label={"Срок сдачи"}>
                <div className={styles.date}>
                  {moment(deadline).format("DD/MM/YY")}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6 2.5C6 2.22386 6.22386 2 6.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.00001H16V2.5C16 2.22386 16.2239 2 16.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4.00001H18.5C20.433 4.00001 22 5.56701 22 7.50001V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V7.50001C2 5.56701 3.567 4.00001 5.5 4.00001H6V2.5ZM4 18.5V9H20V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5ZM13.5 14C13.2239 14 13 14.2239 13 14.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14H13.5Z"
                      fill="#4FB1EB"
                    />
                  </svg>
                  {moment(deadline).format("HH:mm")}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.9998 3.7998C16.5285 3.7998 20.1998 7.47107 20.1998 11.9998C20.1998 16.5285 16.5285 20.1998 11.9998 20.1998C7.47107 20.1998 3.7998 16.5285 3.7998 11.9998C3.7998 7.47107 7.47107 3.7998 11.9998 3.7998ZM22.1998 11.9998C22.1998 6.3665 17.6331 1.7998 11.9998 1.7998C6.3665 1.7998 1.7998 6.3665 1.7998 11.9998C1.7998 17.6331 6.3665 22.1998 11.9998 22.1998C17.6331 22.1998 22.1998 17.6331 22.1998 11.9998ZM12.9998 8.3998C12.9998 7.84752 12.5521 7.3998 11.9998 7.3998C11.4475 7.3998 10.9998 7.84752 10.9998 8.3998V11.9998C10.9998 12.7173 11.4039 13.3733 12.0442 13.6962L15.1426 15.2892C15.6338 15.5417 16.2366 15.3482 16.4892 14.857C16.7417 14.3659 16.5482 13.763 16.057 13.5105L12.9998 11.9387V8.3998Z"
                      fill="#4FB1EB"
                    />
                  </svg>
                </div>
              </LabelTemplate>
            )}
            {priority && <PriorityRange disabled defaultValue={priority} value={priority} className={styles.range} />}
          </div>
          {building_obj && <LabelTemplate label={"Проект"}>{building_obj.name}</LabelTemplate>}
          {section_obj && <LabelTemplate label={"Раздел"}>{section_obj.name}</LabelTemplate>}
          {executor_user && (
            <LabelTemplate label={"Исполнители"}>
              <div className={styles.bubbles}>
                <div className={styles.bubble}>
                  <span>
                    {getShortFullName(executor_user.last_name, executor_user.first_name, executor_user.middle_name)}
                  </span>
                </div>
              </div>
            </LabelTemplate>
          )}
          {viewers?.length > 0 && (
            <LabelTemplate label={`Контролеры: ${viewers.length}`}>
              <div className={styles.bubbles}>
                {viewers_data.map((viewer) => (
                  <div className={styles.bubble} key={viewer.id}>
                    <span>{getShortFullName(viewer.last_name, viewer.first_name, viewer.middle_name)}</span>
                  </div>
                ))}
              </div>
            </LabelTemplate>
          )}
          <LabelTemplate label={"Напоминания и повторы"}>
            <div className={styles.repeat}>
              {/* @ts-ignore */}
              <span>{remember_task ? TASK_REMEMBER[remember_task[0]] : "не напоминать"}</span>
              {/* @ts-ignore */}
              <span>{repeat_task ? TASK_REPEAT[repeat_task] : "без повтора"}</span>
            </div>
          </LabelTemplate>
          <TaskFiles
            files={task_files.map((file) => ({ ...file, name: file.file_name })) || []}
            taskId={id}
            variant={TASK_MODAL_TYPES.VIEW}
          />
          <TaskCommentsContainer comments={task_comments || []} taskId={id} />
          <TaskApproversView taskId={id} approvers={certainTask?.approves_data} />
        </>
      )}
      {activeTab === EXTRA_MODAL_TAB && (
        <>
          <TaskHistory taskId={certainTask?.id!} />
        </>
      )}
    </div>
  );
};

export default React.memo(ModalViewTask);
