import cn from "classnames";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import PopoverOverlay from "../../../../../../components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import Calendar from "../../../Calendar/Calendar";
import useClosureDates, { IClosureDate } from "../../useClosureDates";
import DoubleInput, { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";

import { CLOSURE_DATE_PLATE_BACKGROUNDS } from "../ClosureDatePlate/constants";

import useClosureDatesDoubleInput from "../../hooks/useClosureDatesDoubleInput";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../../utils/percentsFormatter";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import CalendarIcon from "../../../../../../images/icons/CalendarIcon";
import CrossRoundedIcon from "../../../../../../images/icons/CrossRoundedIcon";
import InfoCircle from "../../../../../../images/icons/InfoCircle";

import styles from "./ClosureDatePlate.module.scss";

export interface IClosureDatePlateProps
  extends Partial<Pick<ReturnType<typeof useClosureDates>, "removeClosureDate" | "editClosureDate">> {
  date: IClosureDate;
  canEdit: boolean;
  budget: string;
  canSave: boolean;
  isLastIndex: boolean;
  closureDates: any;
}

const ClosureDatePlateWithPlanPercents: React.FC<IClosureDatePlateProps> = ({
  date,
  removeClosureDate,
  editClosureDate,
  canEdit,
  budget,
  canSave,
  isLastIndex,
  closureDates,
}) => {
  const [tmpDate, setTmpDate] = useState<Moment | null>(date?.payment_date ? moment(date?.payment_date) : null);
  const { changedDiscountCost, changedDiscountPercent, onChangeDiscountPercent, onChangeDiscountCost } =
    useClosureDatesDoubleInput({
      amountSource: budget,
      discountPercent: date.user_percent /* @ts-ignore */,
      discountCost: (+budget * +date.user_percent) / 100 /* @ts-ignore */,
      editClosureDate: (payload) => editClosureDate(date.id, payload),
    });
  const plateBg = useMemo(() => {
    if (+changedDiscountPercent === 0) return CLOSURE_DATE_PLATE_BACKGROUNDS.empty;
    if (!canEdit) return CLOSURE_DATE_PLATE_BACKGROUNDS.default;
    if ((canEdit && !tmpDate?.isValid()) || (isLastIndex && !canSave)) return CLOSURE_DATE_PLATE_BACKGROUNDS.empty;
    if ((canEdit && !!tmpDate?.isValid()) || (!isLastIndex && canSave)) return CLOSURE_DATE_PLATE_BACKGROUNDS.active;
    return CLOSURE_DATE_PLATE_BACKGROUNDS.default;
  }, [canEdit, tmpDate, isLastIndex, canSave, changedDiscountPercent]);

  const calendarIconBg = useMemo(() => {
    if (canEdit) return "white";
    return plateBg;
  }, [canEdit, plateBg]);

  const hasInfo = useMemo(
    () =>
      (date.count_plans !== 0 && parseFloat(date.percent || "") > 0) ||
      parseFloat(date.amount || "") > 0 ||
      (date.count_plans && date.count_plans > 0),
    [date]
  );

  const handleRemoveDate = () => {
    if (!canEdit) return;
    removeClosureDate?.(date.id);
  };

  useEffect(() => {}, []);

  const handleChangeDate = useCallback(
    (newDate: string) => {
      if (
        !moment(newDate)?.isValid() ||
        !canEdit ||
        !editClosureDate?.(date.id, { payment_date: moment(newDate).format("YYYY-MM-DD") })
      ) {
        return;
      } else {
        setTmpDate(moment(newDate));
        editClosureDate?.(date.id, { payment_date: moment(newDate).format("YYYY-MM-DD") });
      }
    },
    [canEdit, closureDates]
  );

  return (
    <div className={styles.datesPlate} style={{ backgroundColor: plateBg }}>
      <Calendar
        value={date.payment_date ? date.payment_date : tmpDate}
        /* @ts-ignore */
        setValue={canEdit ? handleChangeDate : () => {}}
        classNameSelect={cn(styles.calendarInput, {
          [styles.calendarInputEmpty]: !tmpDate?.isValid() && canEdit,
          [styles.calendarInputFilled]: tmpDate?.isValid() && canEdit,
        })}
        format={"DD.MM.YYYY"}
        placeholder={"Нет даты"}
        variant={!canEdit ? "display" : undefined}
        disabled={!canEdit}
        customIcon={<CalendarIcon background={calendarIconBg} className={styles.calendarInputIcon} />}
      />
      <DoubleInput
        className={styles.doubleInput}
        firstValueMeasure={Measure.percent}
        //@ts-ignore
        firstValue={changedDiscountPercent}
        firstInputSize={2}
        secondValueMeasure={Measure.currency}
        //@ts-ignore
        secondValue={changedDiscountCost}
        secondInputSize={2}
        onChangeFirstValue={onChangeDiscountPercent}
        onChangeSecondValue={onChangeDiscountCost}
        variant={"outline"}
        isFirstDisabled={!canEdit}
        isSecondDisabled={!canEdit}
      />
      <div className={styles.actions}>
        <PopoverOverlay
          isDisabled={!hasInfo}
          openType={"hover"}
          portalClassName={styles.modalPortal}
          popoverBorderColor={"default"}
          placement={"bottom"}
          className={styles.popoverAnchor}
          content={
            <div className={styles.infoContent}>
              <div className={styles.infoContentElement}>
                <span className={styles.infoContentElementTitle}>
                  {percentsFormatter(parseFloat(date.percent || date.user_percent || "") || 0)}
                </span>
                <span className={styles.infoContentElementSubtitle}>% закрытия</span>
              </div>
              <div className={styles.infoContentElement}>
                <span className={styles.infoContentElementTitle}>
                  {transformDigitToFinancial(date.amount || "", {
                    withFloat: true,
                    dropZeros: true,
                    withCurrencySign: true,
                  })}
                </span>
                <span className={styles.infoContentElementSubtitle}>сумма закрытия</span>
              </div>
              <div className={styles.infoContentElement}>
                <span className={styles.infoContentElementTitle}>{date.count_plans}</span>
                <span className={styles.infoContentElementSubtitle}>работ к закрытию</span>
              </div>
            </div>
          }
        >
          <InfoCircle className={cn(styles.icon, { [styles.iconActive]: hasInfo })} />
        </PopoverOverlay>
        <hr />
        <CrossRoundedIcon
          color={"black"}
          className={cn(styles.icon, { [styles.iconActive]: canEdit })}
          onClick={handleRemoveDate}
        />
      </div>
    </div>
  );
};

export default React.memo(ClosureDatePlateWithPlanPercents);
