import { message } from "antd";
import cn from "classnames";
import { groupBy } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { approvedIntervalsSelector } from "../../../../../redux/modules/common/building/process";
import {
  deleteExpendituresGroups,
  updateExpendituresGroup,
} from "../../../../../redux/modules/common/building/shedules";

import Checkbox from "../../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";
import { useUIIndicator } from "../../../../../hooks/uiIndicators/useUIIndicator";
import AddRelationsModal from "../../../../UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationsModal";
import DefaultColumns from "../../../../UI/_TODO/Expenditure/components/DefaultColumns/DefaultColumns";
import Info from "../../../../UI/_TODO/Expenditure/components/Info/Info";
import ExpenditureGroupModal from "../../../../UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import { VARIANTS } from "components/UI/_TODO/Expenditure/constants";
import EditExpendituresInHandler from "pages/Handler/ui/ProHandler/components/AddExpenditure/EditExpendituresInHandler";
import { IExpenditureInHandlerProduction } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/types";
import { prepareWorkAndRelatedResourcesForDeleting } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/utils";

import ChartPopup from "../ChartPopup/ChartPopup";
import moment from "moment/moment";
import Actions from "shared/ui/controls/Actions/Actions";

import { EXPENDITURE_TYPES } from "../../../../../constants/constant";
import { TabsEnum } from "../../enums";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";
import { useObjectId } from "../../../Documents/hooks/useObjectId";

import {
  isExpenditureSharedToMeButCantAddInterval,
  isExpenditureSharedToMyContractor,
} from "../../../../../utils/checkers/checkExpenditureShared";
import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import { addIntervalChecker } from "../../utils";

import CheckDouble from "../../../../../images/icons/CheckDouble";

import styles from "./BuildingExpenditure.module.scss";

export interface IBuildingExpenditureProps {
  className?: string;
  buildingId: number;
  expenditureOrGroup: any;
  intervals: any[];
  loadProduct: () => void;
  tab: TabsEnum;
  isShared: boolean;
  sectionName?: string;
  isChecked: boolean;
  onCheck: (e: ChangeEvent) => void;
  canCheck: boolean;
  actions: any;
  loadSectionExpenditures: () => void;
  loadSectionGroups: () => void;
  permissions?: Record<string, boolean>;
  parentId: number;
  isKSHidden: boolean;
  lsrPlanData: any;
  canViewFiles?: boolean;
  isExpendituresGroup?: boolean;
  activeTab: string;
  factIntervals?: any[];
  planIntervals?: any[];
  onOpenApprovedPlanModal?: any;
  dateStart: string;
  dateEnd: string;
  sectionId: number;
  sectionParentId: number;
  isEditing: boolean;
  setIsEditing: (id: number) => void;
  onCancelEdit: (id: number) => void;
  directlyAction?: React.ReactNode;
}

const BuildingExpenditure: React.FC<IBuildingExpenditureProps> = ({
  className,
  buildingId,
  expenditureOrGroup,
  intervals,
  loadProduct,
  tab,
  isShared,
  sectionName,
  isChecked,
  onCheck,
  canCheck,
  actions,
  loadSectionExpenditures,
  loadSectionGroups,
  permissions = {},
  parentId,
  isKSHidden,
  lsrPlanData,
  canViewFiles = true,
  isExpendituresGroup = false,
  activeTab,
  factIntervals,
  planIntervals,
  onOpenApprovedPlanModal,
  dateStart,
  dateEnd,
  sectionId,
  sectionParentId,
  isEditing,
  setIsEditing,
  onCancelEdit,
  directlyAction,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId(2);
  const moduleRoute = useUrlModule();

  const approvedIntervals = useSelector(approvedIntervalsSelector)[parentId];

  const [overviewState, setOverviewState] = useState({});
  const [isShowGroupModal, setIsShowGroupModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [relationOriginInfo, setRelationOriginInfo] = useState(null);

  const isProgressVariant = tab === VARIANTS.PROGRESS;

  const expenditureDisplayedCount = dropNonSignificantZeros(expenditureOrGroup.count);

  const slicedIntervals = useMemo(() => {
    return {
      /* @ts-ignore */
      plans: intervals?.plans /* @ts-ignore */
        ? intervals?.plans?.slice(0, isExpanded ? intervals?.plans?.length : 3)
        : [] /* @ts-ignore */,
      facts: intervals?.facts /* @ts-ignore */
        ? intervals?.facts?.slice(0, isExpanded ? intervals?.facts?.length : 3)
        : [] /* @ts-ignore */,
      donned: intervals?.donned /* @ts-ignore */
        ? intervals?.donned?.slice(0, isExpanded ? intervals?.donned?.length : 3)
        : [] /* @ts-ignore */,
      received: intervals?.received ? intervals?.received?.slice(0, isExpanded ? intervals?.received?.length : 3) : [],
      approved: approvedIntervals ? approvedIntervals?.slice(0, isExpanded ? approvedIntervals?.length : 3) : [],
    };
  }, [intervals, isExpanded, approvedIntervals]);

  const planIntervalItems = useMemo(() => {
    if (!intervals && !approvedIntervals) return []; /* @ts-ignore */
    return isProgressVariant ? intervals?.donned || [] : approvedIntervals || [];
  }, [intervals, isProgressVariant, approvedIntervals]);

  const factIntervalItems = useMemo(() => {
    if (!intervals) return []; /* @ts-ignore */
    return isProgressVariant ? intervals?.received || [] : intervals.facts || [];
  }, [intervals, isProgressVariant]);

  const inProductionIntervalItems = useMemo(() => {
    if (!intervals) return []; /* @ts-ignore */
    return isProgressVariant ? intervals?.facts || [] : intervals?.plans || [];
  }, [intervals, isProgressVariant]);

  const handleSetOverviewState = useCallback(() => {
    if (!expenditureOrGroup.indicators && expenditureOrGroup.estimate_amount) {
      setOverviewState({
        all: isExpendituresGroup ? expenditureOrGroup.amount : expenditureOrGroup.estimate_amount,
        completed: 0,
        accepted: 0,
        count_completed: 0,
        count_accepted: 0,
      });
    }
    if (!expenditureOrGroup.indicators) return;
    setOverviewState({
      all: expenditureOrGroup.indicators.estimate_amount,
      completed: expenditureOrGroup.indicators.work_completed,
      accepted: expenditureOrGroup.indicators.work_accepted,
      count_completed: expenditureOrGroup.indicators.count_completed,
      count_accepted: expenditureOrGroup.indicators.count_accepted,
    });
  }, [expenditureOrGroup.indicators, isExpendituresGroup]);
  /* @ts-ignore */
  const handleCheck = useCallback(() => onCheck(!isChecked), [onCheck, isChecked]);

  const isSharedToMyContractor = isExpenditureSharedToMyContractor(expenditureOrGroup);

  const isSharedToMeButCantAddIntervals = isExpenditureSharedToMeButCantAddInterval(expenditureOrGroup);

  const isHideCheckbox = activeTab === TabsEnum.plan && expenditureOrGroup.expenditure_type !== "work";

  const canAddIntervals =
    expenditureOrGroup.expenditure_type === EXPENDITURE_TYPES.WORK &&
    addIntervalChecker({
      activeTab: tab,
      haveAddingPlanPermission: permissions?.canAddPlan && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      haveAddingProgressPermission:
        permissions?.canAddProgress && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      moduleRoute,
    });

  const onExpand = useCallback(() => setIsExpanded((prevState) => !prevState), []);

  useEffect(() => {
    setIsExpanded(false);
  }, [tab]);

  const handleShowGroupModal = useCallback(() => {
    setIsShowGroupModal(true);
  }, []);

  const handleCloseGroupModal = useCallback(() => {
    setIsShowGroupModal(false);
  }, []);

  const groupedPlanGroupIntervals = useMemo(() => groupBy(planIntervals, (item) => item.week), [planIntervals]);
  const groupedFactGroupIntervals = useMemo(() => groupBy(factIntervals, (item) => item.week), [factIntervals]);

  const reducedPlanGroupIntervals = useMemo(
    () =>
      Object.values(groupedPlanGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: prevValue.count + +currentValue?.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedPlanGroupIntervals]
  );

  const reducedFactGroupIntervals = useMemo(
    () =>
      Object.values(groupedFactGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: +currentValue?.count + prevValue.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedFactGroupIntervals]
  );

  const currentGroupIntervals = useMemo(
    () => (tab === VARIANTS.PROGRESS ? reducedFactGroupIntervals : reducedPlanGroupIntervals),
    [tab, reducedFactGroupIntervals, reducedPlanGroupIntervals]
  );

  const currentInProductionIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => !item.is_confirmed);
    } else if (tab === VARIANTS.PLAN && reducedPlanGroupIntervals?.length > 0) {
      return reducedPlanGroupIntervals;
    }
    return inProductionIntervalItems;
  }, [tab, reducedFactGroupIntervals, reducedPlanGroupIntervals, inProductionIntervalItems]);

  const currentPlanIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed && !item.is_accepted);
    } else {
      return planIntervalItems;
    }
  }, [tab, planIntervalItems, reducedFactGroupIntervals]);

  const currentFactIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed && item.is_accepted);
    } else if (tab === VARIANTS.PLAN && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals;
    }
    return factIntervalItems;
  }, [tab, reducedFactGroupIntervals, factIntervalItems]);

  const handleUngroupExpenditure = useCallback(() => {
    dispatch(
      deleteExpendituresGroups(
        objectId,
        expenditureOrGroup?.id,
        compose(loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal)
      )
    );
  }, [objectId, expenditureOrGroup, loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal]);

  const handleEditExpenditure = useCallback(
    /* @ts-ignore */
    (data, errorCallback) => {
      dispatch(
        updateExpendituresGroup({
          buildingId: objectId,
          groupId: expenditureOrGroup.id,
          data,
          callback: compose(loadSectionExpenditures, loadSectionGroups, () => message.success("Успешно обновлено")),
          errorCallback,
        })
      );
    },
    [objectId, expenditureOrGroup, loadSectionExpenditures, loadSectionGroups]
  );

  const isInProductionIntervalCompleted = useMemo(() => {
    /* @ts-ignore */
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !currentGroupIntervals?.length && (currentFactIntervals?.length || planIntervalItems?.length);
    }

    if (tab === VARIANTS.PROGRESS) {
      /* @ts-ignore */
      return !slicedIntervals.facts?.length && (slicedIntervals.donned?.length || slicedIntervals.received?.length);
    } else {
      /* @ts-ignore */
      return !slicedIntervals.plans?.length && !slicedIntervals.approveds?.length && slicedIntervals.facts?.length;
    }
  }, [tab, slicedIntervals, factIntervals, planIntervals, planIntervalItems]);

  const isPlanIntervalsCompleted = useMemo(() => {
    /* @ts-ignore */
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !planIntervalItems?.length && currentFactIntervals?.length;
    }
    /* @ts-ignore */
    if ([VARIANTS.PROGRESS, VARIANTS.HANDLER, VARIANTS.HANDLER_DRAFT].indexOf(tab) !== -1) {
      return !slicedIntervals.donned?.length && slicedIntervals.received?.length && !slicedIntervals.facts?.length;
    } else {
      /* @ts-ignore */
      return !slicedIntervals.approveds?.length && slicedIntervals.facts?.length && !slicedIntervals.plans?.length;
    }
  }, [tab, slicedIntervals, factIntervals, planIntervals]);

  const { isIndicatorDisplayed } = useUIIndicator({
    /* @ts-ignore */
    type: "confirm_expenditure_plan",
    data: { child_section_id: sectionId, parent_section_id: sectionParentId, exp_id: expenditureOrGroup?.id },
    customFilter: (indicator) =>
      !!indicator?.data?.year &&
      !!indicator?.data?.month &&
      moment()
        .year(indicator.data.year)
        .month(indicator.data.month - 1)
        .date(1)
        .isBetween(moment(dateStart), moment(dateEnd)),
  });

  return (
    <>
      {!isEditing && (
        <div
          className={cn(
            styles.container,
            styles[tab],
            { [styles.expanded]: isExpanded, [styles.withIndicator]: isIndicatorDisplayed },
            className
          )}
        >
          <div className={cn({ [styles.content]: true, [styles.contentWithActions]: !!directlyAction })}>
            <div className={styles.numberCol}>
              <div className={styles.number}>{!isExpendituresGroup ? expenditureOrGroup.number || "-" : ""}</div>
              {canCheck && !isHideCheckbox && <Checkbox checked={isChecked} onChange={handleCheck} />}
              {!canCheck && expenditureOrGroup.shared_count > 0 && (
                <CheckDouble variant={expenditureOrGroup.shared_status === "accepted" ? "primary" : "default"} />
              )}
            </div>
            <div className={styles.infoCol}>
              {/* @ts-ignore */}
              <Info
                expenditure={expenditureOrGroup}
                buildingId={buildingId}
                variant={tab}
                isShared={isShared}
                onOverview={handleSetOverviewState}
                overviewState={overviewState}
                canViewFiles={canViewFiles}
                canAddFiles={permissions.canAddFiles}
                canDeleteFiles={permissions.canDeleteFiles}
                loadSectionExpenditures={loadSectionExpenditures}
                isKSHidden={isKSHidden}
                isExpendituresGroup={isExpendituresGroup}
                handleShowGroupModal={handleShowGroupModal}
              />
            </div>
            <div className={styles.countCol}>
              {/* @ts-ignore */}
              <div>
                {expenditureDisplayedCount as string}
                <br />
                <span
                  title={expenditureDisplayedCount + " (" + expenditureOrGroup.measure + ")"}
                  className={styles.measure}
                >
                  ({expenditureOrGroup.measure})
                </span>
              </div>
              <ChartPopup
                type="info"
                containerClassName={styles.infoContainer}
                onHover={handleSetOverviewState} /* @ts-ignore */
                accepted={overviewState.count_accepted} /* @ts-ignore */
                all={expenditureDisplayedCount} /* @ts-ignore */
                completed={overviewState.count_completed}
              />
            </div>
            <DefaultColumns
              variant={tab}
              expenditure={expenditureOrGroup}
              loadProduct={loadProduct}
              expenditureCount={expenditureDisplayedCount}
              currentInProductionIntervals={currentInProductionIntervals}
              isExpendituresGroup={isExpendituresGroup}
              buildingId={buildingId}
              onExpand={onExpand}
              groupedPlanGroupIntervals={groupedPlanGroupIntervals}
              groupedFactGroupIntervals={groupedFactGroupIntervals}
              loadSectionGroups={loadSectionGroups}
              canAddIntervals={canAddIntervals}
              isInProductionIntervalCompleted={isInProductionIntervalCompleted}
              isShared={isShared}
              isExpanded={isExpanded}
              planIntervals={currentPlanIntervals}
              isPlanIntervalsCompleted={isPlanIntervalsCompleted}
              lsrPlanData={lsrPlanData}
              canDeleteFiles={permissions.canDeleteFiles}
              factIntervals={currentFactIntervals}
              sectionName={sectionName}
              sectionId={sectionId}
              actions={actions}
              directlyAction={directlyAction}
            />
          </div>
          {isShowGroupModal && (
            <ExpenditureGroupModal
              isOpen={isShowGroupModal}
              onClose={handleCloseGroupModal}
              group={expenditureOrGroup}
              isShowButtons={true}
              handleUngroup={handleUngroupExpenditure}
              handleEdit={handleEditExpenditure}
            />
          )}
          {relationOriginInfo && (
            /* @ts-ignore */
            <AddRelationsModal
              relationOriginInfo={relationOriginInfo}
              objectId={objectId}
              handleClose={() => {
                setRelationOriginInfo(null);
              }}
            />
          )}
        </div>
      )}
      {isEditing && (
        <EditExpendituresInHandler
          onCancelEdit={() => onCancelEdit(expenditureOrGroup.id)}
          sectionId={sectionId}
          expenditure={expenditureOrGroup}
          buildingId={buildingId}
        />
      )}
    </>
  );
};

export default React.memo(BuildingExpenditure);
