import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { isAbleToCreateProjectSelector } from "../../../../redux/modules/_TODO/auth";

import AlertPopup from "../../../../_LEGACY/UI/__trash/AlertPopup/AlertPopup";
import ForbiddenPage from "../../../../app/routes/components/ForbiddenPage/ForbiddenPage";
import ObjectsHeaderRow from "./components/ObjectsRow/ObjectsHeaderRow";
import ObjectsRow from "./components/ObjectsRow/ObjectsRow";
import { projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";

import TabBarNotLinks from "../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import { useTypedSelector } from "app/store/typedUseSelector";
import { changeObjectStatus, loadMoreObjects, loadObjects } from "features/objectsList";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { useTranslation } from "react-i18next";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Button from "shared/ui/controls/ButtonBase";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { DEFAULT_ACCEPTED_FILTERS, DEFAULT_TRANSFERRED_FILTERS } from "./constants";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { hasOnlyPaginationFilters } from "utils/hasOnlyPaginationFilters";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./index.module.scss";

interface IProps {
  permissions: any;
  isProjectsLoading: boolean;
}

const Objects: React.FC<IProps> = ({ permissions, isProjectsLoading }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAbleToCreateProject = useSelector(isAbleToCreateProjectSelector);
  const invalidateKey = useTypedSelector((state) => state.objectsListReducer.invalidationKey);

  const [acceptedFilters, setAcceptedFilters] = useState(DEFAULT_ACCEPTED_FILTERS);
  const isWithAcceptedFilters = useMemo(() => !hasOnlyPaginationFilters(acceptedFilters), [acceptedFilters]);
  const transferredFilters = useMemo(() => DEFAULT_TRANSFERRED_FILTERS, []);
  const acceptedKey = generateStorageKey({ ...acceptedFilters, project: projectId } as any);
  const transferredKey = generateStorageKey(transferredFilters as any);
  const accepted = useTypedSelector((state) => state.objectsListReducer.objects)[acceptedKey];
  const transferred = useTypedSelector((state) => state.objectsListReducer.objects)[transferredKey];
  const isLoadingMoreObjects = useTypedSelector((state) => state.objectsListReducer.loadings)[acceptedKey];

  const actualAcceptedProjects = accepted;

  const sentTabAbleToView = transferred?.results?.length !== 0;

  const getDefaultActiveTab = () => {
    if (permissions.viewAllBuildingsTab) return "all";
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) return "sent";
    if (permissions.viewAcceptedBuildingsTab) return "accepted";
  };

  const [tab, setTab] = useState(getDefaultActiveTab());

  const handleTabChange = useCallback((newTab: string) => {
    setAcceptedFilters(DEFAULT_ACCEPTED_FILTERS);
    setTab(newTab);
  }, []);

  const changeAcceptedFilters = (name: string, value: any) => {
    setAcceptedFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAcceptedAdd = () => {
    dispatch(
      loadMoreObjects(
        {},
        {
          ...acceptedFilters,
          offset: actualAcceptedProjects?.results.length,
        }
      )
    );
  };

  const handleTransferredAdd = () => {
    dispatch(
      loadMoreObjects(
        {},
        {
          ...transferredFilters,
          offset: transferred?.results.length,
        }
      )
    );
  };

  const onCreateClick = useCallback(() => {
    if (!isAbleToCreateProject) {
      message.error("Вы достигли максимального кол-ва объектов");
      return;
    }
    history.push(`/constructing/projects/create-object/${projectId}/new/passport`);
  }, [history, isAbleToCreateProject]);

  const [confirmModal, setConfirmModal] = useState({ open: false, name: "", id: null, status: null });
  const clearConfirm = useCallback(() => {
    setConfirmModal({ open: false, name: "", id: null, status: null });
  }, []);

  const handleStatusChange = (row: any, value: any) => {
    setConfirmModal({
      open: true,
      id: row?.id,
      name: row?.name,
      status: value,
    });
  };

  const [pendingSharedObjectsIds, setPendingSharedObjectsIds] = React.useState<any[]>([]);

  const changeStatus = useCallback(
    async (id: number, status: any) => {
      setPendingSharedObjectsIds((prev: any) => [...prev, id]);
      dispatch(changeObjectStatus(id, status));
      clearConfirm();
    },
    [confirmModal.id, confirmModal.status]
  );

  const cancelChange = () => {
    clearConfirm();
  };

  const tabs = React.useMemo(() => {
    const result = [];
    if (permissions.viewAllBuildingsTab) result.push({ text: "Все объекты", id: "all" });
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) result.push({ text: "Выполненные", id: "sent" });
    if (permissions.viewAcceptedBuildingsTab) result.push({ text: "Выполняются", id: "accepted" });
    return result;
  }, [
    permissions.viewAcceptedBuildingsTab,
    permissions.viewAllBuildingsTab,
    permissions.viewSentBuildingsTab,
    sentTabAbleToView,
  ]);

  useEffect(() => {
    dispatch(loadObjects({}, { ...acceptedFilters, project: projectId }));
  }, [acceptedFilters, projectId, invalidateKey]);

  useEffect(() => {
    dispatch(loadObjects({}, transferredFilters));
  }, [transferredFilters, invalidateKey]);

  /* TODO */
  const key = generateStorageKey({ projectId: 0 });
  useEffect(() => {
    dispatch(getProjectsList({}));
  }, []);
  const projects = useSelector(projectsV2ListSelector)[key];
  const parent = useMemo(() => {
    return projects?.find((el) => el.id === +projectId);
  }, [projects, projectId]);
  /*  */

  return (
    <TemplateBase dataTestId="page_objects">
      <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab!} onClick={handleTabChange} />
        {permissions.viewCreateBuilding && (
          <Button onClick={onCreateClick} secondary>
            Создать объект
          </Button>
        )}
      </ListPageHeaderTemplate>
      <BackNavigationBar
        className={styles.back}
        title="Все проекты"
        onBack={() => history.push("/constructing/projects")}
        rightSideText={
          parent ? (
            <div className={styles.total}>
              <div className={styles.budget}>
                {transformDigitToFinancial(parent?.calculation.budget, { withCurrencySign: true } as any)}
              </div>
              <div className={styles.divider}></div>
              <ProgressBar
                containerClassName={styles.progress}
                completed={Math.round((parent?.calculation.complete_percent ?? 0) * 100)}
              />
            </div>
          ) : null
        }
      />
      {tab === "all" && transferred?.results?.length > 0 && (
        <div className={styles.sharedBlock}>
          <p>
            Предложения от заказчика: <strong>{transferred.results.length}</strong>
          </p>
          {transferred.results.map((el: any) => (
            <ObjectsRow
              key={el.id}
              object={el}
              onStatusChange={handleStatusChange}
              isTransferred
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
              isSharingPending={pendingSharedObjectsIds.includes(el.id)}
              projectId={projectId}
            />
          ))}
          <ShowMoreButton
            handleAdd={handleTransferredAdd}
            showedCount={transferred?.results.length}
            allCount={transferred?.count}
            isExists={transferred?.count > transferred?.results.length}
            isLoading={isLoadingMoreObjects}
          />
          {confirmModal.open && (
            /* @ts-expect-error */
            <AlertPopup
              onClose={clearConfirm}
              title={`${confirmModal.status === "accepted" ? "Принять" : "Отклонить"} проект "${confirmModal.name}"`}
            >
              <div className={styles.alert}>
                <p>
                  Вы действительно хотите{" "}
                  <strong>{confirmModal.status === "accepted" ? "принять" : "отклонить"}</strong> проект{" "}
                  <strong>{confirmModal.name}</strong>?
                </p>
              </div>
              <div className={styles.btns}>
                {/* @ts-expect-error */}
                <button onClick={() => cancelChange(confirmModal.id)}>Отменить</button>
                <button
                  /* @ts-expect-error */
                  onClick={() => changeStatus(confirmModal.id, confirmModal.status)}
                  className={confirmModal.status === "accepted" ? styles.accept : styles.decline}
                >
                  {confirmModal.status === "accepted" ? "Принять" : "Отклонить"}
                </button>
              </div>
            </AlertPopup>
          )}
        </div>
      )}
      {tab !== "sent" && (
        <>
          {(isWithAcceptedFilters || !!actualAcceptedProjects?.results.length) && (
            <ObjectsHeaderRow onChangeFilter={changeAcceptedFilters} />
          )}
          {!isProjectsLoading && !actualAcceptedProjects?.results.length && (
            <EmptyPlaceholder text="Нет объектов" img={projectsIcon} />
          )}
          {actualAcceptedProjects?.results?.map((el: any) => (
            <ObjectsRow
              key={el.id}
              object={el}
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
              projectId={projectId}
            />
          ))}
          {isProjectsLoading && !actualAcceptedProjects?.results.length && (
            <Spinner isFullHeight={!!transferred?.results?.length} isStatic />
          )}
        </>
      )}
      <ShowMoreButton
        allCount={actualAcceptedProjects?.count}
        showedCount={actualAcceptedProjects?.results.length}
        isExists={
          /* @ts-expect-error */
          ["all", "accepted"].includes(tab) && actualAcceptedProjects?.count > actualAcceptedProjects?.results.length
        }
        handleAdd={handleAcceptedAdd}
        isLoading={isLoadingMoreObjects}
        limit={DEFAULT_ACCEPTED_FILTERS.limit}
      />
      {tab === "sent" && <EmptyPlaceholder text="Нет объектов" img={projectsIcon} />}
      {!tab && <ForbiddenPage />}
    </TemplateBase>
  );
};

export default Objects;
