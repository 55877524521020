import cn from "classnames";
import React, { useCallback, useEffect } from "react";

import Portal from "shared/ui/atoms/Portal";

import useEscapeHandler from "../../../../utils/hooks/useEscapeHandler";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./index.module.scss";

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeHandler: () => void;
  className?: string;
  childrenContainerClassName?: string;
  containerClassName?: string;
}

const SliderModal: React.FC<IProps> = ({
  children,
  isOpen,
  closeHandler,
  className,
  childrenContainerClassName,
  containerClassName,
}) => {
  useEscapeHandler(closeHandler);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "auto";
  }, [isOpen]);

  const handleShadowClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      stopEventPropagation(e);
      closeHandler?.();
    },
    [closeHandler]
  );

  if (!isOpen) return null;

  return (
    <Portal>
      {isOpen && <div className={styles.shadow} onClick={handleShadowClick} />}
      <div
        onClick={stopEventPropagation}
        className={cn(styles.containerWrapper, className, {
          [styles.active]: isOpen,
        })}
      >
        <div className={styles.closeButtonBlock} onClick={closeHandler}>
          <CrossIcon width={"1rem"} color={"#fff"} />
        </div>
        <div className={cn(styles.container, containerClassName)}>
          <div className={cn(styles.childrenContainer, childrenContainerClassName)}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};

export default React.memo(SliderModal);
