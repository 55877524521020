import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "react-textarea-autosize";

import { aosrActions } from "redux/modules/common/building/journal/aosr/actions";
import { aosrApi } from "redux/modules/common/building/journal/aosr/api";
import {
  aosrClosingPendingSelector,
  aosrCurrentDetailSelector,
  aosrInvalidateKeySelector,
  aosrWorksSelector,
} from "redux/modules/common/building/journal/aosr/selectors";
import {
  addAosrFile,
  addDocFiles,
  closeAosr,
  createDocs,
  deleteDocFiles,
  deleteDocs,
  dropCurrentAosrDetail,
  editAosr,
  editDocs,
  getWorksBySectionInAosr,
  setAosrDetail,
} from "redux/modules/common/building/journal/aosr/thunks";
import { IAosr, IAosrDoc, ICreateAosr } from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import {
  IAosrInKs2ExpenditureDocs,
  IAosrInKs2ExpenditureListFiles,
} from "redux/modules/common/passportsAndCertificates/types";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import AosrDocumentAdd from "../AosrDocumentAdd/AosrDocumentAdd";
import AosrMaterials from "../AosrMaterials/AosrMaterials";
import { useAosrMaterialsV2 } from "../AosrMaterials/useAosrMaterialsV2";
import { useAosrDocs } from "../useAosrDoc";
import { FormApi } from "final-form";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import { DisplayAxesAndMarks } from "widgets/AxesAndMarks";

import { MATERIAL_SOURCES } from "../constants";
import { ITicketInJournalDone } from "types/interfaces/Tickets";

import { composeFieldValidators, required } from "utils/formHelpers/validations";
import downloadFile from "utils/helpers/download";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import StatusConfirmedIcon from "images/icons/StatusConfirmedIcon";

import styles from "./AosrExistingModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  sectionId: number;
  canEdit: boolean;
  canImportAosr: boolean;
  ticket: IExpendituresInJournalFulfillment["items"][0];
}

enum NextType {
  EXPENDITURE = "expenditure",
  GROUP = "group",
}

const EMPTY_ARRAY: any[] = [];

const AosrExistingModal: React.FC<IProps> = ({ isOpen, onClose, ticket, sectionId, canEdit, canImportAosr }) => {
  const dispatch = useDispatch();
  const ticketItem = ticket?.expenditure ?? ticket?.group ?? ticket?.linked;
  const expenditureId = ticket?.expenditure?.expenditure_id;
  const act = ticketItem?.aosr;

  const fileInput = useRef<HTMLInputElement>(null);
  const objectId = useObjectId();

  const detailedAosr = useSelector(aosrCurrentDetailSelector);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dateStart, setDateStart] = useState(detailedAosr?.start_date);
  const [dateEnd, setDateEnd] = useState(detailedAosr?.end_date);
  const [aosrDocs, setAosrDocs] = useState<IAosrDoc[]>(detailedAosr?.docs!);
  const [deletedAosrDocs, setDeletedAosrDocs] = useState<IAosrDoc[] | []>([]);
  const works = useSelector(aosrWorksSelector)[sectionId];
  const invalidateKey = useSelector(aosrInvalidateKeySelector);
  const [nextType, setNextType] = useState<NextType>(NextType.EXPENDITURE);
  const [nextId, setNextId] = useState<number | undefined>(undefined);
  const initialNextRef = useRef({ id: null, type: null });

  useEffect(() => {
    setDateStart(detailedAosr?.start_date);
    setDateEnd(detailedAosr?.end_date);
  }, [detailedAosr]);

  const { materials, addMaterial, removeMaterial } = useAosrMaterialsV2(
    detailedAosr?.materials || EMPTY_ARRAY,
    detailedAosr?.materials_in_aosr,
    true
  );
  const { docs: allDocs, deleteDoc, deletedDocsIds, addDoc, startCreatingDoc } = useAosrDocs(detailedAosr?.docs as any);

  useEffect(() => {
    setIsDisabled(true);
    onClose();
  }, [invalidateKey]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getWorksBySectionInAosr(objectId, sectionId));
    dispatch(setAosrDetail(objectId, expenditureId!, act?.id!));

    return () => {
      setIsDisabled(true);
      setAosrDocs(detailedAosr?.docs!);
      setDeletedAosrDocs([]);
      dispatch(dropCurrentAosrDetail());
    };
  }, [isOpen, sectionId, objectId, invalidateKey]);

  useEffect(() => {
    if (detailedAosr && nextId === undefined) {
      let newNextType = NextType.EXPENDITURE;
      let newNextId; /* @ts-ignore */
      if (detailedAosr.next_group) {
        newNextType = NextType.GROUP; /* @ts-ignore */
        newNextId = detailedAosr.next_group.id;
      }

      if (detailedAosr.next_expenditure) {
        newNextType = NextType.EXPENDITURE; /* @ts-ignore */
        newNextId = detailedAosr.next_expenditure.id;
      }

      setNextType(newNextType);
      setNextId(newNextId); /* @ts-ignore */
      initialNextRef.current = { id: newNextId, type: newNextType };
    }
  }, [detailedAosr, nextId]);

  useEffect(() => {
    if (isDisabled || !detailedAosr?.docs) return;

    setAosrDocs(detailedAosr?.docs);
    setDeletedAosrDocs([]);
  }, [isDisabled, detailedAosr]);

  const submitHandler = useCallback(
    (values: ICreateAosr) => {
      const deleteAosrDocs = deletedDocsIds.map((id) => dispatch(deleteDocs(objectId, expenditureId!, act?.id!, id)));

      const editAosrDocs = values.docs
        .filter((item) => !!item.id && deletedDocsIds.findIndex((deletedAosr) => deletedAosr === item.id) === -1)
        ?.map((item) =>
          dispatch(
            editDocs(
              objectId,
              expenditureId!,
              act?.id!,
              {
                doc_date: item.doc_date,
                doc_type: item.doc_type,
                title: item.title,
                hiddenwork: act?.id!,
              },
              item.id
            )
          )
        );

      const createAosrDocs = values.docs
        .filter((item) => !item.id && item.title)
        ?.map((el) => {
          return el;
        })
        ?.map((item) =>
          dispatch(
            createDocs(
              objectId,
              expenditureId!,
              {
                doc_date: moment(item.doc_date).format("YYYY-MM-DD"),
                doc_type: item.doc_type,
                title: item.title,
                hiddenwork: act?.id!,
                //@ts-ignore
                listfiles: item.listfiles,
              },
              act?.id
            )
          )
        );

      const deleteAosrDocsFile: Promise<any>[] = []; /* deletedAosrDocs
        .filter((item) => item?.id)
        ?.map((item) => dispatch(deleteDocs(objectId, expenditure?.id, item.id))); */

      const promises = [...editAosrDocs, ...createAosrDocs, ...deleteAosrDocs, ...deleteAosrDocsFile];

      Promise.allSettled(promises).then(() =>
        dispatch(
          editAosr(
            objectId,
            expenditureId!,
            act?.id!,
            {
              ...values,
              end_date: moment(dateEnd).format("YYYY-MM-DD"),
              start_date: moment(dateStart).format("YYYY-MM-DD"),
              materials_in_aosr: materials.filter((el) => !el.was_deleted).map((el) => el.id) || [],
            },
            true
          )
        )
      );
    },
    [
      objectId,
      act?.id,
      expenditureId,
      dateEnd,
      dateStart,
      aosrDocs,
      deletedAosrDocs,
      aosrActions,
      detailedAosr,
      materials,
      deletedDocsIds,
    ]
  );

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const downloadHandler = useCallback(
    (document: IAosrDoc["listfiles"]) => {
      downloadFile(document[0]?.file_path, document[0]?.originalname);
    },
    [document]
  );

  const removeAosrDoc = useCallback((index: number) => {}, []);

  const isClosingPending = useSelector(aosrClosingPendingSelector)[act?.id || ""];

  const resetForm = (form: FormApi) => {
    form.reset();
    /* @ts-ignore */
    setNextType(initialNextRef.current.type); /* @ts-ignore */
    setNextId(initialNextRef.current.id);

    initialNextRef.current = { id: null, type: null };
  };

  if (!act) return null;

  return (
    <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.modal}>
      <Form
        mutators={{
          setNext: (args, state) => {
            const id = args[0];
            const name = args[1];
            const field = state.fields["next_work_title"];
            field.change(name);

            const selected = works.find((w) => w.id === args[0]);
            if (selected) {
              const newNextType = selected.expenditure_type === "work" ? NextType.EXPENDITURE : NextType.GROUP;
              setNextType(newNextType);
              setNextId(id);
            }
          },
        }}
        key={detailedAosr?.id}
        onSubmit={(values) => {
          const preparedValues = {
            ...values,
          };

          if (nextType === NextType.GROUP) {
            preparedValues.next_group_id = nextId;
            preparedValues.next_expenditure_id = null;
            preparedValues.next_expenditure = null;
          }

          if (nextType === NextType.EXPENDITURE) {
            preparedValues.next_expenditure_id = nextId;
            preparedValues.next_group_id = null;
            preparedValues.next_group = null;
          }

          delete preparedValues.next_work_id;
          /* @ts-ignore */
          submitHandler(preparedValues);
        }}
        initialValues={detailedAosr}
        render={({ handleSubmit, form }) => (
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <header className={styles.header}>
              <div className={styles.headline}>
                <h2>
                  АОСР №{detailedAosr?.number} от {moment(detailedAosr?.act_date).format("DD.MM.YYYY")}
                </h2>
                <DownloadButton
                  onDownload={() =>
                    aosrApi.download(
                      objectId,
                      //@ts-ignore
                      expenditureId,
                      act?.id,
                      `АОСР №${detailedAosr?.number} от ${moment(detailedAosr?.act_date).format("DD.MM.YYYY")}`
                    )
                  }
                />
              </div>
              <div className={styles.subtitle}>К работе:</div>
              <div className={styles.work}>{ticketItem.name}</div>
            </header>
            <div className={styles.content}>
              <Field
                name="title"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>К освидетельствованию предъявлены следующие работы</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
              <Field
                name="project_documentation"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Работы выполнены по проектной документации</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Номер, реквизиты чертежа, наименование проектной и/или рабочей документации"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
            </div>
            {isDisabled ? (
              <>
                {!!allDocs?.length && (
                  <div className={cn(styles.doc, styles.docsHead)}>
                    <div>Наименование документа</div>
                    <div>Дата</div>
                  </div>
                )}
                {allDocs?.map((el, i) => (
                  <div className={styles.doc} key={el.id}>
                    <div>{el.title}</div>
                    <div>{moment(el.doc_date).format("DD.MM.YYYY")}</div>
                    <div className={cn(styles.actionCell, styles.download)}>
                      {el.listfiles?.length > 0 && <DownloadIcon onClick={() => downloadHandler(el.listfiles)} />}
                    </div>
                    <div className={styles.actionCell}>
                      <StatusConfirmedIcon />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.docsContainer}>
                {allDocs?.map((el, i) => (
                  <AosrDocumentAdd
                    index={i}
                    key={i}
                    remove={removeAosrDoc}
                    rowClassName={styles.docsRow}
                    isEditMode
                    onDirectlyDelete={deleteDoc}
                    item={el}
                  />
                ))}
                <AddButton
                  onClick={startCreatingDoc}
                  text="Добавить"
                  textPosition="left"
                  className={styles.addButton}
                />
              </div>
            )}
            {isOpen && (
              <div className={styles.axes}>
                <DisplayAxesAndMarks
                  planOrFact="fact"
                  id={ticket?.expenditure?.facts_id?.[0]!}
                  workOrGroup={!!ticket?.group ? "group" : "work"}
                />
              </div>
            )}
            <div className={styles.content}>
              <Field
                name="start_date"
                render={() => (
                  <div className={styles.row}>
                    <h4>Срок выполнения работ</h4>
                    <CalendarRange
                      containerClassName={styles.range}
                      defaultDateStart={dateStart}
                      defaultDateEnd={dateEnd}
                      setDefaultDateEnd={setDateEnd}
                      setDefaultDateStart={setDateStart}
                      classNameSelect={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                      isTooLeft
                    />
                  </div>
                )}
              />
              <Field
                name="tech_regulations"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Работы выполнены в соответствии с</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Наименование и структурные единицы технических регламентов, разделы проектной и/или рабочей документации"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
              {!!detailedAosr?.materials?.length && (
                <AosrMaterials
                  isOpen={isOpen}
                  materials={materials}
                  source={MATERIAL_SOURCES.AOSR}
                  canEdit={!isDisabled}
                  onRemoveMaterial={removeMaterial}
                  onAddMaterial={addMaterial}
                  isViewMode={isDisabled}
                />
              )}
              <h3 className={styles.nextTitle}>Разрешается производство последующих работ</h3>
              <Field
                name="next_work_title"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Наименование работы</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Укажите наименование"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
              <Field
                name={`next_work_id`}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <label>Наименование работы по смете</label>
                    <Select
                      containerClassName={styles.select}
                      value={nextId}
                      onChange={(id, name) => {
                        form.mutators.setNext(id, name);
                        input.onChange(id);
                      }}
                      meta={meta}
                      options={works}
                      disabled={isDisabled}
                      className={cn({ [styles.disabled]: isDisabled })}
                      placeholder={form.getState().values.next_work_title}
                    />
                  </div>
                )}
              />
              <Field
                name="extra_info"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Дополнительные сведения</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Введите дополнительную информацию"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
            </div>
            <div className={styles.heightHolder} />
            <BottomControls isExists={detailedAosr?.status !== "close"} isDoubleBtns>
              {!isDisabled ? (
                <>
                  <ButtonBase
                    type="button"
                    secondary
                    onClick={() => {
                      setIsDisabled(true);
                      resetForm(form);
                    }}
                  >
                    Отменить
                  </ButtonBase>
                  <ButtonBase primary type="submit">
                    Сохранить
                  </ButtonBase>
                </>
              ) : (
                !detailedAosr?.confirmed_file_path && (
                  <>
                    {canEdit && (
                      <ButtonBase
                        secondary
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsDisabled(false);
                        }}
                      >
                        Редактировать
                      </ButtonBase>
                    )}
                    {canImportAosr && (
                      <ButtonBase
                        primary
                        type="button"
                        onClick={() => fileInput.current?.click()}
                        isLoading={isClosingPending}
                      >
                        Прикрепить АОСР
                      </ButtonBase>
                    )}
                  </>
                )
              )}
            </BottomControls>
            <input
              type="file"
              ref={fileInput}
              className={styles.hideInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(closeAosr(objectId, act?.id!, e.target.files?.[0]!));
              }}
            />
          </form>
        )}
      />
    </SliderModal>
  );
};

export default React.memo(AosrExistingModal);
