import moment from "moment";
import React, { useState } from "react";

import IndicatorsWrapper from "components/UI/_TODO/IndicatorsWrapper/IndicatorsWrapper";

import TaskIndicatorWrapper from "./TaskIndicatorWrapper";
import CircleComponents from "shared/ui/dataDisplay/CircleComponents";

import { TASK_LISTS_TYPES, taskFilterStatuses } from "../../constants";

import { useTaskAggregations } from "../../hooks/useTaskAggregations";
import { useTasksFilter } from "../../hooks/useTasksFilter";

import { isNanChecker } from "utils/formatters/isNanChecker";

import styles from "./TasksIndicators.module.scss";

interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: TASK_LISTS_TYPES;
  objectId?: string;
}

const queryFilterDeafult: Partial<Record<TASK_LISTS_TYPES, string>> = {
  [TASK_LISTS_TYPES.ALL]: "all",
  [TASK_LISTS_TYPES.MY]: "executor",
  [TASK_LISTS_TYPES.ASSIGNED]: "creator",
  [TASK_LISTS_TYPES.WATCHING]: "viewer",
};

function TasksIndicators({ isOpen, setIsOpen, objectId, type }: Iprops) {
  const indicators = useTaskAggregations(type, Number(objectId));
  const myTasksCount = indicators.executor_count || 0;
  const referenceCount = type === TASK_LISTS_TYPES.MY ? myTasksCount : indicators.all_count;

  const { filters, filtersHandler, dateRangeHandler, changeSectionHandler, setFiltersFull } = useTasksFilter();

  return (
    <IndicatorsWrapper
      containerClassName={styles.container}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      contentClassName={styles.content}
    >
      <TaskIndicatorWrapper
        onClick={() => {
          setFiltersFull((prev) => ({
            ...prev,
            query_filter: queryFilterDeafult[type] as any,
            expires_soon: false,
            status: taskFilterStatuses.NOT_COMPLETED,
          }));
        }}
        isActive={false}
      >
        <div className={styles.all}>
          <span className={styles.allCount}>{indicators.all_count} задач</span>
          Всего
        </div>
      </TaskIndicatorWrapper>
      {type === TASK_LISTS_TYPES.ALL && indicators.executor_count !== undefined && (
        <TaskIndicatorWrapper
          onClick={() => {
            if (filters.query_filter === "executor") {
              filtersHandler("query_filter", "all");
            } else {
              filtersHandler("query_filter", "executor");
            }
          }}
          isActive={filters.query_filter === "executor"}
        >
          <CircleComponents
            color="#5EC5EB"
            displayingValue={`${indicators.executor_count} из ${indicators.all_count}`}
            progress={isNanChecker(Math.round(myTasksCount / (indicators.all_count / 100)))}
            description="Мои задачи"
            isProgressStringHidden
            titleClassName={styles.circleTitle}
            descriptionClassName={styles.circleDescription}
          />
        </TaskIndicatorWrapper>
      )}
      <TaskIndicatorWrapper
        onClick={() => {
          if (filters.status === ("in_work" as any)) {
            filtersHandler("status", taskFilterStatuses.NOT_COMPLETED);
          } else {
            filtersHandler("status", "in_work");
          }
        }}
        isActive={filters.status === ("in_work" as any)}
      >
        <CircleComponents
          color="#D05EEB"
          displayingValue={indicators.in_work_count}
          progress={isNanChecker(Math.round((indicators.in_work_count || 0) / (referenceCount / 100)))}
          description="В работе"
          isProgressStringHidden
          titleClassName={styles.circleTitle}
          descriptionClassName={styles.circleDescription}
        />
      </TaskIndicatorWrapper>
      <TaskIndicatorWrapper
        onClick={() => {
          if (filters.status === ("expired" as any)) {
            filtersHandler("status", taskFilterStatuses.NOT_COMPLETED);
          } else {
            filtersHandler("status", "expired");
          }
        }}
        isActive={filters.status === ("expired" as any)}
      >
        <CircleComponents
          color="#FF0558"
          displayingValue={indicators.expired_count}
          progress={isNanChecker(Math.round((indicators.expired_count || 0) / (referenceCount / 100)))}
          description="Просрочено"
          isProgressStringHidden
          titleClassName={styles.circleTitle}
          descriptionClassName={styles.circleDescription}
        />
      </TaskIndicatorWrapper>
      <TaskIndicatorWrapper
        onClick={() => {
          if (filters.expires_soon) {
            filtersHandler("expires_soon", false);
          } else {
            filtersHandler("expires_soon", true);
          }
        }}
        isActive={!!filters.expires_soon}
      >
        <CircleComponents
          color="#FF8900"
          displayingValue={indicators.expires_count}
          progress={isNanChecker(Math.round((indicators.expires_count || 0) / (referenceCount / 100)))}
          description="Срок истекает"
          isProgressStringHidden
          titleClassName={styles.circleTitle}
          descriptionClassName={styles.circleDescription}
        />
      </TaskIndicatorWrapper>
      <TaskIndicatorWrapper
        onClick={() => {
          if (filters.status === ("closed" as any)) {
            filtersHandler("status", taskFilterStatuses.NOT_COMPLETED);
          } else {
            filtersHandler("status", "closed");
          }
        }}
        isActive={filters.status === ("closed" as any)}
      >
        <CircleComponents
          color="#FF0558"
          displayingValue={indicators.completed_count}
          progress={isNanChecker(Math.round((indicators.completed_count || 0) / (referenceCount / 100)))}
          description="Завершённые"
          isProgressStringHidden
          titleClassName={styles.circleTitle}
          descriptionClassName={styles.circleDescription}
        />
      </TaskIndicatorWrapper>
    </IndicatorsWrapper>
  );
}

export default React.memo(TasksIndicators);
