import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  deleteArrow,
  updateRelationDayDelay,
  updateRelationType,
} from "redux/modules/common/building/manufacturing/thunks";
import { chartActions } from "redux/modules/common/chart/actions";
import { IPlanRelation, RELATION_TYPES } from "redux/modules/common/chart/types";

import {
  MAX_RELATION_BREAK_VALUE,
  MIN_RELATION_BREAK_VALUE,
} from "components/pages/Manufacturing/components/modals/ManufacturingModal/IntervalRelationsContent/constants";

import ConfirmationModal from "../../../../../../entities/ConfirmationModal/ConfirmModal";

export interface IUseRelationEditing {
  relation: IPlanRelation;
  projectId: number;
}

export const useRelationEditing = ({ relation, projectId }: IUseRelationEditing) => {
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const lastSubmittedDelayDay = useRef<number | null>(null);

  const [dynamicRelationDelayDay, setDynamicRelationDelayDay] = useState(relation.delay_day || "");

  const handleRelationTypeChange = useCallback(
    (newRelationType: RELATION_TYPES) => {
      dispatch(
        updateRelationType({
          relationId: relation.id,
          relationType: newRelationType,
          projectId,
        }) // @ts-ignore
      ).then(dispatch(chartActions.updateArrow({ arrow: relation, data: { related_type: newRelationType } })));
    },
    [relation, projectId]
  );

  const handleDelayDayInputChange = useCallback((e: React.ChangeEvent) => {
    /* @ts-ignore */
    setDynamicRelationDelayDay(e.target?.value);
  }, []);

  const delayDaySubmitHandler = useCallback(
    (e?: Event | undefined) => {
      /* @ts-ignore */
      const currentDelayDayValue = e?.target ? Number(e?.target?.value) : Number(dynamicRelationDelayDay);
      if (currentDelayDayValue < MIN_RELATION_BREAK_VALUE || currentDelayDayValue > MAX_RELATION_BREAK_VALUE) {
        setDynamicRelationDelayDay("");
        return;
      }
      if (+relation.delay_day === currentDelayDayValue) return;
      dispatch(
        updateRelationDayDelay({
          relationId: relation.id,
          delayDay: currentDelayDayValue,
          projectId,
        })
        // @ts-ignore
      ).then(dispatch(chartActions.updateArrow({ arrow: relation, data: { delay_day: currentDelayDayValue } })));
      lastSubmittedDelayDay.current = currentDelayDayValue;
    },
    [relation, dynamicRelationDelayDay, projectId]
  );

  const handleDelayDayInputKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter" || e.keyCode === 13) {
        delayDaySubmitHandler(e);
      }
    },
    [delayDaySubmitHandler]
  );

  const openDeleteArrowModal = useCallback(() => setIsOpenDeleteModal(true), []);

  const closeDeleteArrowModal = useCallback(() => setIsOpenDeleteModal(false), []);

  const handleDeleteRelation = useCallback(() => {
    if (!relation.id || !projectId) return;

    dispatch(deleteArrow({ projectId, arrowId: relation.id }))
      //@ts-ignore
      .then(dispatch(chartActions.deleteArrow({ arrow: relation })));
    closeDeleteArrowModal();
  }, [relation, projectId, closeDeleteArrowModal]);

  const deleteArrowConfirmModal = useMemo(
    () =>
      isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen
          onClose={closeDeleteArrowModal}
          variant="secondary"
          acceptButtonText="Подтвердить"
          cancelButtonText="Отменить"
          action={handleDeleteRelation}
        >
          <span>Вы действительно хотите удалить связь?</span>
        </ConfirmationModal>
      ) : null,
    [isOpenDeleteModal, closeDeleteArrowModal, handleDeleteRelation]
  );

  return {
    deleteArrowConfirmModal,
    openDeleteArrowModal,
    handleDelayDayInputKeyDown,
    handleDelayDayInputChange,
    delayDaySubmitHandler,
    dynamicRelationDelayDay,
    handleRelationTypeChange,
    isOpenDeleteModal,
    isSubmited: lastSubmittedDelayDay.current == dynamicRelationDelayDay,
  };
};
