import axios from "axios";

import { ILearningItem } from "./types";
import { IListResponseData } from "types/ListResponse";

export const learningApi = {
  get: () => {
    return axios.get<IListResponseData<ILearningItem>>("/education/user_tasks/custom/", { params: { limit: 500 } });
  },
  getInstructionsRedirects: (id: number) => {
    return axios.get(`/permissions_v2/user/${id}/urlpatterns`);
  },
};
