import { IMultipleApprovingItem } from "./model/types";

export const multiplePlansUtils = {
  prepareDataForSend: (plans: IMultipleApprovingItem[]) => {
    const works = plans.filter((el) => el.plan_type === "work").map((el) => el.plan_id);
    const groups = plans.filter((el) => el.plan_type === "group").map((el) => el.plan_id);

    return {
      works,
      groups,
    } as const;
  },
  filterNumber: (number: string) => {
    return String(number)
      .split("")
      .filter((char) => char !== ".")
      .join("");
  },
};
