import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { multiplePlanApprovingsInvalidateKeySelector, multiplePlanApprovingsSelector } from "../model/selectors";
import { getMultipleApprovingPlans, sendMultiplePlansToAprove } from "../model/thunks";
import SendMultiplePlansSection from "./SendMultiplePlansSection";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TableReusableHead from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox, { LabelPlacement } from "shared/ui/inputs/Checkbox/Checkbox";
import Modal from "shared/ui/modal/Modal";

import { IMultipleApprovingItem } from "../model/types";

import { multiplePlansUtils } from "../utils";

import styles from "./SendMultiplePlansForApproveModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const SendMultiplePlansForApproveModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();
  const [checkedItems, setCheckedItems] = useState<IMultipleApprovingItem[]>([]);

  const sections = useSelector(multiplePlanApprovingsSelector)[buildingId];
  const invalidateKey = useSelector(multiplePlanApprovingsInvalidateKeySelector);

  useEffect(() => {
    dispatch(getMultipleApprovingPlans(buildingId));
  }, [buildingId, invalidateKey, isOpen]);

  useEffect(() => {
    onClose();
  }, [invalidateKey]);

  useEffect(() => {
    if (!isOpen) {
      setCheckedItems([]);
    }
  }, [isOpen]);

  const allInnerItems = useMemo(() => {
    if (!sections) return [];
    return sections.sections.reduce<IMultipleApprovingItem[]>((acc, cur) => {
      return [
        ...acc,
        ...cur.subsections.reduce<IMultipleApprovingItem[]>((acc1, cur1) => {
          return [...acc1, ...cur1.items];
        }, []),
      ];
    }, []);
  }, [sections]);

  const onCheckItem = (item: IMultipleApprovingItem) => {
    setCheckedItems((prev) => {
      if (prev.some((el) => +el.plan_id === +item.plan_id)) {
        return prev.filter((el) => +el.plan_id !== +item.plan_id);
      } else {
        return [...prev, item];
      }
    });
  };

  const onCheckAllItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedItems(allInnerItems);
    } else {
      setCheckedItems([]);
    }
  };

  const checkedItemIds = useMemo(() => {
    return checkedItems.reduce<Record<string, boolean>>((acc, cur) => {
      return {
        ...acc,
        [cur.plan_id]: true,
      };
    }, {});
  }, [checkedItems]);

  const onSubmit = () => {
    if (!checkedItems.length) return;
    dispatch(sendMultiplePlansToAprove(buildingId, multiplePlansUtils.prepareDataForSend(checkedItems)));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Отправка на согласование"
      className={styles.modal}
      headerClassName={styles.header}
    >
      <div className={styles.subHeader}>
        (Планов: {allInnerItems.length}){!!checkedItems?.length && <span>Выбрано: {checkedItems.length}</span>}
      </div>
      <TableReusableHead className={styles.thead} isSmall>
        <TableReusableCell>
          <Checkbox
            checked={allInnerItems.length === checkedItems.length}
            onCheck={onCheckAllItems}
            labelPlacement={LabelPlacement.RIGHT}
          />
        </TableReusableCell>
        <TableReusableCell>№</TableReusableCell>
        <TableReusableCell>Наименование</TableReusableCell>
        <TableReusableCell isCentered>Кол-во (план)</TableReusableCell>
        <TableReusableCell isCentered>Период выполнения</TableReusableCell>
      </TableReusableHead>
      <div className={styles.table}>
        {sections?.sections?.map((el) => (
          <SendMultiplePlansSection section={el} key={el.id} checkedIdsDict={checkedItemIds} onCheck={onCheckItem} />
        ))}
      </div>
      <div className={styles.btns}>
        <ButtonBase secondary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary disabled={!checkedItems.length} onClick={onSubmit}>
          Отправить
        </ButtonBase>
      </div>
    </Modal>
  );
};

export default SendMultiplePlansForApproveModal;
