import React, { useEffect, useMemo, useState } from "react";

import { getPlanMims } from "redux/modules/common/building/processApi";

import { ISerializedMimsInPlan } from "types/interfaces/Mims";

import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IUseMimsPlanList {
  objectId: number;
  expId: number;
  presettedPercentage?: number;
  defaultMimsList?: ISerializedMimsInPlan[];
  isWorkGroup?: boolean;
}

export const useMimsPlanList = ({
  objectId,
  expId,
  presettedPercentage,
  defaultMimsList,
  isWorkGroup,
}: IUseMimsPlanList) => {
  const [allMims, setAllMims] = useState<ISerializedMimsInPlan[]>(defaultMimsList || []);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(defaultMimsList?.length || 0);

  const [filters, setFilters] = useState<Record<string, string> | null>(null);

  React.useEffect(() => {
    if (!defaultMimsList) return;
    setAllMims(defaultMimsList);
    setCount(defaultMimsList?.length);
  }, [defaultMimsList]);

  useEffect(() => {
    if (isWorkGroup) return;
    setIsLoading(true);
    getPlanMims(objectId, expId, filters)
      .then((resp) => {
        setAllMims(resp.data.results);
        setCount(resp.data.count);
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  }, [objectId, expId, filters, isWorkGroup]);

  const filterHandler = (value: string, fieldName: string) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const loadMoreHandler = () => {
    if (isWorkGroup) return;
    getPlanMims(objectId, expId, { ...filters, offset: String(allMims.length) }).then((resp) =>
      setAllMims((prev) => [...prev, ...resp.data.results])
    );
  };

  const presettedPlanMims = useMemo(() => {
    if (!presettedPercentage) return [];
    return allMims.map((el) => ({
      ...el,
      local_count: (Number(el.count) * (presettedPercentage || 1)).toFixed(4),
    }));
  }, [allMims, presettedPercentage]);

  return {
    allMims,
    isLoading,
    count,
    filterHandler,
    loadMoreHandler,
    presettedPlanMims,
  };
};
