import { message } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { SET_USER_LOGOUT } from "./redux/modules/_TODO/auth";
import "./scss/main.scss";

import { OslaLoader } from "./components/pages/OslaLoader/OslaLoader";

import Root from "./app/Root";
import store from "./app/store/store";
import * as Sentry from "@sentry/browser";
import AntdProvider from "app/providers/AntdProvider";
import "moment/locale/ru";
import ReactDOM from "react-dom";
import "shared/config/i18n/i18n";

import { localStorageLogoutCleanUp } from "./utils/helpers/localStorageLogoutCleanUp";

Sentry.init({
  dsn: "https://7826aa91ad18493f8d9c447a52cdf28e@sentry.ddosla.ru/4",
  environment: process.env.NODE_ENV,
});
/* @ts-ignore */
axios.defaults.baseURL = window.externalConfig._backendURL; // do not use this process.env.REACT_APP_API;

const requestInterceptor = (request: AxiosRequestConfig<any>) => {
  if (!request.headers) {
    request.headers = {};
  }

  request.headers["Cache-Control"] = "no-cache";

  const storeToken = localStorage.getItem("authToken");
  if (storeToken) {
    request.headers.Authorization = `Token ${storeToken}`;
  }

  return request;
};

axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && process.env.REACT_APP_MODE !== "test") {
      store.dispatch({ type: SET_USER_LOGOUT });
      window.location.href = "/auth";
      localStorageLogoutCleanUp();
      return;
    }

    if (error.response && error.response.status === 500) {
      message.error("Ошибка сервера");
    }

    return Promise.reject(error);
  }
);

if (process.env.REACT_APP_MODE === "test") {
  const { worker } = require("./utils/testUtils/msw/browser");
  worker.start();
}

ReactDOM.render(
  <Suspense fallback={<OslaLoader />}>
    <Provider store={store}>
      <BrowserRouter>
        <AntdProvider>
          <Root />
        </AntdProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
