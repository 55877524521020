import cn from "classnames";
import moment from "moment";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { weekDayNames } from "../../constants";

import { prepareDaysForPlanFactVolumeHeader } from "../../PlanfactVolumeExpenditure/utils";

import styles from "./PlanFactVolumeTableHead.module.scss";

interface IProps {
  daysCount: number;
  startAt: string;
}

const PlanFactVolumeTableHead: React.FC<IProps> = ({ daysCount, startAt }) => {
  const rowClassName = cn(styles.row, { [styles.row5days]: daysCount === 5 });

  const days = React.useMemo(() => {
    return prepareDaysForPlanFactVolumeHeader(startAt, daysCount);
  }, [startAt, daysCount]);

  return (
    <TableReusableHead className={rowClassName}>
      <TableReusableHeaderCell>№</TableReusableHeaderCell>
      <TableReusableHeaderCell>Наименование расценки</TableReusableHeaderCell>
      <div />
      <TableReusableHeaderCell isCentered>Ед.изм</TableReusableHeaderCell>
      <TableReusableHeaderCell className={styles.date}>
        <div className={styles.title}>На неделю</div>
        <div className={styles.total}>План</div>
      </TableReusableHeaderCell>
      {days.map((el, i) => (
        <TableReusableHeaderCell className={styles.date} key={el}>
          <div className={styles.title}>
            {weekDayNames[i]}&nbsp;
            <span className={styles.dateNum}>{moment(el).format("D")}</span>
          </div>
          <div className={cn(styles.total, styles.blue)}>План / Факт</div>
        </TableReusableHeaderCell>
      ))}
      <TableReusableHeaderCell className={styles.date}>
        <div className={styles.title}>За неделю</div>
        <div className={styles.total}>Факт</div>
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default PlanFactVolumeTableHead;
