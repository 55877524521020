import React from "react";

import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import Calendar from "./components/Calendar/Calendar";
import FileStorageButton from "./components/FileStorageButton/FileStorageButton";
import Menu from "./components/Menu/Menu";
import NewTaskButton from "./components/NewTaskButton/NewTaskButton";
import Notification from "./components/Notification/Notification";
import Profile from "./components/Profile/Profile";

import Help from "widgets/Help/Help";
import Instructions from "widgets/Help/Instructions/Instructions";
import Learning from "widgets/Help/Learning/Learning";

import { VIEW_FILE_STORAGE } from "constants/permissions/generalPermissions";

import usePermission from "hooks/usePermission";

import styles from "./Header.module.scss";

const Header = () => {
  const haveViewFileStoragePermission = usePermission(VIEW_FILE_STORAGE);

  return (
    <div className={styles.container}>
      <Menu />
      <Breadcrumbs />
      <div className={styles.rightMenu}>
        <Help />
        {haveViewFileStoragePermission && <FileStorageButton />}
        <NewTaskButton />
        <Calendar />
        <Notification />
        <Profile />
      </div>
    </div>
  );
};

export default Header;
