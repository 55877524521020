import cn from "classnames";
import update from "immutability-helper";
import { memoize, partial } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { compose } from "redux";

import OrderRequest from "./components/OrderRequest/OrderRequest";

import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import RequestOffers from "../RequestOffers/RequestOffers";
import Fork from "./domain/Fork";
import Serializer from "./domain/Serializer";

import useArrayItemsChecker from "../../../../hooks/useArrayItemsChecker";

import { generateRandomId } from "../../../../utils/helpers/generateRandomId";
import { getIdentifier } from "../../../../utils/helpers/getIdentifier";
import { stringifyArgs } from "../../../../utils/helpers/stringifyArgs";
import { checkOffersMeasuresHaveDiffs } from "./utils/checkOffersMeasuresHaveDiffs";
import { compareOffersAndSetCounts } from "./utils/compareOffersAndSetCounts";
import { getRequestsMeasures } from "./utils/getRequestsMeasures";

import GroupByIcon from "../../../../images/icons/GroupByIcon";

import styles from "./index.module.scss";

const OrderRequests = ({
  className,
  requests,
  kits,
  buildingId,
  updateOrder,
  permissions,
  setRequests,
  setKits,
  onSuccess,
}) => {
  const {
    items: checkerItems,
    checkedCount: checkedRequestsCount,
    checkOnce: checkRequest,
    reset: resetCheckerItems,
  } = useArrayItemsChecker(requests, "id", "temporaryId");

  const serializeAndSetRequests = useCallback(
    (requests) => compose(setRequests, Serializer.serializeRequests)(requests),
    [setRequests]
  );

  const serializeAndSetKits = useCallback((kits) => compose(setKits, Serializer.serializeKits)(kits), [setKits]);

  const deleteRequestOrKit = (requestsOrKits, setRequestsOrKits) => (deletedRequestId) => {
    setRequestsOrKits(requestsOrKits.filter((request) => getIdentifier(request) !== deletedRequestId));
  };

  const forkRequest = (blankRequest, targetRequest) => {
    const { targetRequest: forkedTargetRequest, newRequest } = Fork.forkRequest(blankRequest, targetRequest);

    const targetRequestIndex = requests.indexOf(targetRequest);

    serializeAndSetRequests(
      update(requests, {
        [targetRequestIndex]: { $set: forkedTargetRequest },
        $push: [newRequest],
      })
    );
  };

  const forkKit = (targetKit) => (blankRequest, targetKitRequest) => {
    const { targetKit: forkedTargetKit, newRequest } = Fork.forkKit(blankRequest, {
      kit: targetKit,
      request: targetKitRequest,
    });

    serializeAndSetKits(kits.map((kit) => (getIdentifier(kit) === getIdentifier(targetKit) ? forkedTargetKit : kit)));
    serializeAndSetRequests([...requests, newRequest]);
  };

  const setKitOffersById = useMemo(
    () =>
      memoize(
        (offersKitId) => (offers) =>
          serializeAndSetKits(
            kits.map((kit) =>
              getIdentifier(kit) === offersKitId
                ? {
                    ...kit,
                    offers: compareOffersAndSetCounts(kit.requests, kit.offers, offers),
                  }
                : kit
            )
          ),
        stringifyArgs
      ),
    [kits, serializeAndSetKits]
  );

  const setRequestOffersById = useMemo(
    () =>
      memoize(
        (requestId) => (offers) =>
          serializeAndSetRequests(
            requests.map((request) =>
              getIdentifier(request) === requestId
                ? {
                    ...request,
                    offers: offers,
                  }
                : request
            )
          ),
        stringifyArgs
      ),
    [serializeAndSetRequests, requests]
  );

  const createRequestOffer = (request) => {
    const requestId = getIdentifier(request);
    const newOffer = {
      measure: request.measure,
      supplies: [{ count: "", date: "" }],
      temporaryId: generateRandomId(),
    };

    setRequestOffersById(requestId)([...request.offers, newOffer]);
  };

  const createKitOffer = (kit) => {
    const kitId = getIdentifier(kit);
    const newOffer = {
      temporaryId: generateRandomId(),
      measure: "компл",
      is_kit: true,
      supplies: [{ temporaryId: generateRandomId(), count: "", date: "" }],
      components: [],
    };

    kit.requests.forEach((request) =>
      newOffer.components.push({
        temporaryId: generateRandomId(),
        count: "",
        measure: request.measure,
        name: request.name,
        request_id: request.id,
      })
    );

    setKitOffersById(kitId)([...kit.offers, newOffer]);
  };

  const createOfferSupply = (setOffersById, targetRequest, targetOffer) => {
    const targetRequestId = getIdentifier(targetRequest);
    const updatedOffer = {
      ...targetOffer,
      supplies: update(targetOffer.supplies, {
        $push: [
          {
            temporaryId: generateRandomId(),
            count: "",
            date: "",
            measure: targetOffer.measure,
            price: targetOffer.price,
          },
        ],
      }),
    };

    setOffersById(targetRequestId)(
      targetRequest.offers.map((offer) => (getIdentifier(offer) === getIdentifier(targetOffer) ? updatedOffer : offer))
    );
  };

  const partialCreateRequestOffer = (request) => () => createRequestOffer(request);
  const partialCreateKitOffer = (kit) => () => createKitOffer(kit);
  const partialCreateOfferSupply = (setOffers, targetRequest) => partial(createOfferSupply, setOffers, targetRequest);

  const handleCheckRequest = useMemo(
    () => memoize((requestId) => (event) => checkRequest(requestId, event.target.checked), stringifyArgs),
    [checkRequest]
  );

  const createKit = useCallback(() => {
    serializeAndSetRequests(requests.filter((request) => !checkerItems[getIdentifier(request)]));
    const checkedRequests = requests.filter((request) => checkerItems[getIdentifier(request)]);

    serializeAndSetKits([
      ...kits,
      {
        temporaryId: generateRandomId(),
        is_union: false,
        requests: checkedRequests,
        offers: [
          {
            is_kit: true,
            temporaryId: generateRandomId(),
            components: checkedRequests.map((request) => ({
              temporaryId: generateRandomId(),
              name: request.name,
              measure: request.measure,
              request_id: getIdentifier(request),
              count: "1.00",
            })),
            supplies: [{ temporaryId: generateRandomId(), count: checkedRequests.length }],
            measure: "компл",
          },
        ],
      },
    ]);

    resetCheckerItems();
  }, [serializeAndSetRequests, resetCheckerItems, serializeAndSetKits, kits, requests, checkerItems]);

  const removeFromKit = (targetKit, targetRequest) => {
    const targetKitIndex = kits.indexOf(targetKit);

    if (targetKit.requests.length <= 2) {
      serializeAndSetKits(update(kits, { $splice: [[targetKitIndex, 1]] }));
      serializeAndSetRequests(
        update(requests, {
          $push: targetKit.requests.map((request) => ({
            ...request,
            offers: [{ temporaryId: generateRandomId(), supplies: [{ temporaryId: generateRandomId() }] }],
          })),
        })
      );
      return;
    }

    const targetRequestIndex = targetKit.requests.indexOf(targetRequest);
    const newRequest = { ...targetRequest };
    newRequest.offers = [{ temporaryId: generateRandomId(), supplies: [{ temporaryId: generateRandomId() }] }];

    serializeAndSetKits(
      update(kits, {
        [targetKitIndex]: {
          requests: {
            $splice: [[targetRequestIndex, 1]],
          },
        },
      })
    );

    serializeAndSetRequests([...requests, newRequest]);
  };

  const handleRemoveFromKit = (targetKit, targetRequest) => () => removeFromKit(targetKit, targetRequest);

  const requestOffersMeasuresHaveDiffs = useMemo(
    () => requests.some((request) => checkOffersMeasuresHaveDiffs(request.offers, request.measure)),
    [requests]
  );

  return (
    <div>
      {permissions.editRequest && (
        <ButtonBase
          className={styles.createKitButton}
          disabled={checkedRequestsCount <= 1}
          onClick={createKit}
          primary
          medium
        >
          <GroupByIcon color="#fff" />
          Объединить: {checkedRequestsCount}
        </ButtonBase>
      )}
      <div
        className={cn(styles.orderRequests, className, {
          [styles.withDifferedMeasures]: requestOffersMeasuresHaveDiffs,
        })}
      >
        <header className={styles.header}>
          <div className={styles.columns}>
            <span className={styles.numberColumn}>№</span>
            {permissions.editRequest && <span className={styles.checkboxColumn} />}
          </div>
          <div className={styles.columns}>
            <span className={styles.nameColumn}>Запрос</span>
            <span className={styles.dateColumn}>Поставка</span>
            <span className={styles.countColumn}>Кол-во</span>
            <span className={styles.measureColumn}>Ед.изм.</span>
            <span className={styles.countColumn} />
            <span className={styles.actionsColumn} />
          </div>
        </header>
        {requests.map((request, index) => (
          <React.Fragment key={getIdentifier(request)}>
            <OrderRequest
              request={request}
              isChecked={checkerItems[getIdentifier(request)]}
              checkRequest={handleCheckRequest(getIdentifier(request))}
              number={index + 1}
              updateOrder={updateOrder}
              buildingId={buildingId}
              deleteRequest={deleteRequestOrKit(requests, serializeAndSetRequests)}
              forkRequest={forkRequest}
              permissions={permissions}
              onSuccess={onSuccess}
            />
            {(request.offers.length !== 0 || permissions.editRequest) && (
              <RequestOffers
                offers={request.offers}
                setOffers={compose(setRequestOffersById, getIdentifier)(request)}
                createOffer={partialCreateRequestOffer(request)}
                requestMeasure={request.measure}
                createOfferSupply={partialCreateOfferSupply(setRequestOffersById, request)}
                permissions={permissions}
                showCountRequestMeasure={checkOffersMeasuresHaveDiffs(request.offers, request.measure)}
                onSuccess={onSuccess}
              />
            )}
          </React.Fragment>
        ))}
        {kits.map((kit) => (
          <React.Fragment key={getIdentifier(kit)}>
            {kit.requests.map((kitRequest, index) => (
              <OrderRequest
                request={kitRequest}
                number={requests.length + index + 1}
                updateOrder={updateOrder}
                buildingId={buildingId}
                forkRequest={forkKit(kit)}
                removeFromKit={handleRemoveFromKit(kit, kitRequest)}
                permissions={permissions}
                key={getIdentifier(kitRequest)}
                isKit
              />
            ))}
            {(kit.offers.length !== 0 || permissions.editRequest) && (
              <RequestOffers
                offers={kit.offers}
                setOffers={compose(setKitOffersById, getIdentifier)(kit)}
                kitRequestsMeasures={getRequestsMeasures(kit.requests)}
                createOffer={partialCreateKitOffer(kit)}
                createOfferSupply={partialCreateOfferSupply(setKitOffersById, kit)}
                permissions={permissions}
                isKit
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default React.memo(OrderRequests);
