import cn from "classnames";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import { ITaskComment } from "components/pages/Tasks/model/types";

import TaskOneComment from "./TaskOneComment";
import { useTypedSelector } from "app/store/typedUseSelector";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import InputBase from "shared/ui/inputs/InputBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useTaskComments } from "../../hooks/useTaskComments";

import MessageIcon from "images/icons/MessageIcon";

import styles from "./TaskComments.module.scss";

export interface ITaskComments extends ReturnType<typeof useTaskComments> {
  comments: ITaskComment[];
  testId?: string;
  className?: string;
  isDisplayEmptyPlaceholder?: boolean;
}

function TaskComments({
  comments,
  createNewTaskCommentHandler,
  writeTaskCommentHandler,
  commentText,
  textInputRef,
  testId,
  className,
  isDisplayEmptyPlaceholder = true,
}: ITaskComments) {
  const pendings = useTypedSelector((state) => state.tasksV2Reducer.pendings);
  if (!comments) return null;

  return (
    <div className={cn(styles.comments, className)} data-testid={testId}>
      <span className={styles.title}>Комментарии к задаче: {comments.length}</span>
      <InputBase
        value={commentText}
        onChange={writeTaskCommentHandler as any}
        placeholder="Введите текст комментария"
        //@ts-ignore
        ref={textInputRef as any}
        isWithButton
        isButtonActive={!!commentText}
        onButtonClick={createNewTaskCommentHandler as any}
        onEnter={createNewTaskCommentHandler as any}
      />
      <div className={styles.list}>
        {comments.map((el, i) => (
          <TaskOneComment key={el.id} {...el} isPending={pendings[el.id] || el.id < 1} />
        ))}
      </div>
    </div>
  );
}

export default React.memo(TaskComments);
