import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  ADD_SELECTED_PRODUCTS,
  ADD_SELECTED_SUBSECTIONS,
  DELETE_SELECTED_PRODUCTS,
  DELETE_SELECTED_SUBSECTIONS,
  loadIntervals,
  productsOverviewSelector,
  selectedProductsSelector,
  selectedSubsectionsSelector,
} from "redux/modules/common/building/process";
import { projectInvalidateKeySelector } from "redux/modules/common/building/project/selectors";
import {
  expendituresSubMaterialsSelector,
  getExpendituresBySection,
  resetExpendituresSubMaterialsAction,
} from "redux/modules/common/building/sections/sections";
import { getExpendituresGroups } from "redux/modules/common/building/shedules";

import { TabsEnum } from "components/pages/Building/enums";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import SubSection from "./SubSection";
import { IPlanSection, useSectionPlan } from "./useSectionPlan";
import { deletePlanInvalidateKeySelector } from "widgets/DeletePlan/model/selectors";

import { IExpenditureTypesFiltersInBuilding } from "../../types";
import ISection from "types/interfaces/Section";

export interface ISubSectionContainerProps {
  objectId: number;
  section: ISection;
  editable: boolean;
  activeTab: TabsEnum;
  date_start: string;
  date_end: string;
  isShared: boolean;
  isIndicatorsOpen: boolean;
  canSharing: boolean;
  permissions: {
    canAddFiles: boolean;
    canDeleteFiles: boolean;
    canAddPlan: boolean;
    canAddProgress: boolean;
  };
  filters: IExpenditureTypesFiltersInBuilding;
  canAddPlan?: boolean;
  dataTestId?: string | number;
  lsrPlanData?: IPlanSection;
  isDefaultOpened?: boolean;
}

const SubSectionContainer = ({
  objectId,
  section,
  date_start,
  date_end,
  activeTab,
  editable,
  filters,
  canAddPlan,
  dataTestId,
  lsrPlanData,
  isDefaultOpened = false,
  ...props
}: ISubSectionContainerProps) => {
  const dispatch = useDispatch();
  const { measure } = useFinanceMeasure();
  const selectedProducts = useSelector(selectedProductsSelector);
  const intervals = useSelector((state: any) => state.process.intervals);
  const productsOverview = useSelector(productsOverviewSelector);
  const selectedSubsections = useSelector(selectedSubsectionsSelector);
  const projectInvalidateKey = useSelector(projectInvalidateKeySelector);
  const expendituresSubMaterials = useSelector(expendituresSubMaterialsSelector);
  const deletePlanInvalidateKey = useSelector(deletePlanInvalidateKeySelector);

  const onCheck = useCallback(
    (isChecked: boolean, itemId: number, expenditure: any | undefined) => {
      if (itemId !== section.id) {
        const payload = {
          id: itemId,
          sectionId: section.id,
        };

        if (expenditure !== undefined) {
          /* @ts-ignore */
          payload.expenditure = expenditure;
        }

        dispatch({
          type: isChecked ? ADD_SELECTED_PRODUCTS : DELETE_SELECTED_PRODUCTS,
          payload,
        });
      } else {
        dispatch({
          type: isChecked ? ADD_SELECTED_SUBSECTIONS : DELETE_SELECTED_SUBSECTIONS,
          payload: itemId,
        });
      }
    },
    [section.id]
  );

  const loadSectionExpenditures = useCallback(() => {
    if (!section) return;
    dispatch(
      getExpendituresBySection({
        building: objectId,
        section: section.id,
        estimateState: ESTIMATE_STATES_IDS.PRODUCTION,
      })
    );
  }, [objectId, section?.id]);

  const loadSectionGroups = useCallback(() => {
    if (!section) return;
    dispatch(
      getExpendituresGroups({
        buildingId: String(objectId),
        sectionId: section.id,
        dateEnd: moment(date_end).format("YYYY-MM-DD"),
        dateStart: moment(date_start).format("YYYY-MM-DD"),
      })
    );
  }, [objectId, section?.id, date_start, date_end]);

  useEffect(() => {
    return () => {
      compose(dispatch, resetExpendituresSubMaterialsAction)();
    };
  }, []);

  useEffect(() => {
    loadSectionGroups();
    loadSectionExpenditures();
    dispatch(loadIntervals(objectId, section.id, activeTab, date_start, date_end));
  }, [objectId, section.id, activeTab, date_start, date_end, dispatch, projectInvalidateKey, deletePlanInvalidateKey]);

  useEffect(() => {
    dispatch(loadIntervals(objectId, section.id, activeTab, date_start, date_end, undefined, true));
  }, [deletePlanInvalidateKey]);

  const { planData, refetchSectionPlan } = useSectionPlan(section.id, activeTab === TabsEnum.plan);

  if (!section) return null;

  return (
    <SubSection
      filters={filters}
      objectId={objectId}
      section={section}
      measure={measure}
      activeTab={activeTab}
      loadProduct={() => dispatch(loadIntervals(objectId, section.id, activeTab, date_start, date_end))}
      onCheck={onCheck}
      checkedExpenditures={selectedProducts}
      checkedSections={selectedSubsections}
      intervals={intervals}
      expendituresOverview={productsOverview} /* @ts-ignore */
      sectionPlanData={planData}
      isShownPlanData={activeTab === TabsEnum.plan}
      onReloadSubSections={refetchSectionPlan}
      canAddPlan={canAddPlan}
      loadSectionExpenditures={loadSectionExpenditures}
      loadSectionGroups={loadSectionGroups}
      parentId={section.parent_id}
      lsrPlanData={lsrPlanData}
      dateStart={date_start}
      dateEnd={date_end}
      isDefaultOpened={isDefaultOpened}
      {...props}
    />
  );
};

export default React.memo(SubSectionContainer);
