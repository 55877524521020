import cn from "classnames";
import React, { ChangeEvent, useState } from "react";

import { IJournalAcceptanceTicketItem } from "redux/modules/common/building/journal/acceptance/types";

import InputBase, { VALUE_TYPES } from "../../../../../../../../../shared/ui/inputs/InputBase";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import blackApproveIcon from "../../../../../../../../../images/icons/blackCheckIcon";
import fillBlueApproveIcon from "../../../../../../../../../images/icons/filledBlueCheckIcon";

import styles from "./JournalAprovingCountCell.module.scss";

interface IProps {
  haveApproveTicketPermission: boolean;
  ticket: IJournalAcceptanceTicketItem;
  objectId: string;
  isGroup?: boolean;
  onAccept: (count: string) => void;
  isShared: boolean;
}

const JournalAcceptCountCell: React.FC<IProps> = ({
  haveApproveTicketPermission,
  ticket,
  objectId,
  isGroup,
  onAccept,
  isShared,
}) => {
  const [count, setCount] = useState("");
  const ticketItem = ticket?.expenditure ?? ticket?.group;

  const sum = ticketItem?.confirmed_count;

  const hasAllApproves = +ticketItem?.confirmed_approvals === +ticketItem?.total_approvals;
  const canApprove = haveApproveTicketPermission && hasAllApproves;

  const onCountChange = (e: ChangeEvent<HTMLInputElement>) => setCount(e.target.value);

  if (ticketItem?.is_confirmed) {
    return (
      <div title={sum} className={styles.container}>
        {sum}
        <span>{fillBlueApproveIcon}</span>
      </div>
    );
  }

  if (isShared) {
    return (
      <span
        className={cn(styles.approveButton, styles.sharedApprovedButton)}
        onClick={(e) => {
          stopEventPropagation(e);
          /* canApprove &&  */ onAccept(count);
        }}
      >
        {blackApproveIcon}
      </span>
    );
  }

  if (!ticketItem?.is_confirmed)
    return (
      <div onClick={stopEventPropagation} className={styles.container}>
        {!isShared && (
          <InputBase
            className={cn(styles.inputWrapper)}
            classNameInput={cn(styles.input, {
              [styles.active]: count,
            })}
            onChange={onCountChange}
            value={count}
            disabled={!canApprove}
            valueType={VALUE_TYPES.NUMBER}
          />
        )}
        <span
          className={styles.approveButton}
          onClick={() => {
            canApprove && onAccept(count);
          }}
        >
          {blackApproveIcon}
        </span>
      </div>
    );
  return null;
};

export default React.memo(JournalAcceptCountCell);
