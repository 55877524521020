import { message } from "antd";
import cn from "classnames";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useOnClickOutside from "../../../../../../../../hooks/useOnClickOutside";

import ArrowDownSelect from "../../../../../../../../images/icons/ArrowDownSelect";
import ksIcon from "../../../../../../../../images/icons/ks.svg";
import xlsIcon from "../../../../../../../../images/icons/xls.svg";

import styles from "./Ks.module.scss";

export const KS_TYPES = {
  KS2: "ks2",
  KS3: "ks3",
};

function Ks({ className, count, expenditureId, type, ks }) {
  const history = useHistory();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const outSideRef = useRef();

  const clickHandler = () => {
    if (!count || !expenditureId) {
      message.error("КС еще не сформирована");
      return;
    }

    setIsOpen((prev) => !prev);
  };

  const getTextByType = () => {
    if (type === KS_TYPES.KS2) return "КС-2";
    if (type === KS_TYPES.KS3) return "КС-3";
  };

  const handleOpenKs = useCallback((id) => {
    history.push(`${location.pathname}?${type}Id=${id}`);
  }, []);

  useOnClickOutside(outSideRef, () => setIsOpen(false));

  return (
    <div className={cn(styles.wrapper, className, {[styles.filesOpen]: isOpen})} ref={outSideRef}>
      <div className={cn(styles.container, { [styles.disabled]: !count })} onClick={clickHandler}>
        <span>{getTextByType()}</span>
        <div className={styles.icon}>
          <img src={ksIcon} alt="Иконка КС" />
          {count ? <div className={styles.counter}>{count}</div> : null}
        </div>
        {count ? <ArrowDownSelect className={styles.arrow} color="#707070" /> : null}
      </div>
      {isOpen && (
        <div className={styles.popup}>
          <header className={styles.header}>
            <span>№</span>
            <span>Отчетный период</span>
          </header>
          {ks.map((ks) => (
            <div key={ks.id} className={styles.ks} onClick={() => handleOpenKs(ks.id)}>
              <span className={styles.ksTitle}>{ks.title}</span>
              <span>
                {moment(ks.start_at).format("DD.MM.YYYY")}-{moment(ks.end_at).format("DD.MM.YYYY")}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(Ks);
