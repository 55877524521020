import cn from "classnames";
import React, { FC } from "react";

import styles from "./TabBar.module.scss";

export interface ITab {
  id: string;
  text: string;
  className?: string;
  isHidden?: boolean;
  isDesktopHidden?: boolean;
  disabled?: boolean;
}

export interface ITabBarNotLinks {
  tabs: ITab[];
  className?: string;
  disabled?: boolean;
  activeId: ITab["id"];
  onClick: (tabId: ITab["id"]) => void;
  isTested?: boolean;
  isOnlyTabsView?: boolean;
  containerClassName?: string;
  variant?: "default" | "light";
}

const TabBarNotLinks: FC<ITabBarNotLinks> = ({
  tabs,
  className,
  disabled,
  activeId,
  onClick,
  isTested,
  isOnlyTabsView,
  containerClassName,
  variant = "default",
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const filteredTabs = React.useMemo(() => {
    if (!Array.isArray(tabs)) return [];
    return tabs.filter((el) => !el.isHidden);
  }, [tabs]);
  const isSingle = filteredTabs?.length === 1;

  const isTabsHidden = tabs.every((el) => el.id !== activeId);

  if (isTabsHidden) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.container,
        { [styles.default]: variant === "default", [styles.light]: variant === "light" },
        containerClassName,
        { [styles.select]: !isOnlyTabsView && variant !== "light" }
      )}
    >
      <div
        className={cn(className, styles.tabBar, {
          [styles.disabled]: disabled,
          [styles.open]: isOpen && !isSingle,
          [styles.single]: isSingle,
        })}
        {...rest}
      >
        {filteredTabs.map((el) => (
          <div
            className={cn(styles.tab, el.className, {
              [styles.active]: el.id == activeId,
              [styles.disabledTab]: el.disabled,
              [styles.desktopHidden]: el.isDesktopHidden,
            })}
            onClick={() => {
              if (el.disabled) return;
              onClick(el.id);
              setIsOpen((prev) => !prev);
            }}
            data-testid={isTested ? `test-${el.id}` : null}
            key={el.text}
          >
            {el.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(TabBarNotLinks);
