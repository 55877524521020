import cn from "classnames";
import React, { useEffect, useState } from "react";

import Portal from "shared/ui/atoms/Portal";

import useEscapeHandler from "utils/hooks/useEscapeHandler";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./HelpModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  childrenContainerClassName?: string;
  containerClassName?: string;
  navContent: React.ReactNode;
  navHeader: React.ReactNode;
  contentHeader: React.ReactNode;
  content: React.ReactNode;
  onExpand?: () => void;
  openedKey: number;
}

const HelpModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  className,
  childrenContainerClassName,
  containerClassName,
  navContent,
  navHeader,
  content,
  contentHeader,
  onExpand,
  openedKey,
}) => {
  useEscapeHandler(onClose);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "auto";
  }, [isOpen]);

  const handleShadowClick = (e: React.MouseEvent) => {
    e.preventDefault();
    stopEventPropagation(e);
    setIsCollapsed(true);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, [isOpen, openedKey]);

  return (
    <Portal>
      {isOpen && !isCollapsed && <div className={styles.shadow} onClick={handleShadowClick} />}
      <div
        onClick={stopEventPropagation}
        className={cn(styles.containerWrapper, className, {
          [styles.active]: isOpen,
          [styles.collapsed]: isCollapsed && isOpen,
        })}
      >
        <div className={styles.closeButtonBlock} onClick={onClose}>
          <CrossIcon width={"1rem"} color={"#fff"} />
        </div>
        <div
          className={cn(styles.closeButtonBlock, styles.collapseBtn, { [styles.isCollapsed]: isCollapsed })}
          onClick={() =>
            setIsCollapsed((p) => {
              if (p) {
                onExpand?.();
              }
              return !p;
            })
          }
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 1.10156L11.5 8.00159L4.5 14.9016" stroke="white" strokeWidth="3" />
          </svg>
        </div>
        <div className={cn(styles.container, containerClassName)}>
          <div className={cn(styles.childrenContainer, childrenContainerClassName)}>
            <div className={styles.flex}>
              <div className={styles.nav}>
                <div className={styles.header}>{navHeader}</div>
                <div className={styles.navContent}>{navContent}</div>
              </div>
              <div className={styles.content}>
                <div className={styles.header}>{contentHeader}</div>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default HelpModal;
