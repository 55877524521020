import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import SendMultiplePlansForApproveModal from "./SendMultiplePlansForApproveModal/SendMultiplePlansForApproveModal";
import { multiplePlanApprovingsSelector } from "./model/selectors";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IMultipleApprovingItem } from "./model/types";

import { useLocationArray } from "utils/hooks/useLocationArray";

export interface SendMultiplePlansForApproveProps {
  noMargin?: boolean;
  children?: React.ReactNode
}

const SendMultiplePlansForApprove: React.FC<SendMultiplePlansForApproveProps> = ({ noMargin, children = "Согласование" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buildingId = useObjectId(2);
  const loactionArray = useLocationArray();

  const isHidden = useMemo(() => {
    const isNotCertainBuilding = !buildingId || +buildingId === 0;
    const isNotRightLocation = !loactionArray.includes("manufacturing") && !loactionArray.includes("plan");
    return isNotCertainBuilding || isNotRightLocation;
  }, [buildingId, loactionArray]);

  const sections = useSelector(multiplePlanApprovingsSelector)[buildingId];

  const allInnerItems = useMemo(() => {
    if (!sections) return [];
    return sections.sections.reduce<IMultipleApprovingItem[]>((acc, cur) => {
      return [
        ...acc,
        ...cur.subsections.reduce<IMultipleApprovingItem[]>((acc1, cur1) => {
          return [...acc1, ...cur1.items];
        }, []),
      ];
    }, []);
  }, [sections]);

  if (isHidden) return null;

  return (
    <div style={noMargin ? undefined : { marginRight: "1rem", marginLeft: "auto" }}>
      <ButtonBase secondary disabled={!allInnerItems?.length} onClick={() => setIsOpen(true)}>
        {children}
      </ButtonBase>
      <SendMultiplePlansForApproveModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default SendMultiplePlansForApprove;
