import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FACT_INTERVALS, INTERVALS } from "../../../../../../redux/modules/common/building/manufacturing/manufacturing";
import {
  manufacturingModalMaterialsLoadingSelector,
  modalDetailedIntervalsSelector,
  modalFilesSelector,
  modalIntervalListsSelector,
  modalLoadingsSelector,
  modalMaterialsSelector,
} from "../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  clearModalData,
  clearModalInterval,
  getModalFiles,
  getModalInterval,
  getModalIntervalList,
  getModalWorkMaterials,
  removeModalFile,
  uploadModalFile,
} from "../../../../../../redux/modules/common/building/manufacturing/thunks";

import { useLoadIntervalRelations } from "./IntervalRelationsContent/useLoadIntervalRelations";
import { useApprovePlan } from "./PlanEditing/useApprovePlan";
import { useCanEditPlan } from "./PlanEditing/useCanEditPlan";
import { manufacturingModalStatusSwitcher } from "./manufacturingModalStatusSwitcher";

import { VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS } from "../../../../../../constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_CHART_EDIT_RELATIONS } from "../../../../../../constants/permissions/manufacturingPermissions";
import { INTERVAL_TYPES } from "../../../constants";
import { MAIN_PLAN_TAB, RELATIONS_PLAN_TAB } from "./constants";

import { useCommonModulesPermissions } from "../../../../../../hooks/useCommonModulesPermissions";

export type ManufacturingModalType = "expenditure" | "section" | "lsr" | "building";

export interface IUseManufacturingModalProps {
  isOpen: boolean;
  type: any;
  objectId: string;
  expenditureId: number;
  sectionId: number;
  date_start: string;
  date_end: string;
  modalType: ManufacturingModalType;
  onClose: () => void;
  updateWorkStatus?: (status: string, id: number) => void;
  updatePlanStatus?: (status: string, id: number) => void;
  intervaldata: any;
  isPlanSection?: boolean;
  isEditRelationsByDefault?: boolean;
  isGroupInterval?: boolean;
}

export const useManufacturingModal = ({
  isOpen,
  type,
  objectId,
  expenditureId,
  sectionId,
  date_start,
  date_end,
  modalType,
  onClose,
  updateWorkStatus,
  updatePlanStatus,
  intervaldata,
  isPlanSection,
  isEditRelationsByDefault,
  isGroupInterval,
}: IUseManufacturingModalProps) => {
  const dispatch = useDispatch();
  const modalIntervalList = useSelector(modalIntervalListsSelector)[modalType];
  const interval = useSelector(modalDetailedIntervalsSelector)[modalType];
  const files = useSelector(modalFilesSelector);
  const modalMaterials = useSelector(modalMaterialsSelector);
  const isLoading = useSelector(modalLoadingsSelector)[modalType];
  const isMaterialsLoading = useSelector(manufacturingModalMaterialsLoadingSelector);

  const list = useMemo(() => {
    let listCandidate = modalIntervalList;
    if (modalType === "section" || modalType === "lsr") {
      // @ts-ignore
      listCandidate = listCandidate?.filter((x) => x?.id !== intervaldata?.id);
    }
    // @ts-ignore
    return listCandidate?.sort((a, b) => (moment(a?.start_at).isAfter(moment(b?.start_at)) ? 1 : -1));
  }, [modalIntervalList, intervaldata, modalType]);

  const isPlan = useMemo(() => type === INTERVAL_TYPES.plan, [type]);

  const [isGroup, setIsGroup] = useState<boolean>(isGroupInterval || false);

  const [planTab, setPlanTab] = useState(isEditRelationsByDefault ? RELATIONS_PLAN_TAB : MAIN_PLAN_TAB);

  const hasEditRelationsPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_CHART_EDIT_RELATIONS,
    constructing: VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS,
  });

  const activeModule = isPlan ? INTERVALS : FACT_INTERVALS;
  //@ts-ignore
  if (intervaldata?.building_id) {
    //@ts-ignore
    objectId = intervaldata.building_id;
  }

  useEffect(() => {
    if (!isOpen || (!date_start?.length && !date_end?.length)) return;
    dispatch(
      getModalIntervalList({
        projectId: objectId,
        expenditureId,
        sectionId,
        activeModule,
        date_start,
        date_end,
        modalType,
        hasToRedirectOnSingleInterval: !isPlanSection,
        isGroupInterval,
      })
    );
    return () => {
      dispatch(clearModalInterval(modalType));
    };
  }, [
    isOpen,
    objectId,
    isPlanSection,
    expenditureId,
    sectionId,
    activeModule,
    date_start,
    date_end,
    modalType,
    isGroupInterval,
  ]);

  const { hasRelations, relationsFromCurrentInterval, relationsToCurrentInterval } = useLoadIntervalRelations({
    projectId: +objectId,
    intervalId: interval?.id || intervaldata?.id,
  });

  const intervalClickHandler = (interval_id: number, expenditure_id: number, isGroup: boolean = false) => {
    dispatch(getModalInterval(objectId, activeModule, expenditure_id, interval_id, modalType, isGroup));
    setIsGroup(isGroup);
  };

  useEffect(() => {
    if (!interval?.id || !interval?.expenditure_id) return;
    if (type === INTERVAL_TYPES.work) {
      dispatch(getModalWorkMaterials(objectId, interval.expenditure_id, interval.id));
    }
    dispatch(getModalFiles(objectId, interval.expenditure_id, activeModule, interval.id));
  }, [interval]);

  useEffect(() => {
    if (!interval?.id) return;
    type === INTERVAL_TYPES.work && updateWorkStatus?.(interval.status, interval.id);
    type === INTERVAL_TYPES.plan && updatePlanStatus?.(interval.status, interval.id);
  }, [interval, updateWorkStatus, updatePlanStatus]);

  const closeHandler = () => {
    onClose && onClose();
    dispatch(clearModalData());
  };

  const fileUploadHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadModalFile(objectId, interval?.expenditure_id, activeModule, interval.id, formData));
  };

  const fileRemoveHandler = (fileId: number) =>
    dispatch(removeModalFile(objectId, interval?.expenditure_id, activeModule, interval.id, fileId));

  const handleListBackButton = () => {
    dispatch(clearModalInterval(modalType));
    setIsGroup(isGroupInterval || false);
    if (planTab !== MAIN_PLAN_TAB) setPlanTab(MAIN_PLAN_TAB);
  };

  const intervalDataFetcher = (id: number) => {
    dispatch(
      getModalInterval(objectId, activeModule, interval?.expenditure_id || expenditureId, id, modalType, isGroup)
    );
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const [isEditingPlan, setIsEditingPlan] = React.useState(false);

  const canEditPlan = useCanEditPlan({
    isActive: isOpen,
    isSection: !!isPlanSection,
    approvals: intervaldata?.approvals,
    objectId,
  });

  const planIsInFuture = !!interval?.start_at && moment(interval.start_at).isAfter(moment(), "day");

  const approveType: "section" | "group" | "expenditure" = useMemo(() => {
    if (isGroup) return "group";
    if (isPlanSection) return "section";
    return "expenditure";
  }, [isGroup, isPlanSection]);

  const approveHandler = useApprovePlan(
    Number(objectId),
    interval?.expenditure_id || expenditureId,
    interval?.id,
    { year: moment(interval.start_at).year(), month: moment(interval.start_at).month() + 1 },
    approveType,
    intervalDataFetcher
  );

  const closePlanEditingHandler = () => {
    setIsEditingPlan(false);
    intervalDataFetcher(interval?.id);
  };

  const [isSharing, setIsSharing] = React.useState(false);
  const [isReviewing, setIsReviewing] = React.useState(false);

  const {
    text: statusText,
    color: statusColor,
    icon: statusIcon,
  } = manufacturingModalStatusSwitcher({
    isPlan,
    status: interval?.work_status,
    sharingStatus: interval?.status,
  });

  return {
    isPlan,
    isSharing,
    isReviewing,
    setIsReviewing,
    closeHandler,
    interval,
    statusText,
    statusIcon,
    setIsSharing,
    intervalDataFetcher,
    files,
    fileUploadHandler,
    fileRemoveHandler,
    isEditingPlan,
    setIsEditingPlan,
    closePlanEditingHandler,
    isLoading,
    hasRelations,
    planTab,
    isAddingNew,
    setIsAddingNew,
    list,
    handleListBackButton,
    setPlanTab,
    hasEditRelationsPermission,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    canEditPlan,
    modalMaterials,
    planIsInFuture,
    approveHandler,
    intervalClickHandler,
    statusColor,
    isGroup,
    isMaterialsLoading,
  };
};
