import cn from "classnames";
import React from "react";

import ButtonBack from "../../controls/ButtonBack/ButtonBack";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./BackNavigationBar.module.scss";

interface IProps {
  title: string;
  slashTitle?: string;
  rightSideText?: React.ReactNode;
  backLink?: string;
  onBack?: () => void;
  className?: string;
  isInNestedList?: boolean;
  isBold?: boolean;
}

const BackNavigationBar: React.FC<IProps> = ({
  backLink,
  title,
  slashTitle,
  rightSideText,
  onBack,
  className,
  isInNestedList,
  isBold,
}) => {
  return (
    <nav
      className={cn(styles.backNavigationBar, className, {
        [styles.nested]: isInNestedList,
        [styles.slashed]: !!slashTitle,
        [styles.bold]: isBold,
      })}
    >
      <div className={styles.leftSide}>
        <ButtonBack className={styles.button} link={backLink && backLink} onClick={onBack} />
        <p className={styles.text} title={title}>
          {sliceTextByConstraint(title, slashTitle ? 60 : 120)}
        </p>
        {slashTitle && (
          <p className={styles.slashText} title={slashTitle}>
            {`/  ${sliceTextByConstraint(slashTitle, 60)}`}
          </p>
        )}
      </div>
      <div className={styles.rightSide}>
        <p className={styles.text}>{rightSideText}</p>
      </div>
    </nav>
  );
};

export default React.memo(BackNavigationBar);
