import React, { useMemo, useState } from "react";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useGetDrawingSetsQuery, useGetDrawingsQuery } from "../../model/blueprintsQuery";
import DocumentationTableHeader from "../../ui/DocumentationTableHeader/DocumentationTableHeader";
import DrawingRow from "../../ui/DrawingRow/DrawingRow";
import { emptyDrawingsIcon } from "../../ui/emptyDrawingsIcon";
import BlueprintsCreateModal from "../BlueprintsModal/BlueprintsCreateModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import styles from "./BlueprintsLevel.module.scss";

interface IProps {
  children: React.ReactNode;
  drawingsSetId: string;
  permissions: IBuildingPropsPermissions;
}

const BlueprintsLevel: React.FC<IProps> = ({ children, drawingsSetId, permissions }) => {
  const buildingId = useObjectId();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [isModal, setIsModal] = useState(false);

  const { data: drawingSetsData } = useGetDrawingSetsQuery({ buildingId });
  const { data, isLoading, isFetching } = useGetDrawingsQuery({ buildingId, ...filters, drawing_set: drawingsSetId });

  const showLoader = isLoading || (isFetching && !data?.results.length);

  const parent = useMemo(() => {
    const parent = drawingSetsData?.results.find((el) => +el.id === +drawingsSetId);

    return parent;
  }, [drawingSetsData, drawingsSetId]);

  return (
    <>
      <ListPageHeaderTemplate>
        {children}
        {permissions.canAddDrawings && (
          <ButtonBase secondary onClick={() => setIsModal(true)}>
            Добавить чертеж
          </ButtonBase>
        )}
      </ListPageHeaderTemplate>
      <div className={styles.header}>
        <BackNavigationBar title={parent?.title ?? ""} className={styles.navbar} />
        <DocumentationTableHeader
          onChangeFilter={(name, value) => setFilters((prev) => ({ ...prev, [name]: value }))}
          permissions={permissions}
        />
      </div>

      {data?.results?.map((el) => (
        <DrawingRow key={el.id} drawing={el} parent={parent!} permissions={permissions} />
      ))}
      {showLoader && <Spinner />}
      {!isLoading && !data?.results?.length && (
        <EmptyPlaceholder text="Нет чертежей в комплекте. Добавьте запись" svgJsx={emptyDrawingsIcon}>
          {permissions.canAddDrawings && (
            <ButtonBase onClick={() => setIsModal(true)} primary>
              Добавить чертеж
            </ButtonBase>
          )}
        </EmptyPlaceholder>
      )}
      <BlueprintsCreateModal
        drawingsSetId={drawingsSetId}
        key={data?.results?.length}
        isOpen={isModal}
        onClose={() => setIsModal(false)}
      />
    </>
  );
};

export default BlueprintsLevel;
