import axios from "axios";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export const getExpenseSubProducts = (estimate_item_id, params = null) => {
  return axios
    .get(`/stocks/productusings/`, {
      params: {
        estimate_item_id,
        ...params,
      },
    })
    .catch(errorCatcher);
};

export function apiGetStockProducts(objectId, productId) {
  return axios
    .get(`/stocks/${objectId}/products/${productId}/`)
    .then(({ data }) => data?.replenishments)
    .catch(errorCatcher);
}

export const getStockUsingRequest = (stockUsingId, limit, params = null) => {
  return axios
    .get(`/stocks/productusings/`, { params: { stock_using_id: stockUsingId, limit, ...params } })
    .catch(errorCatcher);
};

export const getStockEventRequest = (stockId, stockUsingId) => {
  return axios
    .get(`/stocks/${stockId}/events/`, { params: { stockusing_id: stockUsingId } })
    .then((response) => response.data.results[0])
    .catch(errorCatcher);
};

export const getStockEventsPackinglist = (packinglist) => {
  return axios.get(`/purchaser/packinglists/${packinglist}/`).catch(errorCatcher);
};

export const approveStockIncoming = (packinglist, payload) => {
  return axios
    .patch(`/purchaser/packinglists/${packinglist}/stock-confirm/`, { approve_stock_responsible: payload })
    .catch(errorCatcher);
};

export const approveStockOutgoing = (stockId, stockusing, payload) => {
  return axios
    .patch(`/stocks/${stockId}/using/${stockusing}/stock-confirm/`, { approve_stock_responsible: payload })
    .catch(errorCatcher);
};
