import Axios from "axios"
import React from "react"
import { getStockUsingRequest } from "../../../../../../redux/modules/common/building/stocksApi"

export const useSubTableUsing = ({ usingId, limit = 100 }) => {
    const [items, setItems] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    React.useEffect(() => {
        if (!usingId) return
        setIsLoading(true)
        getStockUsingRequest(usingId, limit)
            .then(resp => setItems(resp.data.results))
            .finally(() => setIsLoading(false))
    }, [usingId, limit])

    return {
        items,
        isLoading
    }
}