import cn from "classnames";
import React from "react";

import ExecutorsRow from "./components/ExecutorsRow/ExecutorsRow";

import AddButton from "../../shared/ui/controls/AddButton/AddButton";
import TableReusableHead, {
  TableReusableHeaderCell,
} from "../../shared/ui/dataDisplay/TableReusable/TableReusableHead";
import useExecutors, { IExecutor } from "./useExecutors";

import styles from "./Executors.module.scss";

export interface IExecutorsProps {
  className?: string;
  tableClassName?: string;
  addButtonClassName?: string;
  executors: IExecutor[];
  onDelete: ReturnType<typeof useExecutors>["onDelete"];
  onEdit: ReturnType<typeof useExecutors>["onEdit"];
  onAddExecutor: ReturnType<typeof useExecutors>["onAddExecutor"];
  availableRanksByPosts: ReturnType<typeof useExecutors>["availableRanksByPosts"];
  availablePosts: ReturnType<typeof useExecutors>["availablePosts"];
  canEdit: boolean;
}

const Executors: React.FC<IExecutorsProps> = ({
  className,
  tableClassName,
  addButtonClassName,
  executors,
  onEdit,
  onDelete,
  onAddExecutor,
  availableRanksByPosts,
  availablePosts,
  canEdit,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      {canEdit && (
        <AddButton
          text={"Исполнители"}
          textPosition={"left"}
          onClick={onAddExecutor}
          className={cn(styles.addBtn, addButtonClassName)}
        />
      )}
      {!!executors.length && (
        <>
          <div className={cn(styles.tableContainer, tableClassName)}>
            <TableReusableHead className={styles.tableHead}>
              <TableReusableHeaderCell>Должность</TableReusableHeaderCell>
              <TableReusableHeaderCell isCentered>Разряд</TableReusableHeaderCell>
              <TableReusableHeaderCell>Кол-во</TableReusableHeaderCell>
            </TableReusableHead>
            <div className={styles.table}>
              {executors?.map((executor, index) => (
                <ExecutorsRow
                  key={executor.id}
                  index={index}
                  onApprove={(data) => onEdit(executor.id, data)}
                  onDelete={() => onDelete(executor.id)}
                  post={executor.post}
                  canEdit={canEdit}
                  rank={executor.rank}
                  count={executor.count}
                  postOptions={availablePosts}
                  rankOptionsByPost={availableRanksByPosts}
                  isNew={executor.isNew}
                  postTitle={executor.post_title}
                  rankDisplay={executor.rank_display}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(Executors);
