import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { deletePassportDocument } from "redux/modules/common/passportsAndCertificates/thunks";
import { IAttachmentInExpenditure, ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import PassportForm from "../PassportsAndSertificates/PassportForm/PassportForm";
import PassportsRow from "../PassportsAndSertificates/PassportsTable/PassportsRow";
import PassportsTableHead from "../PassportsAndSertificates/PassportsTable/PassportsTableHead";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import FileViewer from "widgets/FileViewer/FileViewer";

import { IExpenditure } from "types/interfaces/Expenditure";

import styles from "./ExpenditureFilesModal.module.scss";

export type attachmentModalType = "expenditure" | "section" | "ks2";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  expenditureId?: number;
  expenditureType?: IExpenditure["expenditure_type"];
  type?: attachmentModalType;
  attachments?: IAttachmentInExpenditure[];
  isPending?: boolean;
  invalidateKey?: number | string;
  onUpload: (file: File) => void;
  onSubmit: (values: ICreatePassport & { range?: number }) => void;
  onDirectlyDelete?: (docId: number) => void;
  isFileViewer?: boolean;
}

const CommonFilesModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  name,
  expenditureId,
  expenditureType,
  type = "expenditure",
  attachments,
  isPending,
  invalidateKey,
  onUpload,
  onSubmit,
  onDirectlyDelete,
  isFileViewer = true,
}) => {
  const dispatch = useDispatch();
  const files = React.useMemo(() => {
    if (!attachments?.length) return [];
    return attachments.filter((el) => el.type === "file");
  }, [attachments]);

  const documents = React.useMemo(() => {
    return attachments?.filter((el) => el.type !== "file") ?? [];
  }, [attachments]);

  const [isPassportForm, setIsPassportForm] = React.useState(false);

  React.useEffect(() => {
    setIsPassportForm(false);
  }, [invalidateKey]);

  const innerType = React.useMemo(() => {
    if (type === "expenditure") return expenditureType;
    return type;
  }, [expenditureType, type]);

  const deleteDocument = useCallback(
    (docId: number) => {
      if (onDirectlyDelete) {
        onDirectlyDelete(docId);
        return;
      }
      if (expenditureId) {
        dispatch(deletePassportDocument(expenditureId, docId));
      }
    },
    [document, expenditureId]
  );

  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.modal}>
      <header className={styles.header}>
        <h2>Вложения</h2>
        {name && <p>к позиции:</p>}
        {name && <h3>{name}</h3>}
      </header>
      <div className={styles.content}>
        {innerType && (
          <PassportForm
            onSubmit={onSubmit}
            expenditureType={innerType}
            isPending={!!isPending}
            isOpen={isPassportForm}
            setIsOpen={setIsPassportForm}
          />
        )}
        {!!documents?.length && (
          <div className={styles.table}>
            <PassportsTableHead />
            {documents?.map((el, i) => (
              <PassportsRow
                key={el.id}
                document={el}
                expenditureId={expenditureId}
                onDirectlyDelete={onDirectlyDelete}
                onDirectlyDownLoad={
                  isFileViewer
                    ? () => {
                        const index = attachments?.findIndex((file) => file.id === el.id)!;
                        setActiveIndex(index);
                      }
                    : undefined
                }
              />
            ))}
          </div>
        )}
        <div className={styles.files}>
          <ExpandableFileInput
            files={files}
            onUploadFileDirectly={onUpload}
            title="Файлы:"
            onRemoveFileDirectly={deleteDocument}
            isFileViewer={isFileViewer}
          />
        </div>
      </div>
      <BottomControls isEmpty isExists />
      <FileViewer
        isOpen={activeIndex > -1}
        onClose={() => setActiveIndex(-1)}
        startIndex={activeIndex}
        files={attachments as any}
      />
    </SliderModal>
  );
};

export default React.memo(CommonFilesModal);
