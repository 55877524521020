import { message } from "antd";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";

import Expandable from "../../../../UI/atoms/_TODO/Expandable/Expandable";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import ClosureDates from "../../../../../shared/ui/inputs/ClosureDates/ClosureDates";
import useClosureDates from "../../../../../shared/ui/inputs/ClosureDates/useClosureDates";
import DoubleInput, { Measure } from "../../../../../shared/ui/inputs/DoubleInput/DoubleInput";
import Executors from "../../../../../widgets/Executors/Executors";
import useExecutors from "../../../../../widgets/Executors/useExecutors";
import { useSectionPlan } from "./useSectionPlan";
import ButtonBase from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";

import { IRouterParamsWithObjectId } from "../../../../../types/routerTypes";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./SectionPlanModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  sectionName: string;
  sectionId: number;
  isLsr?: boolean;
  onPlanCreateSuccess?: () => void;
  sectionBudget?: string;
  defaultDate?: string;
}

const SectionPlanModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  sectionName,
  sectionId,
  isLsr,
  onPlanCreateSuccess,
  sectionBudget,
  defaultDate,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const isNew_close_dates = useSelector(objectDetailSelector)?.new_close_dates;
  const [canSave, setCanSave] = useState(true);

  const {
    closureDates,
    addNewClosureDate,
    editClosureDate,
    removeClosureDate,
    createSectionClosureDates,
    validateClosureDates,
    validateClosureDatesPercents,
    /* @ts-ignore */
  } = useClosureDates({ isOpen, objectId, sectionId });

  const {
    executors,
    onDelete,
    onEdit,
    onAddExecutor,
    availablePosts,
    availableRanksByPosts,
    createSectionExecutors,
    validateExecutors,
  } = useExecutors({
    isOpen,
  });

  const { dateEnd, dateStart, setDateEnd, setDateStart, submitHandler } = useSectionPlan({
    defaultDate,
    sectionId,
    onClose,
    planCreateSuccessCallback: async (planData, withMissedPercentFilling = false) => {
      await createSectionClosureDates({ planId: planData.id, withMissedPercentFilling });
      await createSectionExecutors({ planId: planData.id });
      onPlanCreateSuccess?.();
    },
  });

  const handleSaveClick = () => {
    if (!validateClosureDates()) {
      message.warn("Подтвердите все даты закрытия");
      return;
    }
    if (!validateExecutors()) {
      message.warn("Подтвердите всех исполнителей");
      return;
    }
    if (isNew_close_dates && !validateClosureDatesPercents()) {
      message.warn("Введите корректные проценты");
      return;
    }
    let isPassingValidation = false;

    if (!canSave) {
      const percentSum = +dropNonSignificantZeros(
        /* @ts-ignore */
        closureDates.reduce((acc, curr) => acc + +curr.user_percent, 0),
        8
      );
      let budgetSum = 0;

      closureDates.forEach((date) => {
        if (date.strategy === 2) {
          /* @ts-ignore */
          budgetSum += (+date.user_percent / 100) * +sectionBudget;
        }
      });

      budgetSum = +dropNonSignificantZeros(budgetSum, 8);

      if (isNew_close_dates && closureDates.some((d) => d.strategy === 2)) {
        if (percentSum > 100) {
          /* @ts-ignore */
          if (Math.abs(+sectionBudget - budgetSum) < 0.001) {
            isPassingValidation = true;
          } else {
            let formattedPercents = Number(+percentSum - 100).toString();
            if (formattedPercents.includes("e")) {
              formattedPercents = Number(formattedPercents).toFixed(8);
            } else {
              /* @ts-ignore */
              formattedPercents = dropNonSignificantZeros(formattedPercents, 8);
            }

            message.warn(
              `Превышение распределения на ${formattedPercents}% на сумму ${transformDigitToFinancial(
                /* @ts-ignore */
                dropNonSignificantZeros((+sectionBudget * (+percentSum - 100)) / 100, 2)
              )}₽`
            );
          }
        } else if (percentSum < 100) {
          /* @ts-ignore */
          if (Math.abs(+sectionBudget - budgetSum) < 0.001) {
            isPassingValidation = true;
          } else {
            let formattedPercents = Number(100 - +percentSum).toString();
            if (formattedPercents.includes("e")) {
              formattedPercents = Number(formattedPercents).toFixed(8);
            } else {
              /* @ts-ignore */
              formattedPercents = dropNonSignificantZeros(formattedPercents, 8);
            }

            message.warn(
              `Не распределено ${formattedPercents}% на сумму ${transformDigitToFinancial(
                /* @ts-ignore */
                dropNonSignificantZeros((+sectionBudget * (100 - +percentSum)) / 100, 2)
              )}₽`
            );
          }
        }
      } else {
        isPassingValidation = true;
      }

      if (!isPassingValidation) {
        return;
      }
    }

    submitHandler(isPassingValidation);
  };

  useEffect(() => {
    /* @ts-ignore */
    const isCompletePercents = closureDates.reduce((acc, curr) => acc + +curr.user_percent, 0) === 100;
    const isCompleteSum =
      /* @ts-ignore */
      closureDates.reduce((acc, curr) => acc + Number(curr.amount?.replaceAll(" ", "")), 0) === +sectionBudget;
    setCanSave(() => {
      if (closureDates.length && isNew_close_dates) {
        return isCompletePercents || isCompleteSum;
      }
      return true;
    });
  }, [closureDates, isNew_close_dates]);

  return (
    <ModalContainer
      headlineClassName={styles.headline}
      isOpen={isOpen}
      onClose={onClose}
      name="Добавить в план"
      customHeadline={<div className={styles.subtitle}>{sectionName}</div>}
    >
      <div className={cn(styles.field, isNew_close_dates && styles.plannedCompletion)}>
        {/* @ts-ignore */}
        {isNew_close_dates && <span>Период выполнения:</span>}
        <CalendarRange
          label={!isNew_close_dates ? "Укажите период" : undefined}
          defaultDateEnd={dateEnd} /* @ts-ignore */
          defaultDateStart={dateStart} /* @ts-ignore */
          setDefaultDateEnd={setDateEnd} /* @ts-ignore */
          isTooRight={isNew_close_dates} /* @ts-ignore */
          setDefaultDateStart={setDateStart}
          classNameSelect={isNew_close_dates ? styles.calendarInput : undefined}
        />
      </div>
      {!isLsr && (
        <>
          <div className={cn(styles.field, styles.plannedCompletion)}>
            <span>Запланировано выполнение:</span>
            <DoubleInput
              firstValueMeasure={Measure.percent}
              firstValue={"0"}
              firstInputSize={1}
              secondValueMeasure={Measure.currency}
              secondValue={"0"}
              secondInputSize={2}
              onChangeFirstValue={() => {}}
              onChangeSecondValue={() => {}}
              isFirstDisabled
              isSecondDisabled
              isFullHeight
              variant={"outline"}
            />
          </div>
          {isNew_close_dates && (
            <div className={cn(styles.field, styles.plannedCompletion)}>
              <span>Бюджет (по проекту):</span>
              <InputBase
                isButtonActive
                value={sectionBudget}
                disabled
                valueType={VALUE_TYPES.PRICE} /* @ts-ignore */
                numberTransformOptions={{ withCurrencySign: true }}
              />
            </div>
          )}
          <Expandable title={`Плановые даты закрытия:`}>
            <ClosureDates /* @ts-ignore */
              budgetData={sectionBudget}
              closureDates={closureDates}
              addNewClosureDate={addNewClosureDate}
              editClosureDate={editClosureDate}
              removeClosureDate={removeClosureDate}
              canEdit
              canSave={canSave} /* @ts-ignore */
              isNew_close_dates={isNew_close_dates}
            />
          </Expandable>
          <Executors
            className={styles.field}
            tableClassName={styles.executorsTable}
            addButtonClassName={styles.addExecutorBtn}
            availablePosts={availablePosts}
            availableRanksByPosts={availableRanksByPosts}
            executors={executors}
            onDelete={onDelete}
            onEdit={onEdit}
            onAddExecutor={onAddExecutor}
            canEdit
          />
        </>
      )}
      <BottomControls isExists isDoubleBtns>
        <ButtonBase secondary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={handleSaveClick}>
          Сохранить
        </ButtonBase>
      </BottomControls>
    </ModalContainer>
  );
};

export default SectionPlanModal;
