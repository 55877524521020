import React from "react";

import { getPlanMaterials } from "redux/modules/common/building/processApi";

import { IMaterialInAddingInFact, ISerializedMaterialInPlan } from "types/interfaces/Materials";

import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IUseMaterialsPlanList {
  objectId: number;
  expId: number;
  presettedPercentage?: number;
  defaultMaterialsList?: IMaterialInAddingInFact[];
  isWorkGroup?: boolean;
}

export const useMaterialsPlanList = ({
  objectId,
  expId,
  presettedPercentage,
  defaultMaterialsList,
  isWorkGroup,
}: IUseMaterialsPlanList) => {
  const [allMaterials, setAllMaterials] = React.useState<ISerializedMaterialInPlan[]>(defaultMaterialsList || []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState(defaultMaterialsList?.length || 0);

  const [filters, setFilters] = React.useState<Record<string, string> | null>(null);

  React.useEffect(() => {
    if (!defaultMaterialsList) return;
    setAllMaterials(defaultMaterialsList);
    setCount(defaultMaterialsList?.length);
  }, [defaultMaterialsList]);

  React.useEffect(() => {
    if (isWorkGroup) return;
    setIsLoading(true);
    getPlanMaterials(objectId, expId, filters)
      .then((resp) => {
        setAllMaterials(resp.data.results);
        setCount(resp.data.count);
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  }, [objectId, expId, filters, isWorkGroup]);

  const filterHandler = (value: string, fieldName: string) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const loadMoreHandler = () => {
    if (isWorkGroup) return;
    getPlanMaterials(objectId, expId, { ...filters, offset: String(allMaterials.length) }).then((resp) =>
      setAllMaterials((prev) => [...prev, ...resp.data.results])
    );
  };

  const presettedPlanMaterials = React.useMemo(() => {
    if (!presettedPercentage) return [];
    return allMaterials.map((el) => ({
      ...el,
      local_count: (Number(el.count) * (presettedPercentage || 1)).toFixed(4),
    }));
  }, [allMaterials, presettedPercentage]);

  return {
    allMaterials,
    isLoading,
    count,
    filterHandler,
    loadMoreHandler,
    presettedPlanMaterials,
  };
};
