import { memoize } from "lodash";
import React, { FC, useEffect, useMemo } from "react";

import HiddenSubsection from "./components/HiddenSubsection/HiddenSubsection";
import HideBlock from "components/UI/atoms/_TODO/HideBlock";

import { IExpenditure } from "types/interfaces/Expenditure";
import ISection, { IExpenditureTree, ISectionTree, ISubsectionTree } from "types/interfaces/Section";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./SectionsWithCheckedExpenditures.module.scss";

export interface IEstimateTarget {
  sectionId: ISectionTree["id"] | undefined;
  subsectionId: ISubsectionTree["id"] | undefined;
  expenditureId: IExpenditureTree["id"] | undefined;
}

export interface ISectionsWithCheckedExpendituresProps {
  sections: ISectionTree[];
  opened: boolean;
  selectedEstimateTarget: IEstimateTarget | null;
  onCheckExpenditure: (target: IEstimateTarget, isChecked: boolean) => void;
  isDisabled?: boolean;
  expendituresBySections?: IExpenditure[];
  onOpenHiddenSubsection?: (id: number) => void;
  externalCheckedExpenditures?: Record<IExpenditure["id"], boolean>;
  isExternalMultiple?: boolean;
}

const SectionsWithCheckedExpenditures: FC<ISectionsWithCheckedExpendituresProps> = ({
  sections,
  opened,
  selectedEstimateTarget,
  onCheckExpenditure,
  expendituresBySections,
  onOpenHiddenSubsection,
  externalCheckedExpenditures,
  isExternalMultiple,
}) => {
  const checkedExpenditures = useMemo(() => {
    if (!!externalCheckedExpenditures) {
      return externalCheckedExpenditures;
    }
    return selectedEstimateTarget?.expenditureId ? { [selectedEstimateTarget.expenditureId]: true } : {};
  }, [selectedEstimateTarget, externalCheckedExpenditures, isExternalMultiple]);

  const partialCheckExpenditure =
    (sectionId: ISection["id"]) => (estimateTarget: Omit<IEstimateTarget, "sectionId">, isChecked: boolean) => {
      onCheckExpenditure(
        {
          ...estimateTarget,
          sectionId,
        },
        isChecked
      );
    };

  const memoizedPartialCheckExpenditure = useMemo(
    () => memoize(partialCheckExpenditure, stringifyArgs),
    [onCheckExpenditure]
  );

  return (
    <div>
      {sections.map((section) => (
        <HideBlock
          titleClassName={styles.sectionTitleClassName}
          title={section.name}
          key={section.id}
          isHiddenDefault={
            !(
              selectedEstimateTarget?.sectionId === section?.id ||
              opened ||
              section.subsections.some((s) => s.expenditures.some((el) => checkedExpenditures[el.id]))
            )
          }
        >
          <div className={styles.subSection}>
            {Array.isArray(section.subsections) &&
              section.subsections.map((subsection) => (
                <HiddenSubsection
                  subsection={subsection}
                  opened={opened || subsection.expenditures?.some((el) => checkedExpenditures[el.id])}
                  expenditures={subsection.expenditures ?? expendituresBySections?.[subsection.id]}
                  expendituresAreLoading={!subsection.expenditures && !expendituresBySections?.[subsection.id]}
                  selectedEstimateTarget={selectedEstimateTarget}
                  checkedExpenditures={checkedExpenditures}
                  onCheckExpenditure={memoizedPartialCheckExpenditure(section.id)}
                  key={subsection.id}
                  onOpenHiddenSubsection={onOpenHiddenSubsection}
                />
              ))}
          </div>
        </HideBlock>
      ))}
    </div>
  );
};

export default React.memo(SectionsWithCheckedExpenditures);
