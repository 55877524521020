import { IActions } from "app/store/store";

import {
  CHART_LOADERS,
  CHART_TABS,
  CHART_VIEW_MODE,
  ChartActions,
  ChartFilterType,
  ChartTreeExpanderType,
  IChartFilters,
  IChartPersistValues,
  IChartStatePatch,
  IChartTree,
  IDraggedArrow,
  IPlanRelation,
  IPlanRelationCore,
  RELATION_TYPES,
  TouchedYearsUpdateOptions,
  ChartCheckpointsMarks,
} from "./types";

import { UpdateTreeParams } from "./utils";

export const chartActions = {
  // tree
  addTree: ({ tab, tree, index }: { tab: CHART_TABS; tree: IChartTree; index: number }) =>
    ({
      type: "chart/ADD_TREE",
      payload: { tab, tree, index },
    } as const),
  updateTree: ({ tab, params }: { tab?: CHART_TABS; params: UpdateTreeParams }) =>
    ({
      type: "chart/UPDATE_TREE",
      payload: { params, tab },
    } as const),
  incrementProjectSliceIndex: () =>
    ({
      type: "chart/INCREMENT_PROJECT_SLICE_INDEX",
      payload: {},
    } as const),
  setProjectIds: (projectIds: number[]) =>
    ({
      type: "chart/SET_PROJECT_IDS",
      payload: projectIds,
    } as const),
  setTab: (tab: CHART_TABS) =>
    ({
      type: "chart/SET_TAB",
      payload: tab,
    } as const),
  setViewMode: (mode: CHART_VIEW_MODE) =>
    ({
      type: "chart/SET_VIEW_MODE",
      payload: mode,
    } as const),
  updateAction: ({ name, value }: { name: ChartActions; value: boolean }) =>
    ({
      type: "chart/UPDATE_ACTION",
      payload: { name, value },
    } as const),
  updateTreeFilter: ({ filter, value }: { filter: ChartFilterType; value: IChartFilters[ChartFilterType] }) =>
    ({
      type: "chart/UPDATE_TREE_FILTER",
      payload: { filter, value },
    } as const),
  updateTreeExpander: ({ name, value, tab }: { name: ChartTreeExpanderType; value: boolean; tab?: CHART_TABS }) =>
    ({
      type: "chart/UPDATE_TREE_EXPANDER",
      payload: { name, value, tab },
    } as const),
  setLoader: (loader: CHART_LOADERS, value: boolean) =>
    ({
      type: "chart/SET_LOADER",
      payload: { loader, value },
    } as const),
  updateHash: (hash: number = Math.random()) =>
    ({
      type: "chart/UPDATE_HASH",
      payload: hash,
    } as const),
  addOpenedTree: (treeId: IChartTree["_id"]) =>
    ({
      type: "chart/ADD_OPENED_TREE",
      payload: treeId,
    } as const),
  removeOpenedTree: (treeId: IChartTree["_id"]) =>
    ({
      type: "chart/REMOVE_OPENED_TREE",
      payload: treeId,
    } as const),

  // scroll
  setScrollMarkers: (markers: number[], year: number) =>
    ({
      type: "chart/SET_SCROLL_MARKERS",
      payload: { markers, year },
    } as const),
  setTouchedYears: (touchedYears: number[], options?: TouchedYearsUpdateOptions) =>
    ({
      type: "chart/SET_TOUCHED_YEARS",
      payload: { touchedYears, options },
    } as const),
  setYear: (year: number) =>
    ({
      type: "chart/SET_YEAR",
      payload: year,
    } as const),
  setMonth: (month: number) =>
    ({
      type: "chart/SET_MONTH",
      payload: month,
    } as const),

  // relations
  addRelations: ({
    tab,
    fromIntervalsById,
    fromGroupsById,
  }: {
    tab?: CHART_TABS;
    fromIntervalsById: Record<number, IPlanRelation[]>;
    fromGroupsById: Record<number, IPlanRelation[]>;
  }) =>
    ({
      type: "chart/ADD_RELATIONS",
      payload: { tab, fromIntervalsById, fromGroupsById },
    } as const),
  setDraggedArrow: (arrow: IDraggedArrow | null) =>
    ({
      type: "chart/SET_DRAGGED_ARROW",
      payload: arrow,
    } as const),
  setJustAddedArrow: (arrow: IPlanRelation | null) =>
    ({
      type: "chart/SET_JUST_ADDED_ARROW",
      payload: arrow,
    } as const),
  setDraggedArrowRelationType: (relationType: RELATION_TYPES) =>
    ({
      type: "chart/SET_DRAGGED_ARROW_RELATION_TYPE",
      payload: relationType,
    } as const),
  updateArrowHash: (hash: number = Math.random()) =>
    ({
      type: "chart/SET_ARROW_HASH",
      payload: hash,
    } as const),
  addArrow: ({ tab, arrow }: { tab?: CHART_TABS; arrow: IPlanRelationCore }) =>
    ({
      type: "chart/ADD_ARROW",
      payload: { tab, arrow },
    } as const),
  deleteArrow: ({ tab, arrow }: { tab?: CHART_TABS; arrow: IPlanRelationCore }) =>
    ({
      type: "chart/DELETE_ARROW",
      payload: { tab, arrow },
    } as const),
  updateArrow: ({ tab, arrow, data }: { tab?: CHART_TABS; arrow: IPlanRelationCore; data: IPlanRelation }) =>
    ({
      type: "chart/UPDATE_ARROW",
      payload: { tab, arrow, data },
    } as const),
    
  addCheckpointsMarks: (marks: ChartCheckpointsMarks) => ({
    type: 'chart/ADD_CHECKPOINTS_MARKS',
    payload: marks
  } as const),

  setIsLoadedIntervalsByYear: ({
    tab,
    projectId,
    year,
    isLoaded,
  }: {
    tab: CHART_TABS;
    projectId: number;
    year: number;
    isLoaded: boolean;
  }) =>
    ({
      type: "chart/SET_IS_LOADED_INTERVALS_BY_YEAR",
      payload: { tab, projectId, year, isLoaded },
    } as const),

  patchState: (patch: IChartStatePatch) =>
    ({
      type: "chart/PATCH_STATE",
      payload: patch,
    } as const),

  dropChart: (persistValues?: IChartPersistValues) =>
    ({
      type: "chart/DROP",
      payload: persistValues,
    } as const),
};

export type IChartAction = IActions<typeof chartActions>;
