import axios from "axios";

import { ICreateProject } from "./types";
import { IListResponseData } from "types/ListResponse";

export const projectsApi = {
  create: (data: ICreateProject) => {
    return axios.post(`/projects/create/`, data);
  },
  getList: (params: any) => {
    return axios.get<IListResponseData<any>>(`/projects/`, { params: { ...params, limit: 500 } });
  },
  getDetailed: (id: number | string) => {
    return axios.get(`/projects/${id}/`);
  },
  edit: (id: string, data: any) => {
    return axios.patch(`/projects/${id}/`, data);
  },
};
