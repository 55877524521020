import { message } from "antd";
import { Dispatch } from "redux";

import { journalActions } from "../actions";
import { journalExecutionActions } from "./actions";
import { journalExecutionApi } from "./journalExecutionApi";
import { RootState } from "app/store/rootReducer";

import {
  IAcceptGroupTicketToActingData,
  IAcceptedGroupTicketActingInfo,
  IAcceptedTicketActingInfo,
  ICreateDeliveryRemarkData,
  ICreateRemarkData,
  IEditRemarkData,
  IResolveRemarkData,
  IReturnRemarkData,
  ISectionsFilterJournalExec,
} from "./types";

import { generateJornalRemarkSectionKey, generateJornalTicketSectionKey } from "../utils";
import {
  checkIsNeedSetLoading,
  prepareDataForAcceptGroupTicketToActing,
  prepareDataForAcceptTicketToActing,
} from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getJournalSectionsWithRemarks =
  (buildingId: string, filters: ISectionsFilterJournalExec) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = buildingId;
    if (checkIsNeedSetLoading(getState(), filters as any, key, "sectionsWithRemarks")) {
      dispatch(journalExecutionActions.setSectionsWithRemarksLoading(true, key));
    }
    journalExecutionApi
      .getSectionsWithRemarks(buildingId, filters)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setSectionsWithRemarks(data.results, key));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setSectionsWithRemarksLoading(false, key));
      });
  };

export const getJournalExecutionSections =
  (buildingId: string, year: number, month: number, filters: ISectionsFilterJournalExec) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month);
    if (checkIsNeedSetLoading(getState(), filters as any, key)) {
      dispatch(journalExecutionActions.setSectionsLoading(true, key));
    }
    journalExecutionApi /* @ts-ignore */
      .getSections(buildingId, year, month, filters) /* @ts-ignore */
      .then(({ data }) => {
        dispatch(journalExecutionActions.setSections(data.results, key));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setSectionsLoading(false, key));
      });
  };

export const setJournalExecutionTicketInSection =
  (buildingId: string, year: number, month: number, sectionId: number, ticketId: number, successCallback?: Function) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const filters = { year, month, id: ticketId };
    const key = generateJornalTicketSectionKey(buildingId, year, month, sectionId);
    if (checkIsNeedSetLoading(getState(), filters as any, key)) {
      dispatch(journalExecutionActions.setSectionsLoading(true, key));
    }
    journalExecutionApi /* @ts-ignore */
      .getTickets(buildingId, year, month, sectionId, filters) /* @ts-ignore */
      .then(async ({ data }) => {
        dispatch(journalExecutionActions.setTicketInSection(data.results?.[0], key));
        successCallback?.();
      })
      .finally(() => {
        dispatch(journalExecutionActions.setSectionsLoading(false, key));
      });
  };

export const getJournalExecutionTickets =
  (buildingId: string, year: number, month: number, sectionId: number, filters: any) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month, sectionId);
    if (!getState().journalExecution.ticketsBySections?.[key]?.results?.length) {
      dispatch(journalExecutionActions.setTicketsLoading(true, key));
    }
    journalExecutionApi /* @ts-ignore */
      .getTickets(buildingId, year, month, sectionId, filters) /* @ts-ignore */
      .then(({ data }) => {
        dispatch(journalExecutionActions.setTickets(data.results, key));
      })
      .then(() => {
        journalExecutionApi.getGroups(buildingId, year, month, sectionId, filters).then(({ data }) => {
          dispatch(journalExecutionActions.setGroupTickets(data.results, key));
        });
      })
      .finally(() => dispatch(journalExecutionActions.setTicketsLoading(false, key)));
  };

export const getJournalRemarksBySection =
  (buildingId: string, sectionId: number, filters: any) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalRemarkSectionKey(buildingId, sectionId);
    if (!getState().journalExecution.remarksBySections?.[key]?.results?.length) {
      dispatch(journalExecutionActions.setRemarksBySectionLoading(true, key));
    }
    journalExecutionApi
      .getRemarks(buildingId, sectionId, filters)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setRemarksBySection(data.results, key));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setRemarksBySectionLoading(false, key));
      });
  };

export const postJournalExecutionCreateRemark =
  (buildingId: string, data: ICreateRemarkData) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsCreateRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsCreateRemarkLoading(true));

    journalExecutionApi
      .createRemark(buildingId, data)
      .then(() => {
        dispatch(journalExecutionActions.changeInvalidateKey());
        dispatch(journalExecutionActions.setIsCreateRemarkSuccess(true));
        message.success("Замечание создано");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsCreateRemarkLoading(false));
      });
  };

export const postJournalDeliveryCreateRemark =
  (buildingId: string, data: ICreateDeliveryRemarkData) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsCreateRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsCreateRemarkLoading(true));

    journalExecutionApi
      .createDeliveryRemark(buildingId, data)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        dispatch(journalExecutionActions.setIsCreateRemarkSuccess(true));
        message.success("Замечание создано");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsCreateRemarkLoading(false));
      });
  };

export const putJournalExecutionEditRemark =
  (buildingId: string, remarkId: number, data: IEditRemarkData) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsEditRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsEditRemarkLoading(true));

    journalExecutionApi
      .editRemark(buildingId, remarkId, data)
      .then((payload) => {
        dispatch(journalExecutionActions.setRemarkDetailInfoData(payload.data));
        dispatch(journalExecutionActions.setIsEditRemarkSuccess(true));
        dispatch(journalExecutionActions.changeInvalidateKey());
        message.success("Замечание изменено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsEditRemarkLoading(false));
      });
  };

export const putJournalDeliveryEditRemark =
  (buildingId: string, remarkId: number, data: IEditRemarkData) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsEditRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsEditRemarkLoading(true));

    journalExecutionApi
      .editDeliveryRemark(buildingId, remarkId, data)
      .then((payload) => {
        dispatch(journalExecutionActions.setRemarkDetailInfoData(payload.data));
        dispatch(journalExecutionActions.setIsEditRemarkSuccess(true));
        dispatch(journalExecutionActions.changeInvalidateKey());
        message.success("Замечание изменено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsEditRemarkLoading(false));
      });
  };

export const getJournalExecutionRemarksList =
  (buildingId: string, expenditureId: number, isShowOnlyAccepted: boolean, isGroup?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsRemarksListLoading(true));

    journalExecutionApi
      .getRemarksList(buildingId, expenditureId, isShowOnlyAccepted, isGroup)
      .then((payload) => {
        dispatch(journalExecutionActions.setRemarksListData(payload.data.results));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setIsRemarksListLoading(false));
      });
  };

export const getJournalDeliveryExecutionRemarksList =
  (buildingId: string, expenditureId: number, isShowOnlyAccepted: boolean, isGroup?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsRemarksListLoading(true));

    journalExecutionApi
      .getDeliveryRemarksList(buildingId, expenditureId, isShowOnlyAccepted, isGroup)
      .then((payload) => {
        dispatch(journalExecutionActions.setRemarksListData(payload.data.results));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setIsRemarksListLoading(false));
      });
  };

export const getJournalExecutionDetailRemarkInfo = (buildingId: string, remarkId: number) => (dispatch: Dispatch) => {
  dispatch(journalExecutionActions.setIsRemarkDetailInfoLoading(true));

  journalExecutionApi
    .getDetailRemarkInfo(buildingId, remarkId)
    .then((payload) => {
      dispatch(journalExecutionActions.setRemarkDetailInfoData(payload.data));
    })
    .finally(() => {
      dispatch(journalExecutionActions.setIsRemarkDetailInfoLoading(false));
    });
};

export const getJournalDeliveryExecutionDetailRemarkInfo =
  (buildingId: string, remarkId: number) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsRemarkDetailInfoLoading(true));

    journalExecutionApi
      .getDetailDeliveryRemarkInfo(buildingId, remarkId)
      .then((payload) => {
        dispatch(journalExecutionActions.setRemarkDetailInfoData(payload.data));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setIsRemarkDetailInfoLoading(false));
      });
  };

export const deleteJournalExecutionRemark = (buildingId: string, remarkId: number) => (dispatch: Dispatch) => {
  dispatch(journalExecutionActions.setIsDeleteRemarkSuccess(false));
  dispatch(journalExecutionActions.setIsDeleteRemarkLoading(true));

  journalExecutionApi
    .deleteRemark(buildingId, remarkId)
    .then(() => {
      dispatch(journalExecutionActions.changeInvalidateKey());
      dispatch(journalExecutionActions.setIsDeleteRemarkSuccess(true));
      message.success("Замечание удалено");
    })
    .finally(() => {
      dispatch(journalExecutionActions.setIsDeleteRemarkLoading(false));
    })
    .catch(errorCatcher);
};

export const deleteJournalDeliveryRemark = (buildingId: string, remarkId: number) => (dispatch: Dispatch) => {
  dispatch(journalExecutionActions.setIsDeleteRemarkSuccess(false));
  dispatch(journalExecutionActions.setIsDeleteRemarkLoading(true));

  journalExecutionApi
    .deleteDeliveryRemark(buildingId, remarkId)
    .then(() => {
      dispatch(journalActions.changeInvalidateKey());
      dispatch(journalExecutionActions.setIsDeleteRemarkSuccess(true));
      message.success("Замечание удалено");
    })
    .finally(() => {
      dispatch(journalExecutionActions.setIsDeleteRemarkLoading(false));
    })
    .catch(errorCatcher);
};

export const postJournalExecutionResolveRemark =
  (buildingId: string, remarkId: number, data: IResolveRemarkData, successCallback?: () => void) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(true));

    journalExecutionApi
      .resolveRemark(buildingId, remarkId, data)
      .then(() => {
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(true));
        successCallback?.();
        message.success("Замечание отправлено на проверку");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(false));
      });
  };

export const postJournalDeliveryResolveRemark =
  (buildingId: string, remarkId: number, data: IResolveRemarkData, successCallback?: () => void) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(true));

    journalExecutionApi
      .resolveDeliveryRemark(buildingId, remarkId, data)
      .then(() => {
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(true));
        successCallback?.();
        message.success("Замечание отправлено на проверку");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(false));
      });
  };

export const postJournalExecutionReturnRemark =
  (buildingId: string, remarkId: number, data: IReturnRemarkData, successCallback?: () => void) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(true));

    journalExecutionApi
      .returnRemark(buildingId, remarkId, data)
      .then(() => {
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(true));
        successCallback?.();
        message.success("Замечание отправлено на доработку");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(false));
      });
  };

export const postJournalDeliveryReturnRemark =
  (buildingId: string, remarkId: number, data: IReturnRemarkData, successCallback?: () => void) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(false));
    dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(true));

    journalExecutionApi
      .returnDeliveryRemark(buildingId, remarkId, data)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkSuccess(true));
        successCallback?.();
        message.success("Замечание отправлено на доработку");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsResolveOrReturnRemarkLoading(false));
      });
  };

export const postJournalExecutionAcceptRemark =
  (buildingId: string, remarkId: number, successCallback?: () => void) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsAcceptRemarkLoading(true));

    journalExecutionApi
      .acceptRemark(buildingId, remarkId)
      .then(() => {
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
        successCallback?.();
        message.success("Замечание устранено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsAcceptRemarkLoading(false));
      });
  };

export const postJournalDeliveryAcceptRemark =
  (buildingId: string, remarkId: number, successCallback?: () => void) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsAcceptRemarkLoading(true));

    journalExecutionApi
      .acceptDeliveryRemark(buildingId, remarkId)
      .then(() => {
        dispatch(journalExecutionActions.changeReportInvalidateKey());
        dispatch(journalActions.changeInvalidateKey());
        successCallback?.();
        message.success("Замечание устранено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsAcceptRemarkLoading(false));
      });
  };

export const getTicketActingInfo =
  (buildingId: string, expenditureId: number, year: number, month: number) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setIsTicketActingInfoLoading(true));

    journalExecutionApi
      .getTicketActingInfo(buildingId, expenditureId, year, month)
      .then((response) => {
        dispatch(journalExecutionActions.setTicketActingInfoData(response.data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setIsTicketActingInfoLoading(false));
      });
  };

export const getGroupTicketActingInfo =
  (buildingId: string, groupId: number, year: number, month: number) => (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setGroupTicketActingInfoLoading(true));

    journalExecutionApi
      .getGroupTicketActingInfo(buildingId, groupId, year, month)
      .then((response) => {
        dispatch(journalExecutionActions.setGroupTicketActingInfoData(response.data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalExecutionActions.setGroupTicketActingInfoLoading(false));
      });
  };

export const acceptTicketToActing =
  (
    buildingId: string,
    expenditureId: number,
    year: number,
    month: number,
    sectionId: number,
    data: IAcceptedTicketActingInfo
  ) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setTicketActingIsApproving(true));
    journalExecutionApi /* @ts-ignore */
      .acceptToActing(buildingId, expenditureId, year, month, prepareDataForAcceptTicketToActing(data))
      .then(() => {
        dispatch(journalExecutionActions.changeInvalidateKey()); /* @ts-ignore */
        dispatch(getJournalExecutionTickets(buildingId, year, month, sectionId, {}));
        message.success("Успешно принято");
      })
      .catch(errorCatcher)
      .finally(() => dispatch(journalExecutionActions.setTicketActingIsApproving(false)));
  };

export const acceptGroupTicketToActing =
  (
    buildingId: string,
    expenditureId: number,
    year: number,
    month: number,
    sectionId: number,
    data: IAcceptedGroupTicketActingInfo
  ) =>
  (dispatch: Dispatch) => {
    dispatch(journalExecutionActions.setTicketActingIsApproving(true));
    journalExecutionApi
      .acceptGroupTicketToActing(buildingId, expenditureId, {
        year,
        month,
        ...prepareDataForAcceptGroupTicketToActing(data),
      })
      .then(() => {
        dispatch(journalExecutionActions.changeInvalidateKey()); /* @ts-ignore */
        dispatch(getJournalExecutionTickets(buildingId, year, month, sectionId, {}));
        message.success("Успешно принято");
      })
      .catch(errorCatcher)
      .finally(() => dispatch(journalExecutionActions.setTicketActingIsApproving(false)));
  };
