import moment, { Moment } from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";

import { planFactActions } from "../../../redux/modules/_TODO/constructing/finance/planFact/actions";
import { planFactSelectedDatesSelector } from "../../../redux/modules/_TODO/constructing/finance/planFact/selectors";
import {
  loadPlanFactTree,
  togglePlanFactMeasure,
} from "../../../redux/modules/_TODO/constructing/finance/planFact/thunks";

import PlanFactVolume from "../../../pages/PlanFact/PlanFactVolume/PlanFactVolume";
import PlanFactVolumeExpenditures from "../../../pages/PlanFact/PlanFactVolume/PlanFactVolumeExpenditures";
import PlanFactVolumeSectionslevel from "../../../pages/PlanFact/PlanFactVolume/PlanFactVolumeSectionslevel";
import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";
import { planFactVolumeIsAllExpandedSelector } from "pages/PlanFact/PlanFactVolume/model/selectors";
import { planFactVolumeAllExpand } from "pages/PlanFact/PlanFactVolume/model/thunks";

import { useTypedSelector } from "../../../app/store/typedUseSelector";
import MeasureBtn from "../../../features/financeMeasure/MeasureBtn/MeasureBtn";
import TemplateBase from "../../../features/templates/TemplateBase/TemplateBase";
import PlanFactFinanceMonthsSlider from "./PlanFactFinanceMonthsSlider/PlanFactFinanceMonthsSlider";
import PlanFactItem from "./PlanFactItem/PlanFactItem";
import PlanFactList from "./PlanFactList/PlanFactList";
import TabBar from "shared/ui/controls/TabBar/TabBar";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { ROUTES } from "../../../constants/routes";
import { IRouterParamsWithObjectId } from "../../../types/routerTypes";
import { VIEW_PLAN_FACT_FINANCE, VIEW_PLAN_FACT_VOLUME } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";
import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./PlanFact.module.scss";

const PlanFact: React.FC = () => {
  const history = useHistory();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const locationArr = useLocationArray();
  const dispatch = useDispatch();

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);
  const viewFinancePermission = usePermission(VIEW_PLAN_FACT_FINANCE);

  const selectedDates = useSelector(planFactSelectedDatesSelector);

  useEffect(() => {
    if (!objectId) return;
    //@ts-ignore
    dispatch(loadPlanFactTree(+objectId, selectedDates?.start_at, selectedDates?.end_at));
    //@ts-ignore
  }, [objectId, selectedDates?.start_at, selectedDates?.end_at]);

  useEffect(() => {
    return () => {
      dispatch(planFactActions.clearData());
    };
  }, [objectId]);

  const tabs = React.useMemo(() => {
    return [
      {
        link: `/objects/${objectId}/plan-fact/finance`,
        text: "Сводный",
        isHidden: !viewFinancePermission,
      },
      {
        link: `/objects/${objectId}/plan-fact/volume`,
        text: "Недели",
        isHidden: !viewVolumePermission,
      },
    ];
  }, [objectId, viewVolumePermission, viewFinancePermission]);

  const [date, setDate] = React.useState<Moment>(moment());

  const isAllExpanded = useSelector(planFactVolumeIsAllExpandedSelector);

  const onAllExpand = (status: boolean) => {
    dispatch(planFactVolumeAllExpand(status));
    const lastRoutePart = locationArr.at(-1);
    if (lastRoutePart !== "volume") {
      history.push(`/objects/${objectId}/plan-fact/volume`);
    }
  };

  return (
    <TemplateBase>
      <div className={styles.container}>
        <ListPageHeaderTemplate className={styles.header}>
          <TabBar tabs={tabs} />
          <Route
            path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME}
            render={() => <MonthsYearsSlider date={date} onChange={setDate} className={styles.slider} />}
          />
          <Route path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} component={PlanFactFinanceMonthsSlider} />
          <div className={styles.right}>
            <Route
              path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME}
              render={() => (
                <LabeledSwitch
                  className={styles.switch}
                  label="Развернуть"
                  onChange={onAllExpand}
                  value={isAllExpanded}
                  labelRight={undefined}
                />
              )}
            />
            <MeasureBtn className={styles.measureBtn} />
          </div>
        </ListPageHeaderTemplate>
        <Switch>
          <Route exact path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} component={PlanFactList} />
          <Route exact path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE_ITEM} component={PlanFactItem} />
          <Route
            exact
            path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME}
            render={() => <PlanFactVolume date={date} setDate={setDate} />}
          />
          <Route
            exact
            path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME_ITEM}
            render={() => <PlanFactVolumeSectionslevel date={date} setDate={setDate} />}
          />
          <Route
            exact
            path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME_SUBITEM}
            render={() => <PlanFactVolumeExpenditures date={date} setDate={setDate} />}
          />
          {viewFinancePermission && <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} />}
          {viewVolumePermission && <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME} />}
        </Switch>
      </div>
    </TemplateBase>
  );
};

export default React.memo(PlanFact);
