export const defineWorkerId = (id: string, customUserFieldName?: string, customWorkerFieldName?: string) => {
  const splittedId = id.split("_");
  const type = splittedId[0];
  const resId = splittedId[1];

  if (type === "worker") {
    return {
      [customWorkerFieldName ?? "worker_id"]: resId,
    };
  } else {
    return {
      [customUserFieldName ?? "user_id"]: resId,
    };
  }
};
