export const confirmedIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.01991 14.8512L17.3486 6.5225L16.3587 5.53255L8.52493 13.3663L5.22489 10.0663L4.23494 11.0562L8.02996 14.8512L8.52493 15.3462L9.01991 14.8512Z"
      fill="#9AD9FF"
    />
  </svg>
);
