import cn from "classnames";
import React, { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextArea from "react-textarea-autosize";

import { aosrInvalidateKeySelector, aosrWorksSelector } from "redux/modules/common/building/journal/aosr/selectors";
import { dropCurrentAosrDetail, getWorksBySectionInAosr } from "redux/modules/common/building/journal/aosr/thunks";
import { AosrTypes } from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import AosrDocumentAdd from "./AosrDocumentAdd/AosrDocumentAdd";
import AosrMaterials from "./AosrMaterials/AosrMaterials";
import { useCraeteAosr } from "./useCreateAosr";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "shared/ui/inputs/InputBase";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import AxesAndMarksInfo from "widgets/AxesAndMarks/ui/AxesAndMarksInfo/AxesAndMarksInfo";
import DisplayAxesAndMarks from "widgets/AxesAndMarks/widgets/DisplayAxesAndMarks/DisplayAxesAndMarks";

import { actTypesEnum, actTypesOptions, getActTypesOptions } from "../Aook/constants";
import { MATERIAL_SOURCES, initialAosrForm } from "./constants";
import { IGroupInJournalDone, ITicketInJournalDone } from "types/interfaces/Tickets";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { composeFieldValidators, maxLength, minLength, required } from "utils/formHelpers/validations";

import styles from "./Aosr.module.scss";

enum NextType {
  EXPENDITURE = "expenditure",
  GROUP = "group",
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  ticket: IExpendituresInJournalFulfillment["items"][0];
  sectionId: number;
  type: AosrTypes;
  groupId?: number;
  year?: number;
  month?: number;
  onTypeSelect: (type: string) => void;
  isAosrExists?: boolean;
}

const AosrCreateForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  ticket,
  sectionId,
  type,
  groupId,
  year,
  month,
  onTypeSelect,
  isAosrExists,
}) => {
  const objectId = useObjectId();
  const expenditure = ticket?.expenditure ?? ticket?.group;
  const itemId = ticket?.expenditure?.expenditure_id ?? ticket?.group?.group_id;
  const invalidateKey = useSelector(aosrInvalidateKeySelector);
  const works = useSelector(aosrWorksSelector)[sectionId];
  const dispatch = useDispatch();
  const {
    handleAosrFormChange,
    createAosrHandler,
    aosr,
    changeAosrDateField,
    aosrDocs,
    addAosrDoc,
    removeAosrDoc,
    allMaterials,
    deleteMaterial,
    addMaterial,
  } = useCraeteAosr(ticket, type, groupId, { isOpen });

  const [nextType, setNextType] = useState<NextType>(NextType.EXPENDITURE);
  const [nextId, setNextId] = useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (!isOpen) return;
    dispatch(dropCurrentAosrDetail());
    dispatch(getWorksBySectionInAosr(objectId, sectionId));
    return () => {
      dispatch(dropCurrentAosrDetail());
    };
  }, [isOpen, sectionId, objectId]);

  return (
    <Form
      key={expenditure?.name}
      initialValues={{ ...initialAosrForm, title: expenditure?.name }}
      mutators={{
        setValue: (args, state) => {
          const field = state.fields["next_work_title"];
          field.change(args[0]);
        },
        setNext: (args, state) => {
          const id = args[0];
          const name = args[1];
          const field = state.fields["next_work_title"];
          field.change(name);

          const selected = works.find((w) => w.id === args[0]);
          if (selected) {
            const newNextType = selected.expenditure_type === "work" ? NextType.EXPENDITURE : NextType.GROUP;
            setNextType(newNextType);
            setNextId(id);
          }
        },
      }}
      //@ts-ignore
      onSubmit={(values) => {
        const preparedValues = {
          ...values,
        };

        if (nextType === NextType.GROUP) {
          preparedValues.next_group_id = nextId;
          preparedValues.next_expenditure_id = null;
          preparedValues.next_expenditure = null;
        }

        if (nextType === NextType.EXPENDITURE) {
          preparedValues.next_expenditure_id = nextId;
          preparedValues.next_group_id = null;
          preparedValues.next_group = null;
        }

        delete preparedValues.next_work_id;
        /* @ts-ignore */
        createAosrHandler(preparedValues, true);
      }}
      render={({ handleSubmit, form }) => (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          //@ts-ignore
          onChange={(_) => handleAosrFormChange(form.getState())}
        >
          <div className={styles.content}>
            <div className={cn(styles.row, styles.row1)}>
              <div>
                <Select
                  options={getActTypesOptions(!!isAosrExists)}
                  value={actTypesEnum.aosr}
                  label="Тип акта"
                  //@ts-ignore
                  onChange={onTypeSelect}
                />
              </div>
              <Field
                selfControlled
                name="number" /* @ts-ignore */
                component={InputBase}
                label="№ акта"
                validate={composeFieldValidators(
                  required(),
                  maxLength(255, "Не более 255 символов"),
                  minLength(1, "Введите № акта")
                )}
              />
            </div>
            <div className={cn(styles.row, styles.row2)}>
              <Field
                selfControlled
                name="act_date"
                render={({ input, meta }) => (
                  <Calendar
                    label="Дата акта"
                    value={input.value}
                    setValue={input.onChange}
                    classNameSelect=""
                    //@ts-ignore
                    isTooLeft
                  />
                )}
              />
              <CalendarRange
                label="Срок выполнения работ"
                defaultDateEnd={aosr.end_date}
                defaultDateStart={aosr.start_date}
                setDefaultDateEnd={changeAosrDateField("end_date") as any}
                setDefaultDateStart={changeAosrDateField("start_date") as any}
              />
              {/* @ts-ignore */}
              <Field name="copy_count" component={InputBase} label="Кол-во экз." selfControlled />
            </div>
            <div className={cn(styles.row)}>
              <div>
                <label>Наименование работы</label>
                <Field
                  selfControlled
                  name="title"
                  render={({ input, meta }) => (
                    <>
                      <TextArea
                        onChange={input.onChange}
                        minRows={3}
                        placeholder="Введите наименование работы"
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        defaultValue={input.value}
                      />
                      {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                    </>
                  )}
                  validate={composeFieldValidators(required())}
                />
              </div>
            </div>
            <div className={cn(styles.row)}>
              <div>
                <label>Проектная документация</label>
                <Field
                  selfControlled
                  name="project_documentation"
                  render={({ input, meta }) => (
                    <TextArea
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Введите номер, реквизиты чертежа, наименование проектной и/или рабочей документации"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.docsContainer}>
            <h3>Документы, подтверждающие соответствие работ требованиям</h3>
            {aosrDocs.map((el, i) => (
              <AosrDocumentAdd index={i} key={i} remove={removeAosrDoc} isHidden={!!el.isHidden} />
            ))}

            <AddButton onClick={addAosrDoc} text="Добавить" textPosition="left" className={styles.addButton} />
            {isOpen && (
              <>
                <br />
                <DisplayAxesAndMarks
                  planOrFact="fact"
                  id={ticket?.expenditure?.facts_id?.[0]!}
                  workOrGroup={!!groupId ? "group" : "work"}
                />
              </>
            )}
          </div>
          {!!allMaterials?.length && (
            <AosrMaterials
              isOpen={isOpen}
              source={MATERIAL_SOURCES.AOSR}
              materials={allMaterials}
              className={styles.materials}
              canEdit
              onRemoveMaterial={deleteMaterial}
              onAddMaterial={addMaterial}
            />
          )}
          <div className={styles.content2}>
            <div className={cn(styles.row)}>
              <div>
                <label>Работы выполнены в соответствии с</label>
                <Field
                  selfControlled
                  name="tech_regulations"
                  render={({ input, meta }) => (
                    <TextArea
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Введите наименование и структурные единицы технических регламентов, разделы проектной и/или рабочей документации"
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.hr} />
            <h4>Разрешается производство последующих работ:</h4>
            <Field
              name="next_work_title"
              render={({ input, meta }) => (
                <div className={styles.row}>
                  <label>Наименование работы</label>
                  <TextArea
                    value={input.value}
                    key={String(meta.submitting)}
                    onChange={input.onChange}
                    minRows={3}
                    placeholder="Укажите наименование"
                  />
                </div>
              )}
            />
            <Field
              name={`next_work_id`}
              render={({ input, meta }) => (
                <div className={styles.row}>
                  <label>Наименование работы по смете</label>
                  <Select
                    containerClassName={styles.select}
                    value={nextId}
                    onChange={(id, name) => {
                      form.mutators.setNext(id, name);
                      input.onChange(id);
                    }}
                    meta={meta}
                    options={works}
                  />
                </div>
              )}
            />
            <Field
              name="extra_info"
              render={({ input, meta }) => (
                <div className={styles.row}>
                  <label>Дополнительные сведения</label>
                  <TextArea
                    value={input.value}
                    key={String(meta.submitting)}
                    onChange={input.onChange}
                    minRows={3}
                    placeholder="Введите дополнительную информацию"
                  />
                </div>
              )}
            />
          </div>
          <div className={styles.heightHolder} />
          <BottomControls isExists isDoubleBtns>
            <ButtonBase type="submit" primary>
              Сформировать
            </ButtonBase>
          </BottomControls>
        </form>
      )}
    ></Form>
  );
};

export default React.memo(AosrCreateForm);
