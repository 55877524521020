import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import OfferColumns from "../OfferColumns";
import OfferComponentColumns from "../OfferComponentColumns";
import OfferSupplyColumns from "../OfferSupplyColumns";

import { getIdentifier } from "../../../../../../utils/helpers/getIdentifier";

import styles from "../../index.module.scss";

interface IProps {
  offer: any;
  setOffer: any;
  kitRequestsMeasures: any;
  requestMeasure: any;
  deleteOffer: any;
  createOfferSupply: any;
  permissions: Record<string, boolean>;
  setSupply: any;
  deleteSupply: any;
}

const Offer: React.FC<IProps> = ({
  offer,
  setOffer,
  deleteOffer,
  requestMeasure,
  kitRequestsMeasures,
  createOfferSupply,
  setSupply,
  deleteSupply,
  permissions,
}) => {
  const [isComponentsOpen, setIsComponentsOpen] = useState(false);

  const handleComponentsOpening = useCallback(() => setIsComponentsOpen((prevState) => !prevState), []);

  const isShownCountRequestMeasure = !offer.is_kit && offer.measure !== requestMeasure;
  const offerIsKitWithNotKitMeasure = offer.is_kit && offer.measure !== "компл";

  const offerSuppliesWithoutFirst = useMemo(() => {
    return offer.supplies.slice(1);
  }, [offer.supplies]);

  return (
    <>
      <div className={cn(styles.offer)} key={"offerColumnsKey"}>
        <OfferColumns
          offer={offer}
          setOffer={setOffer}
          handleKitComponentsOpening={handleComponentsOpening}
          deleteOffer={deleteOffer}
          requestMeasure={requestMeasure}
          createOfferSupply={createOfferSupply}
          isShownCountRequestMeasure={isShownCountRequestMeasure}
          offerIsKitWithNotKitMeasure={offerIsKitWithNotKitMeasure}
          permissions={permissions}
        />
      </div>
      {offerSuppliesWithoutFirst.map((supply: any, index: number) => (
        <div className={cn(styles.offer)} key={getIdentifier(supply)}>
          <OfferSupplyColumns
            canEdit={permissions.editRequest}
            supply={supply}
            setSupply={setSupply}
            deleteSupply={deleteSupply}
            createSupply={createOfferSupply}
            canCreateSupply={index === offerSuppliesWithoutFirst.length - 1}
          />
        </div>
      ))}
      {offer.is_kit &&
        isComponentsOpen &&
        offer.components.map((offerComponent: any) => (
          <div className={cn(styles.offer)} key={getIdentifier(offerComponent)}>
            <OfferComponentColumns
              canEdit={permissions.editRequest}
              offer={offer}
              offerComponent={offerComponent}
              setOffer={setOffer}
              requestMeasure={kitRequestsMeasures[offerComponent.request_id]}
            />
          </div>
        ))}
    </>
  );
};

export default React.memo(Offer);
