import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  getObjectBreadcrumbsByProject,
  objectsBreadcrumbsLoadingSelector,
  objectsBreadcrumbsSelector,
  objectsByProjectSelector,
} from "redux/modules/common/building/objects";

import { projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";

import breadcrumbs from "../../../../../constants/breadcrumbs";
import { MODULES_ENUM } from "../../../../../types/enums/ModulesEnum";
import { IIdAndName } from "../../../../../types/idAndName";
import { IRouterParamsWithObjectId } from "../../../../../types/routerTypes";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IModuleLocation {
  modules: string[];
  subModules: string[];
}

const MODULES_WITH_PRESET_OBJECT_ID = [MODULES_ENUM.OBJECTS];

const MODULES_WITH_ALL_PROJECTS_OPTION_0 = [MODULES_ENUM.TASKS];
const SUB_MODULES_WITH_ALL_PROJECTS_OPTION_0 = ["finance"];

const LOCATIONS_WITH_ALL_PROJECTS_OPTION_0: IModuleLocation[] = [
  {
    modules: [MODULES_ENUM.CONSTRUCTING, MODULES_ENUM.PURCHASES, MODULES_ENUM.TASKS],
    subModules: ["files"],
  },
];

const LOCATIONS_WITH_ALL_PROJECTS_OPTION_EMPTY_STRING: IModuleLocation[] = [
  {
    modules: [MODULES_ENUM.CONSTRUCTING, MODULES_ENUM.PURCHASES],
    subModules: ["manufacturing"],
  },
];

const LOCATIONS_WITH_REDIRECT_TO_CLOSEST_PROJECT: IModuleLocation[] = [];

const SUB_MODULES_WITHOUT_OBJECT_SELECT = ["settings", "events", "profile", "lists", "analytics"];

const matchLocationByModule = (moduleName: string, subModuleName: string) => (loc: IModuleLocation) =>
  loc.modules.includes(moduleName as MODULES_ENUM) && loc.subModules.includes(subModuleName);

export const useBreadcrumbs = () => {
  const moduleName = useUrlModule();
  const {
    objectId: urlObjectId,
    buildingId,
    projectId,
  } = useParams<IRouterParamsWithObjectId & { buildingId?: string; projectId: string }>();
  const objectId = urlObjectId || buildingId;

  const history = useHistory();
  const location = useLocation();
  const locationArray = location?.pathname.split("/").filter((item: string) => item);

  const objects = useSelector(objectsBreadcrumbsSelector);
  const isObjectsLoading = useSelector(objectsBreadcrumbsLoadingSelector);
  const objectsByProject = useSelector(objectsByProjectSelector)[projectId] ?? [];

  const isProjectSelectShown = useMemo(() => {
    if (
      location?.pathname.includes("constructing/projects") &&
      (projectId || location?.pathname.includes("projects/object"))
    ) {
      return true;
    }
    if (location.pathname.includes("constructing/finance")) {
      return true;
    }
    if (location.pathname.includes("constructing/handler") && projectId) {
      return true;
    }
    return false;
  }, [location?.pathname, projectId]);

  const activeModule = useMemo(
    () => breadcrumbs.find((item) => item.name === moduleName) || { children: [], name: "", link: "", title: "" },
    [moduleName]
  );

  const activeSubModule = useMemo(
    () =>
      activeModule?.children?.find(
        (item) =>
          item.name ===
          (MODULES_WITH_PRESET_OBJECT_ID.includes(activeModule.name as MODULES_ENUM)
            ? locationArray[2]
            : locationArray[1])
      ) || { name: "", link: "", title: "" },
    [activeModule, locationArray]
  );

  useEffect(() => {
    if (location?.pathname?.includes?.("constructing/projects")) {
      return;
    }
    const lastObjectId = localStorage?.getItem("lastObjectId") || objects.results?.[0]?.id;
    if (
      !!objectId ||
      !LOCATIONS_WITH_REDIRECT_TO_CLOSEST_PROJECT.some(
        matchLocationByModule(activeModule.name, activeSubModule.name)
      ) ||
      !lastObjectId
    )
      return;
    history.push(`${lastObjectId}`);
  }, [objectId, objects.results?.[0]?.id, activeModule.name, activeSubModule.name, location?.pathname]);

  const activeObjectName = useMemo(
    () => objects?.results?.find((o: IIdAndName) => +o.id == +objectId!)?.name || "Все объекты",
    [objects?.results, objectId]
  );

  const constructSelectLink = useCallback(
    (objectId: string | number) => {
      if (location?.pathname?.includes("constructing/projects")) {
        if (+objectId === 0) {
          return `/constructing/projects/objects-list/${projectId}`;
        }
        return `/constructing/projects/${projectId}/object/${objectId}`;
      }

      if (location?.pathname?.includes("constructing/handler")) {
        if (+objectId === 0) {
          return `/constructing/projects/objects-list/${projectId}`;
        }

        return `/constructing/handler/${projectId}/${objectId}`;
      }

      if (location.pathname.includes("constructing/finance")) {
        return `/constructing/finance/${projectId}/${objectId}/${locationArray[4]}`;
      }

      return (
        activeModule &&
        (MODULES_WITH_PRESET_OBJECT_ID.includes(activeModule.name as MODULES_ENUM)
          ? `${activeModule.link}/${objectId}/${locationArray[2]}`
          : locationArray[3]
          ? `${activeModule.link}/${locationArray[1]}/${objectId}/${locationArray[3]}`
          : `${activeModule.link}/${locationArray[1]}/${objectId}`)
      );
    },
    [activeModule, locationArray, projectId, location?.pathname]
  );

  const shouldHaveZeroOption =
    MODULES_WITH_ALL_PROJECTS_OPTION_0.includes(activeModule.name as MODULES_ENUM) ||
    SUB_MODULES_WITH_ALL_PROJECTS_OPTION_0.includes(activeSubModule.name) ||
    LOCATIONS_WITH_ALL_PROJECTS_OPTION_0.some(matchLocationByModule(activeModule.name, activeSubModule.name));

  const shouldHaveEmptyStringOption = LOCATIONS_WITH_ALL_PROJECTS_OPTION_EMPTY_STRING.some(
    matchLocationByModule(activeModule.name, activeSubModule.name)
  );

  const objectsOptions = useMemo(() => {
    if (isProjectSelectShown) {
      return [{ id: 0, name: "Все объекты" }, ...objectsByProject];
    }
    let optionsCandidates: IIdAndName[] = [];
    if (!objects) return optionsCandidates;
    if (objects?.results?.length !== 1 && shouldHaveZeroOption) {
      optionsCandidates = [{ id: 0, name: "Все объекты" }, ...objects?.results];
    } else if (objects?.results?.length !== 1 && shouldHaveEmptyStringOption) {
      optionsCandidates = [{ id: "", name: "Все объекты" }, ...objects?.results];
    } else {
      optionsCandidates = objects?.results;
    }
    return optionsCandidates?.filter((o: IIdAndName) => o.id != +objectId!);
  }, [
    location,
    objects,
    shouldHaveZeroOption,
    shouldHaveEmptyStringOption,
    objectId,
    isProjectSelectShown,
    objectsByProject,
  ]);

  const isObjectSelectShown = useMemo(() => {
    if (location?.pathname?.includes("constructing/projects/object")) {
      return true;
    }

    if (location?.pathname?.includes("constructing/handler")) {
      return true;
    }

    return (
      !isObjectsLoading &&
      objects?.results?.length > 0 &&
      !SUB_MODULES_WITHOUT_OBJECT_SELECT?.includes(activeSubModule.name) &&
      (!!objectId ||
        objectsOptions?.findIndex((option: IIdAndName) => !option.id) !== -1 ||
        (objects?.results?.length === 1 && (shouldHaveEmptyStringOption || shouldHaveZeroOption)))
    );
  }, [
    objectId,
    isObjectsLoading,
    activeSubModule.name,
    objectsOptions,
    objects?.results,
    shouldHaveZeroOption,
    shouldHaveEmptyStringOption,
    location?.pathname,
  ]);

  useEffect(() => {
    if (location?.pathname?.includes?.("constructing/projects")) {
      return;
    }
    if (
      !isObjectSelectShown ||
      objects?.results?.length > 1 ||
      !objects?.results.length ||
      +objectId! == +objects?.results?.[0]?.id
    )
      return;
    history.replace(constructSelectLink(objects?.results?.[0]?.id));
  }, [isObjectSelectShown, objects?.results, constructSelectLink, location?.pathname, objectId]);

  // PROJECTS

  const dispatch = useDispatch();
  const key = generateStorageKey({ projectId: 0 });
  const projects = useSelector(projectsV2ListSelector)[key];

  const activeProjectName = useMemo(
    () => projects?.find((o: IIdAndName) => +o.id == +projectId!)?.name || "Все проекты",
    [projects, projectId]
  );

  useEffect(() => {
    if (!isProjectSelectShown || projects?.length) return;
    dispatch(getProjectsList({}));
  }, [projects, isProjectSelectShown]);

  useEffect(() => {
    if (!projectId) return;
    dispatch(getObjectBreadcrumbsByProject(projectId));
  }, [projectId]);

  const projectsOptions = useMemo(() => {
    if (!projects) {
      return [];
    }
    return [{ id: 0, name: "Все проекты" }, ...projects];
  }, [projects]);

  const constructProjectSelectLink = useCallback(
    (projectId: number) => {
      if (locationArray?.includes("objects-list")) {
        if (+projectId === 0) {
          return `/constructing/projects`;
        }
        return `/constructing/projects/objects-list/${projectId}`;
      }

      if (locationArray?.includes("handler")) {
        if (+projectId === 0) {
          return `/constructing/projects`;
        }
        return `/constructing/projects/objects-list/${projectId}`;
      }

      if (location.pathname.includes("constructing/projects/")) {
        if (+projectId === 0) {
          return `/constructing/projects`;
        }
        return `/constructing/projects/objects-list/${projectId}`;
      }

      if (location.pathname.includes("constructing/finance")) {
        return `/constructing/finance/${projectId}/0/${locationArray[4]}`;
      }
    },
    [locationArray, location.pathname]
  );

  return {
    activeModule,
    activeSubModule,
    constructSelectLink,
    activeObjectName,
    objectsOptions,
    isObjectSelectShown,
    // projects
    projectsOptions,
    isProjectSelectShown,
    activeProjectName,
    constructProjectSelectLink,
    projectId: +projectId,
  };
};
