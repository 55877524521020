import cn from "classnames";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  dropHighlightRelatedIntervals,
  highlightRelatedIntervals,
} from "redux/modules/common/building/manufacturing/thunks";
import { chartActions } from "redux/modules/common/chart/actions";
import { chartJustAddedArrowSelector } from "redux/modules/common/chart/selectors";
import { IPlanRelation } from "redux/modules/common/chart/types";

import { CHART_ARROW_COLORS, CHART_ARROW_CONFIG } from "components/pages/Chart/constants";

import { IChartPlanIntervalProps } from "../_plan/withPlan";
import { CHART_Z_INDEX } from "../const";
import ConnectPointsWrapper from "./ConnectPointsWrapper/ConnectPointsWrapper";
import { JustAddedArrowPopup } from "./JustAddedArrowPopup/JustAddedArrowPopup";
import { useHover } from "./useHover";
import { IDiagramIntervalLink, useIntervalLinks } from "./useIntervalLinks";
import Xarrow from "react-xarrows";

import styles from "./withLinking.module.scss";

export interface IWithLinkingProps {
  isLinkingEnabled: boolean;
  isLinkingVisible: boolean;
  handleIntervalClickLinking: MouseEventHandler;
}

export const withLinking =
  (Component: React.ComponentType<IChartPlanIntervalProps>) => (props: IChartPlanIntervalProps) => {
    const { onAddArrowCallback, forwardRef: intervalWrapperRef } = props;
    const { hover } = useHover(intervalWrapperRef);
    // @ts-ignore
    const intervalId = props.plan.group?.id || props.plan.expenditure?.id || props.plan.id;

    const {
      isLinkingEnabled,
      isLinkingVisible,
      handleIntervalClick,
      position,
      handleDragStart,
      handleDragging,
      arrowsStartsWithCurrentIntervalId,
      isIntervalHighlighted,
      highlightedArrows,
      arrowHash,
      draggedArrow,
      onHoverIntervalWithDraggedLink,
    } = useIntervalLinks({
      intervalWrapperRef,
      toIntervalId: intervalId,
      projectId: props.projectId,
      startDate: props.plan.start,
      endDate: props.plan.end,
      onAddArrowCallback,
      // @ts-ignore
      isToIntervalGroup: Boolean(props.plan.group),
    });
    // @ts-ignore
    const hasShift = props.plan.has_shifts;

    const dispatch = useDispatch();
    const justAddedArrow = useSelector(chartJustAddedArrowSelector);

    const highlightChain = useCallback(() => {
      if (!isLinkingEnabled) return;
      dispatch(highlightRelatedIntervals({ intervalId, projectId: props.projectId }));
    }, [intervalId, props.projectId, isLinkingEnabled]);

    const removeHighlightChain = useCallback(() => dispatch(dropHighlightRelatedIntervals()), []);

    const isChainHighlighted = isLinkingEnabled && (hover || isIntervalHighlighted);

    useEffect(() => {
      if (!isLinkingVisible) return;
      dispatch(chartActions.updateArrowHash());
    }, [isLinkingVisible]);

    const linkingLineColor = (isArrowHighlighted: boolean) => {
      if (hasShift) {
        return CHART_ARROW_COLORS.CRITICAL;
      }
      if (isChainHighlighted || isArrowHighlighted) {
        return CHART_ARROW_COLORS.HIGHLIGHT;
      }
      if (isLinkingVisible && !isLinkingEnabled) {
        return CHART_ARROW_COLORS.DISPLAY_NON_INTERACTIVE;
      }
      return CHART_ARROW_CONFIG.lineColor;
    };

    const innerAfterContent = (
      <>
        {props.innerAfterContent}
        {isLinkingEnabled && (
          <ConnectPointsWrapper
            intervalId={intervalId}
            arrowId={props.id || intervalId}
            isAddingArrowAllowed={isLinkingEnabled}
            hover={hover}
            position={position}
            handleIntervalClick={handleIntervalClick}
            // @ts-ignore
            handleDragStart={handleDragStart}
            // @ts-ignore
            handleDragging={handleDragging}
            intervalWrapperRef={intervalWrapperRef}
            projectId={props.projectId}
            isLinkingEnabled={isLinkingEnabled}
            draggedArrow={draggedArrow}
            onHoverIntervalWithDraggedLink={onHoverIntervalWithDraggedLink}
          />
        )}
      </>
    );

    const afterContent = (
      <>
        {props.afterContent}
        {isLinkingVisible &&
          arrowsStartsWithCurrentIntervalId
            // @ts-ignore
            ?.filter((a) => document.getElementById(a.from_interval ? `work_${a.to_interval}` : `group_${a.to_group}`))
            ?.map((arrow: IDiagramIntervalLink) => {
              const isArrowHighlighted = Boolean(highlightedArrows[arrow.id]);
              return (
                <Xarrow
                  {...CHART_ARROW_CONFIG}
                  lineColor={linkingLineColor(isArrowHighlighted)}
                  headColor={linkingLineColor(isArrowHighlighted)}
                  zIndex={
                    isChainHighlighted || isArrowHighlighted || !!arrow.delay_day ? CHART_Z_INDEX.HIGHLIGHTED_ARROW : 0
                  }
                  start={arrow.from_interval ? `work_${arrow.from_interval}` : `group_${arrow.from_group}`}
                  end={arrow.from_interval ? `work_${arrow.to_interval}` : `group_${arrow.to_group}`}
                  relationType={arrow.related_type}
                  key={`${arrow.from_interval ?? arrow.from_group}-${arrow.to_interval ?? arrow.to_group}-${arrowHash}`}
                  labels={{
                    start: !!arrow.delay_day ? (
                      <div
                        className={cn(styles.breakBubble, {
                          [styles.breakBubbleHighlighted]: isChainHighlighted || isArrowHighlighted,
                        })}
                        onMouseOver={highlightChain}
                        onMouseLeave={removeHighlightChain}
                      >
                        {arrow.delay_day}
                      </div>
                    ) : undefined,
                    end:
                      justAddedArrow?.id === arrow.id ? (
                        <JustAddedArrowPopup arrow={arrow as IPlanRelation} projectId={props.projectId} />
                      ) : undefined,
                  }}
                  passProps={{
                    onMouseOver: highlightChain,
                    onMouseLeave: removeHighlightChain,
                  }}
                />
              );
            })}
      </>
    );

    return (
      <>
        <Component
          {...props}
          innerAfterContent={innerAfterContent}
          isLinkingEnabled={isLinkingEnabled}
          isLinkingVisible={isLinkingVisible}
          handleIntervalClickLinking={handleIntervalClick}
          afterContent={afterContent}
          children={props.children}
        />
      </>
    );
  };
